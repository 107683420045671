import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators'
import SingleFileupload from 'ebg-vue-common/src/components/common/fileupload/single/SingleFileupload.vue'
import SnipeAssetUserAssets from '@/components/snipeasset/user/assets/SnipeAssetUserAssets.vue'
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import D365AddressPicker from 'ebg-vue-common/src/components/d365/addresspicker/D365AddressPicker.vue'
import UserJobs from '@/components/user/tab/jobs/list/UserJobs.vue';
import UserEducation from '@/components/user/tab/education/list/UserEducation.vue';
import UserQualifications from '@/components/user/tab/qualifications/list/UserQualifications.vue';
import UserBills from '@/components/user/tab/bills/list/UserBills.vue';
import UserWorkSecurity from '@/components/user/tab/workSecurity/list/UserWorkSecurity.vue';
import UserContactPersons from '@/components/user/tab/contactPersons/list/UserContactPersons.vue';
import UserBaseAdGroups from '@/components/user/tab/baseAdGroups/list/UserBaseAdGroups.vue';
import UserInventory from '@/components/user/tab/inventory/list/UserInventory.vue';
import UserDocuments from '@/components/user/tab/documents/list/UserDocuments.vue';
import UserHistory from '@/components/user/tab/history/UserHistory.vue';

export default {
  components: {
    SingleFileupload,
    SnipeAssetUserAssets,
    VueSimpleSuggest,
    D365AddressPicker,
    UserJobs,
    UserEducation,
    UserQualifications,
    UserBills,
    UserWorkSecurity,
    UserContactPersons,
    UserBaseAdGroups,
    UserInventory,
    UserDocuments,
    UserHistory
  },

  name: 'userEdit',

  data () {
    return {
      form: {
        id: null,
        name: null,
        surname: null,
        personalCode: '',
        userType: null,
        company_id: null,
        isMatchesDeclaredAddr: true,
        addressDeclared: {
          hasvzdCode: null,
          id: null,
          manualEntry: false,
          text: ''
        },
        actualAddress: {
          hasvzdCode: null,
          id: null,
          manualEntry: false,
          text: ''
        },
        gender: null,
        photo: null,
        ldapUsername: null,
        ldap_home_phone: null,
        emailHome: null,
        isAgreesPhoto: true,
        ldap_telephone_number: null,
        isUsePrivatePhone: false,
        isUsePrivatEemail: false,
        email: null,
        isAgreesBirthday: true,
        isUseForeigner: false,
        isAgreesPhone: false
      },
      isLoading: false,
      api_id: '',
      usernameReq: null,
      generatingUsername: false,
      activeTab: 'UserJobs',
      D365AddressPickerStyleSize: '',
      colNumber: 6
    }
  },

  validations: {
    form: {
      name: { required },
      surname: { required },
      company_id: { required },
      personalCode: {
        validatePersonalCode: function (value, vm) {
          if (vm.userType !== null &&
            this.$store.getters.getParamFromConfig('contact_person_types', vm.userType, 'code', this.$i18n.locale) === 'CHILD' &&
            !vm.isUseForeigner &&
            value.length < 11
          ) {
            return false;
          }
          return true;
        }
      },
      addressDeclared: {
        text: {
          validateAddressDeclared: function (value, vm) {
            if ((vm.manualEntry && value !== '') || (!vm.manualEntry && vm.id !== null)) {
              return true;
            }
            return false;
          }
        }
      },
      actualAddress: {
        text: {
          validateAddressDeclared: function (value, vm) {
            if (!this.form.isMatchesDeclaredAddr && ((vm.manualEntry && value === '') || (!vm.manualEntry && vm.id === null))) {
              return false;
            }
            return true;
          }
        }
      },
      gender: { required },
      userType: { required },
      ldap_home_phone: {
        required: requiredIf(function () {
          return this.form.isUsePrivatePhone;
        })
      },
      emailHome: {
        required: requiredIf(function () {
          return this.form.isUsePrivatEemail;
        })
      }
    }
  },

  computed: {
    tabOptions () {
      const options = [];
      if (this.canShowJobs) {
        options.push({ code: 'UserJobs', text: this.$t('user.tab.jobs'), slotcode: 'UserJobs' });
      }
      if (this.canShowEducation) {
        options.push({ code: 'UserEducation', text: this.$t('user.tab.education'), slotcode: 'UserEducation' });
      }
      if (this.canShowQualification) {
        options.push({ code: 'UserQualifications', text: this.$t('user.tab.qualifications'), slotcode: 'UserQualifications' });
      }
      if (this.canShowWorkSecurity) {
        options.push({ code: 'UserWorkSecurity', text: this.$t('user.tab.work-security'), slotcode: 'UserWorkSecurity' });
      }
      if (this.canShowContactPerson) {
        options.push({ code: 'UserContactPersons', text: this.$t('user.tab.contact-persons'), slotcode: 'UserContactPersons' });
      }
      if (this.canShowUserSnipeAssets) {
        options.push({ code: 'SnipeAssetUserAssets', text: this.$t('user.tab.inventory'), slotcode: 'SnipeAssetUserAssets' });
      }
      if (this.canShowUserHistory) {
        options.push({ code: 'UserHistory', text: this.$t('user.tab.history'), slotcode: 'UserHistory' });
      }

      return options;
    },

    userTypeOptions () {
      return this.$store.getters.getConfigOptions('user_types', this.$i18n.locale);
    },

    genderOptions () {
      return this.$store.getters.getConfigOptions('user_gender', this.$i18n.locale);
    },

    companyOptions () {
      return this.BasicHelper.sortArrayByKeyValue(
        this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0')
      );
    },

    isNewRecord () {
      return (this.canEdit && this.api_id === 'NEW');
    },

    canShowUserHistory () {
      return this.ApiRequest.userHasPermission('view-user-history');
    },

    canShowUserSnipeAssets () {
      return this.ApiRequest.userHasPermission('view-user-snipe-assets');
    },

    canShowContactPerson () {
      return this.ApiRequest.userHasPermission('view-contact-person');
    },

    canShowWorkSecurity () {
      return this.ApiRequest.userHasPermission('view-work-security');
    },

    canShowQualification () {
      return this.ApiRequest.userHasPermission('view-qualification');
    },

    canShowEducation () {
      return this.ApiRequest.userHasPermission('view-education');
    },

    canShowJobs () {
      return this.ApiRequest.userHasPermission('view-job');
    },

    canEdit () {
      return (this.ApiRequest.userHasPermission('edit-all-users'));
    },

    canSave () {
      return (this.ApiRequest.userHasPermission('save-all-users'));
    },

    isUserType () {
      if (
        this.form.userType !== null &&
        this.$store.getters.getParamFromConfig('contact_person_types', this.form.userType, 'code', this.$i18n.locale) === 'HUSBAND'
      ) {
        return true;
      }
      return false;
    },

    isRequiredPersonalCode () {
      if (
        this.form.userType !== null &&
        this.$store.getters.getParamFromConfig('contact_person_types', this.form.userType, 'code', this.$i18n.locale) === 'CHILD' &&
        !this.form.isUseForeigner
      ) {
        return true;
      }
      return false;
    }
  },

  methods: {
    async show () {
      this.api_id = this.$route.params.api_id;
      if (this.api_id !== 'NEW') {
        const path = `user/show/${this.api_id}`;
        const { data } = await this.loadData(path);
        this.BasicHelper.syncObjectValuesByKeys(this.form, data);
        this.changeMatchesDeclaredAddr(this.form.isMatchesDeclaredAddr);
      } else {
        this.form.userType = this.$store.getters.getParamFromConfigByFilter('user_types', 'code', 'EMPLOYEE', 'id');
      }
    },

    tabChanged (el) {
      if (this.tabOptions[el]) {
        this.activeTab = this.tabOptions[el].code;
      }
    },

    isPersonCode (el) {
      if (!this.form.isUseForeigner) {
        const keyCode = (el.keyCode ? el.keyCode : el.which);
        if (keyCode == 45 || (keyCode >= 48 && keyCode <= 57)) {
          return true;
        }
        return el.preventDefault();
      }
      return true;
    },

    async loadData (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, [], response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    },

    async submitForm () {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      const reqData = this.BasicHelper.cloneObject(this.form);
      reqData.files = (this.form.photo ? [this.form.photo] : []);
      const formData = this.ApiRequest.createFormData(reqData, Object.keys(reqData), 'user', this, false);
      this.isLoading = true;
      const { data } = await this.save(`user/save/${this.api_id}`, formData);
      if (data) {
        let msg = ''
        if (this.api_id === 'NEW') {
          msg = this.$t('user.msg.add-successfully', { name: data.name, surname: data.surname });
        } else {
          msg = this.$t('user.msg.update-successfully', { name: data.name, surname: data.surname });
        }

        this.$store.commit('toastAdd', { context: 'success', message: msg });
        if (this.api_id === 'NEW') {
          this.api_id = data.api_id;
          this.$router.push({ name: 'menu.user.view', params: { api_id: data.api_id } });
        }
      }
      this.isLoading = false;
    },

    close () {
      this.$router.push({ name: 'menu.user.list' });
    },

    changedDeclaredAddress (el) {
      this.form.addressDeclared = el;
    },

    changedActualAddress (el) {
      this.form.actualAddress = el;
    },

    changeMatchesDeclaredAddr (el) {
      if (el) {
        this.D365AddressPickerStyleSize = '';
        this.colNumber = 6;
      } else {
        this.D365AddressPickerStyleSize = 'max-width: 50px;';
        this.colNumber = 3;
      }
    },

    updateLdapEmail () {
      if (this.api_id !== 'NEW') {
        return;
      }
      this.form.email = this.form.ldapUsername;
    },

    getLdapUsername () {
      if (this.api_id !== 'NEW') {
        return;
      }

      if (this.usernameReq) {
        this.usernameReq.cancel('changed search query');
      }

      this.generatingUsername = true;
      const reqData = { name: this.form.name, surname: this.form.surname };
      const self = this;
      setTimeout(
        function () {
          if (reqData.name === self.form.name && reqData.surname === self.form.surname) {
            self.usernameReq = self.ApiRequest.request('user/generate-ldap-username', self.ApiRequest.REQUEST_POST, reqData, function (response) {
              if (response.data) {
                self.form.ldapUsername = response.data;
                self.form.email = response.data;
                self.generatingUsername = false;
              } else if (response.error) {
                self.generatingUsername = false;
                self.ApiRequest.displayErrorDialog(response, self);
              }
            });
          }
        }, 500);
    },

    async save (path, formData) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, formData, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  },

  mounted () {
    if (this.$route.params.api_id) {
      this.show().then(() => null);
    }
  },

  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'userEdit' }) }
  }
}
