import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core';
import { mapState } from 'vuex';

const defaultEl = {
  id: null,
  userApi_id: null,
  company_id: null,
  department_id: null,
  division_id: null,
  job_position_id: null,
  contractNo: null,
  contractAxId: null,
  working_time_type_id: null,
  companyValidFrom: null,
  validTo: null,
  axaptaCode: null,
  d365Code: null,
  directManager: null,
  isPrimary: false,
  remainHoliday: null,
  user_id: null,
  bankName: null,
  accountNumber: null,
  dynamicBankAccountId: null
}

export default {
  name: 'UserJobsEdit',

  data () {
    return {
      form: [],
      companyId: null,
      header_title: '',
      isLoading: false,
      isShowCode: true
    }
  },

  validations: {
    form: {
      company_id: { required },
      job_position_id: { required }
    }
  },

  computed: {
    ...mapState(['config']),
    companyOptions () {
      return this.BasicHelper.sortArrayByKeyValue(
        this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0', false, false, false, ['axaptcode', 'isDynamics'])
      );
    },

    departmentOptions () {
      const departments = this.$store.getters.getConfigOptions(
        'company_departments',
        this.$i18n.locale,
        null, null, false, false, false,
        ['company_id']
      ).filter(
        el => parseInt(el.company_id) === parseInt(this.form.company_id)
      );

      return this.BasicHelper.sortArrayByKeyValue(departments, 'sortText');
    },

    divisionOptions () {
      const division = this.$store.getters.getConfigOptions(
        'company_divisions',
        this.$i18n.locale,
        null, null, false, false, false,
        ['company_id', 'department_id']
      ).filter(
        el => (
          parseInt(el.company_id) === parseInt(this.form.company_id) &&
          (this.form.department_id === null || parseInt(el.department_id) === parseInt(this.form.department_id))
        )
      );

      return this.BasicHelper.sortArrayByKeyValue(division, 'sortText');
    },

    jobPositionOptions () {
      const options = [];
      if (this.config && this.config.company_jobs && this.form.company_id) {
        Object.values(this.config.company_jobs).forEach(el => {
          Object.values(el.job_positions).forEach(jobPos => {
            if (
              parseInt(jobPos.company_id) === parseInt(this.form.company_id) &&
              (this.form.department_id === null || parseInt(jobPos.department_id) === parseInt(this.form.department_id)) &&
              (this.form.division_id === null || parseInt(jobPos.division_id) === parseInt(this.form.division_id))
            ) {
              options.push({ value: jobPos.id, text: el.name[this.$i18n.locale], department_id: jobPos.department_id })
            }
          });
        });
      }
      return this.BasicHelper.sortArrayByKeyValue(options, 'text');
    },

    workingTimeTypeOptions () {
      return this.$store.getters.getConfigOptions('working_time_types', this.$i18n.locale);
    }
  },

  methods: {
    async show (id, userApiId) {
      this.v$.$reset();
      this.form = this.BasicHelper.cloneObject(defaultEl);
      if (id !== 'NEW') {
        this.isLoading = true;
        const { data } = await this.loadData(`user/jobs/show/${id}`);
        this.BasicHelper.syncObjectValuesByKeys(this.form, data);
        this.isLoading = false;
      }
      this.form.id = id;
      this.form.userApi_id = userApiId;
      this.header_title = id === 'NEW' ? this.$t('user.jobs.title-add') : this.$t('user.jobs.title-update');
      this.$refs['modal-window'].show()
    },

    selectCompany (el) {
      if (el !== null && el.isDynamics) {
        this.isShowCode = false;
      } else {
        this.isShowCode = true;
      }

      this.companyId = el !== null ? el.value : null;
    },

    changeCompany () {
      this.form.department_id = null;
      this.form.division_id = null;
      this.form.job_position_id = null;
    },

    async populateJobPosition (el) {
      this.form.directManager = null;
      if (el !== null) {
        this.form.department_id = el.department_id;
        this.form.directManager = this.$store.getters.getDirectManager(el, this.form.user_id, this.form.division_id);
      }
    },

    async synchronize () {
      let code = '';
      if (!this.isShowCode && this.form.d365Code != null && this.d365Code !== '') {
        code = this.form.d365Code;
      } else if (this.isShowCode && this.form.axaptaCode != null && this.axaptaCode !== '') {
        code = this.form.axaptaCode;
      }

      if (code !== '' && this.companyId !== null) {
        this.isLoading = true;
        const { data } = await this.loadData(`user/jobs/synchronize/${code}/${this.companyId}`);
        if (data) {
          this.BasicHelper.syncObjectValuesByKeys(this.form, data);
        }
        this.isLoading = false;
      }
    },

    async submitForm () {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      this.isLoading = true;
      const { data } = await this.save(`user/jobs/save/${this.form.id}`);
      let jobName = '';
      if (data) {
        jobName = this.$store.getters.getParamFromConfig('company_jobs', data, 'name', this.$i18n.locale);
      }
      this.$refs['modal-window'].hide();
      this.$emit('changeDate');
      this.isLoading = false;
      let msg = ''
      if (this.form.id === 'NEW') {
        msg = this.$t('user.jobs.msg-add-successfully', { name: jobName });
      } else {
        msg = this.$t('user.jobs.msg-update-successfully', { name: jobName });
      }

      this.$store.commit('toastAdd', { context: 'success', message: msg });
    },

    async save (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, this.form, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    },

    async loadData (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, this.form, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  },

  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'UserJobsEdit' }) }
  }
}
