export default {
  name: 'SnipeAssetUserAssets',
  props: {
    userId: {
      type: Number | String,
      default: null
    },
    useLoggedInUser: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      assets: [],
      loading: true,
      errorMsg: null
    }
  },
  computed: {
    statusLabels () {
      const items = this.$store.getters.getItemsFromConfigWithFilter('snipe_asset_status_labels');
      const configData = {
        1: { class: 'btn-danger', bicon: 'question' }, // nav zinams
        2: { class: 'btn-primary', bicon: 'person-check-fill' }, // nodots lietotajam
        5: { class: 'btn-outline-error', bicon: 'trash2' }, // norakstiits
        8: { class: 'btn-info', icon: 'computer' }, // IT noliktava
        9: { class: 'btn-warning', icon: 'euro_symbol' }, // pardots lietotajam
        10: { class: 'btn-secondary', icon: 'contacts' }, // HR noliktava
        default: { class: 'btn-danger', bicon: 'exclamation-circle-fill' }
      }
      Object.keys(items).forEach(function (key) {
        items[key].code = parseInt(items[key].code);
        if (configData[items[key].code]) {
          items[key].class = configData[items[key].code].class;
          items[key].icon = configData[items[key].code].icon;
          items[key].bicon = configData[items[key].code].bicon;
        } else {
          items[key].class = configData.default.class;
          items[key].icon = configData.default.icon;
          items[key].bicon = configData[items[key].code].bicon;
        }
      });
      return items;
    },
    tableHeaders () {
      const headers = [
        { key: 'category.name', label: this.$t('snipe-asset.asset.category') },
        { key: 'asset_tag', label: this.$t('snipe-asset.asset.asset_tag') },
        { key: 'name', label: this.$t('snipe-asset.asset.name') },
        { key: 'model.name', label: this.$t('snipe-asset.asset.model'), format: 'html' },
        { key: 'serial', label: this.$t('snipe-asset.asset.serial') },
        { key: 'image', label: this.$t('snipe-asset.asset.image'), format: 'image', class: 'asset-image' },
        { key: 'status_label.name', label: this.$t('snipe-asset.asset.status') },
        { key: 'custom_fields', label: this.$t('snipe-asset.asset.limit'), format: 'formatFunc', funcs: [this.getLimitColumnData], classFuncs: [null] }
      ];
      const linkActions = [{ label: this.$t('snipe-asset.asset.linkOpen'), emit: 'openAsset', bicon: 'eye-fill', class: 'btn-warning' }];
      headers.push({ key: 'actions', labelcode: 'snipe-asset.asset.link', actions: linkActions });
      if (this.canEdit) {
        const actions = [];
        const self = this;
        Object.values(this.statusLabels).forEach(function (el) {
          actions.push({
            label: self.BasicHelper.getTranslation(el.name),
            emit: 'changeStatus',
            bicon: el.bicon,
            icon: el.icon,
            class: el.class,
            ifconfig: { param: 'status_label.id', value: el.code, notCheck: true },
            data: { status: el.code }
          })
        })
        headers.push({ key: 'actions', labelcode: 'snipe-asset.asset.change-status', actions: actions, class: 'col-width-large' });
      }
      return headers;
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-user-snipe-assets') && false;
    }
  },
  methods: {
    getLimitColumnData (item) {
      const limitFields = [
        { key: 'Limits sarunam', format: '$1' },
        { key: 'Degvielas Limits, EUR', format: '$1EUR' }
      ];
      for (const [, limit] of Object.entries(limitFields)) {
        if (item[limit.key]) {
          return limit.format.replace('$1', item[limit.key].value);
        }
      }
    },
    openAsset (item) {
      window.open(this.$store.getters.getDataFromConfigWithFilter('assets', 'item_url') + item.id, '_blank');
    },
    getTableData () {
      const userId = this.userId ? this.userId : this.$session.get('userId');
      if (userId) {
        this.loading = true;
        this.ApiRequest.request(`snipe-assets/user/${userId}/assets`, this.ApiRequest.REQUEST_GET, [], (response) => {
          if (response.data) {
            this.assets = response.data.rows;
            this.errorMsg = null;
          } else if (response.error) {
            this.errorMsg = this.ApiRequest.getApiResponseErrorMessage(response);
          }
          this.loading = false;
        });
      }
    },
    changeStatus (el, newTab, actionData) {
      el.newStatus = actionData.status;
      this.$refs.changeStatusMsgBox.show(
        el,
        this.$t('snipe-asset.asset.changeStatusTitle'),
        this.$t('snipe-asset.asset.changeStatusMessage')
      )
    },
    changeStatusApproved (el, approved = 0) {
      if (approved) {
        el.loading = true;
        const reqData = { id: el.id, status: el.newStatus }
        this.ApiRequest.request('snipe-assets/assets/change-status', this.ApiRequest.REQUEST_POST, reqData, function (response) {
          if (response.error) {
            self.ApiRequest.displayErrorDialog(response, self);
          } else {
            self.getTableData();
          }
          el.loading = false;
        });
      }
    }
  },
  watch: {
    userId: {
      immediate: true,
      handler (val) {
        if (val) {
          this.getTableData();
        }
      }
    }
  },
  mounted () {
    // show data for logged-in user. Otherwise, wait for this.userId to be set
    if (this.useLoggedInUser) {
      this.getTableData();
    }
  }
}
