import { mapState } from 'vuex';
import UserRightsInfo from '@/components/user/rights/info/UserRightsInfo.vue'

export default {
  name: 'EbgSystemUsers',
  props: {
    system: Object
  },
  data () {
    return {
      systemUsers: null,
      loading: false
    }
  },
  components: {
    UserRightsInfo
  },
  computed: {
    ...mapState(['config']),
    tableHeaders () {
      const headers = [
        { key: 'id', label: this.$t('user.ad-groups.all'), classTd: 'header-col col-width-large', type: 'slot', slotcode: 'AdGroupName' },
        { key: 'users', label: this.$t('menu.user.title'), format: 'roundedBlockList', blockParam: 'fullname', class: 'left-border', listClassFunc: this.getItemClass }
      ];
      return headers;
    }
  },
  methods: {
    getUsers () {
      if (this.system) {
        this.loading = true;
        this.ApiRequest.request('ad-groups/ebg-systems/' + this.system.id + '/users', this.ApiRequest.REQUEST_GET, [], (response) => {
          if (response.data) {
            this.systemUsers = response.data;
          }
          this.loading = false;
        });
      }
    },
    getItemClass (item, user) {
      if (parseInt(user.isDisabled) === 1) {
        return 'rounded-block-disabled';
      } else if (user.request) {
        return 'rounded-block-' + user.request;
      }
      return '';
    }
  },
  mounted () {
    this.getUsers();
  },
  watch: {
    system: {
      immediate: true,
      handler (val) {
        this.getUsers();
      }
    }
  }
}
