export default {
  name: 'UserBenefits',
  data () {
    return {
      isMounted: false,
      benefits: [],
      contracts: [],
      primaryPosition: '',
      loading: true,
      errorMsg: null,
      paging: {
        page: 1,
        pages: 1,
        limit: 10,
        total: 0
      }
    }
  },
  computed: {
    tableHeaders () {
      return [
        { key: 'title.' + this.$i18n.locale, label: this.$t('benefits.config.benefit'), class: 'w-20' },
        { key: 'description.' + this.$i18n.locale, label: this.$t('benefits.config.description'), format: 'html', class: 'w-50' },
        { key: 'details.' + this.$i18n.locale, label: this.$t('benefits.config.details'), format: 'html' }
      ];
    },
    requestPath () {
      return this.ApiRequest.addGetParamsToPath(`benefits/show/${this.$session.get('uuid')}?perPage=${this.paging.limit}&page=${this.paging.page}`, [], true);
    }
  },
  methods: {
    getContractData () {
      const items = [];
      this.contracts.forEach((item) => {
        items.push({
          position: item.position,
          contractLength: this.translateEmploymentLength(item.months)
        });
      });
      return items;
    },
    getTotalEmploymentTime () {
      const totalMonths = Math.max.apply(Math, this.contracts.map(function (o) { return o.months }));
      return this.translateEmploymentLength(totalMonths);
    },
    getTableData () {
      if (this.$session.get('uuid')) {
        this.loading = true;
        const self = this;
        this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, [], function (response) {
          if (response.data && response.data.benefits) {
            self.benefits = response.data.benefits;
            self.contracts = response.data.contracts;
            self.primaryPosition = response.data.primary_position;
            self.errorMsg = null;
          } else if (response.error) {
            self.errorMsg = self.ApiRequest.getApiResponseErrorMessage(response);
          }
          self.loading = false;
        });
      }
    },
    translateEmploymentLength (months) {
      let length = '';
      const years = Math.floor(months / 12);
      months = months % 12;
      if (years) {
        length += this.$t('benefits.user.year-' + (years === 1 ? 'singular' : 'plural'), { year: years });
      }
      if (months || !years) {
        length += ' ' + this.$t('benefits.user.month-' + (months === 1 ? 'singular' : 'plural'), { month: months });
      }
      return length.trim();
    }
  },
  mounted () {
    this.isMounted = true;
    this.getTableData();
  }
}
