import Draggable from 'vuedraggable'

export default {
  name: 'NotificationSortList',
  props: {
    notifications: {
      type: Object,
      default: {}
    },
    notificationsOrder: {
      type: Object,
      default: {}
    },
    company: {
      type: Number,
      default: null
    }
  },
  components: {
    Draggable
  },
  data () {
    return {
      list: [],
      active: {
        company: {},
        department: {}
      },
      order: {}
    };
  },
  computed: {
    allDepartments () {
      return this.$store.getters.getItemsFromConfigWithFilter('company_departments', null);
    },
    allEmployees () {
      let users = {};
      let jobPosition;
      for (const [companyId, departments] of Object.entries(this.notifications)) {
        for (const [departmentId, employees] of Object.entries(departments)) {
          employees.forEach((user) => {
            if (user.user || departmentId === 'direct') {
              const u = { name: '', position: '', approve: user.approve, notify: user.notify };
              if (departmentId === 'direct') {
                u.name = this.$t('custom-form.notifications.direct-' + user.employeeId);
                u.id = user.employeeId;
              } else {
                u.name = `${user.user.name} ${user.user.surname}`;
                u.id = user.user.id;
                jobPosition = user.user.job_positions.filter(obj => {
                  return obj && obj.job_position && obj && obj.job_position.company_id === companyId;
                })[0] || {};
                if (jobPosition && jobPosition.job) {
                  u.position = jobPosition.job.name[this.$i18n.locale];
                }
              }
              users = this.BasicHelper.setNestedObject(users, [companyId, departmentId, u.id], u);
            }
          });
        }
      }
      return users;
    }
  },
  methods: {
    generateOrderList () {
      const order = this.BasicHelper.cloneObject(this.notificationsOrder);
      order.departments = order.departments || {};
      order.employees = order.employees || {};
      // companies
      Object.keys(this.notifications).forEach((companyId) => {
        order.departments[companyId] = order.departments[companyId] || [];
        order.employees[companyId] = order.employees[companyId] || {};
        // departments
        Object.keys(this.notifications[companyId]).forEach((depId) => {
          order.employees[companyId][depId] = order.employees[companyId][depId] || [];
          if (!order.departments[companyId].includes(depId)) {
            order.departments[companyId].push(depId);
          }
          // employees
          if (this.allEmployees[companyId][depId] !== undefined) {
            Object.keys(this.allEmployees[companyId][depId]).forEach((employeeId) => {
              if (!order.employees[companyId][depId].includes(employeeId)) {
                order.employees[companyId][depId].push(employeeId);
              }
            });
          }
        });
      });
      this.order = this.BasicHelper.cloneObject(order);
    },
    getCompanyList (all) {
      const companies = this.$store.getters.getDataFromConfigWithFilter('user_companies', null);
      const list = {};
      for (const [companyId, data] of Object.entries(this.notifications)) {
        if (all !== true && this.company && parseInt(this.company, 10) !== parseInt(companyId, 10)) {
          continue;
        }
        list[companyId] = {
          company: companies[companyId],
          users: data
        };
      }
      return list;
    },
    getDepartmentList (companyId, order) {
      const list = [];
      order.forEach((key) => {
        let dep = {
          id: key,
          users: this.notifications[companyId][key],
          name: '-'
        }
        if (this.allDepartments[key] && this.notifications[companyId] && this.notifications[companyId][key]) {
          dep.name = this.BasicHelper.getTranslation(this.allDepartments[key].name, '-');
        } else if (key === 'direct') {
          dep.name = this.$t('custom-form.notifications.direct-notifications');
        } else {
          dep = null;
        }
        if (dep) {
          list.push(dep);
        }
      });
      return list;
    },
    getEmployeesList (companyId, departmentId, order) {
      const users = [];
      order.forEach((id) => {
        users.push(this.allEmployees[companyId][departmentId][id]);
      });
      return users;
    },
    toggleActive (key, level) {
      this.$set(this.active[level], key, this.active[level][key] === undefined ? true : !this.active[level][key])
    },
    updateSorting () {
      let data = this.BasicHelper.cloneObject(this.order);
      if (this.company) {
        data = { departments: {}, employees: {} };
        data.departments[this.company] = this.order.departments[this.company];
        data.employees[this.company] = this.order.employees[this.company];
      }
      this.$emit('update', data);
    }
  },
  beforeMount () {
    this.generateOrderList();
  },
  mounted () {
  }
}
