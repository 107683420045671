<template>
  <div>
    <nav v-if="$session.exists()" class="navbar navbar-light navbar-collapse flex-nowrap border-bottom page-header-nav">
      <div class="d-flex ms-2 me-2 justify-content-between" style="width: 100%">
        <button @click="showOffcanvas" class="navbar-toggler align-self-start d-block position-relative" data-action="showOffcanvas">
          <span class="navbar-toggler-icon"></span>
          <span class="position-absolute bottom-0 start-0 w-100 fw-bold" style="font-size: 0.6rem" >
            {{ shortversion }}
          </span>
        </button>
        <router-link :to="{ name: 'menu.home.title' }" class="ms-2">
          <span class="bi bi-house-fill text-tertiary fs-1" />
        </router-link>

        <div class="navbar-nav row flex-row row-cols-auto ms-2 subnavmenu align-items-center">
          <router-link
            v-for="(item, key) in subnav"
            class="show nav-link ms-2 me-2 d-none d-sm-block"
            :key="key + '-subnav'"
            :to="{ name: item.name }"
            :active-class="'active fw-bold'"
          >
            {{ (item.title ? $t(item.title) : $t(item.name)) }}
          </router-link>
        </div>

        <div
          v-tooltip
          class="d-flex align-items-center cursor-pointer"
          data-action="showIssueReport"
          :title="$t('issue_report.actions.add')"
          @click="showIssueReport()"
        >
          <span class="bi bi-question-square fs-1" />
        </div>

        <div class="dropdown text-nowrap me-2 nav-link fw-bold p-0 align-self-center ms-2 btn">
          <div
            :class="[
              canChangeUserRole ? 'dropdown-toggle' : '',
              'changeFullName'
            ]"
            id="userDropdownMenu"
            :data-bs-toggle="canChangeUserRole ? 'dropdown' : ''"
            aria-expanded="false"
          >
            {{ $session.get('fullname') }}
          </div>

          <ul class="dropdown-menu" aria-labelledby="userDropdownMenu" v-if="canChangeUserRole">
            <li v-for="(row, key) in userMenu" v-bind:key="key">
              <a class="dropdown-item" href="#" @click="changeRole">
                {{ $t(row.text) }}
              </a>
            </li>
          </ul>
        </div>

        <button class="btn btn-outline-tertiary btn-sm align-self-start text-nowrap" @click="logout">
          <span class="bi bi-door-open"></span>
          {{ $t("menu.user.logout") }}
        </button>
      </div>

      <offcanvas-common
        :prop-offcanvas-show="propOffcanvasShow"
        @offcanvas-hide="hideOffcanvas"
        @offcanvas-hidden="offcanvasVisible.resolve()">
        <template #body>
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-2">
            <a class="nav-link text-end" @click="hideOffcanvas">
              <i class="bi bi-x-lg" />
            </a>

            <li v-for="(module, moduleId) in modules" :key="moduleId" class="nav-item dropdown">
              <template v-if="module.children && module.children.length > 0">
                <a class="nav-link pt-0 pb-0 fw-bold text-black text-uppercase">
                  {{ (module.title ? $t(module.title) : $t(module.name)) }}
                </a>
                <ul class="navbar-sub py-1 show">
                  <li v-for="(child, childId) in module.children" :key="'sub1_' + childId">
                    <template v-if="child.children && child.children.length > 0">
                      <a class="nav-link fw-bold text-black fst-italic dropdown-item ps-2">
                        {{ (child.title ? $t(child.title) : $t(child.name)) }}
                      </a>
                      <ul class="navbar-sub py-1 show ms-2">
                        <li v-for="(subChild, sbuChildId) in child.children" :key="'sub2_' + sbuChildId" class="nav-item dropdown">
                          <router-link class="dropdown-item ps-2" :active-class="'active'" :to="{name: subChild.name}">
                            {{ (subChild.title ? $t(subChild.title) : $t(subChild.name)) }}
                          </router-link>
                        </li>
                      </ul>
                    </template>
                    <router-link v-else-if="!child.children || child.children.length == 0" class="dropdown-item show ps-2" :active-class="'active'" :to="{name: child.name}">
                      {{ (child.title ? $t(child.title) : $t(child.name)) }}
                    </router-link>
                  </li>
                </ul>
              </template>
              <router-link v-else-if="!module.children" class="dropdown-item text-uppercase show" :active-class="'active'" :to="{name: module.name}">
                {{ (module.title ? $t(module.title) : $t(module.name)) }}
              </router-link>
            </li>
          </ul>
        </template>
      </offcanvas-common>
    </nav>
    <nav v-else class="navbar navbar-light navbar-collapse flex-nowrap page-header-nav">
      <div class="d-flex ms-2 me-2 justify-content-between" style="width: 100%">
        {{ version }}
      </div>
    </nav>
    <main class="m-4 mt-2">
      <slot/>
    </main>
    <base-toast/>
    <UserRoleChange ref="userRoleChange" />
    <IssueReport ref="issueReport"/>
  </div>
</template>

<style>
  @media screen and (max-width: 576px) {
    .changeFullName {
      width: 135px;
      overflow: hidden;
      text-overflow: clip;
      display: block !important;
    }
  }
</style>

<script>
import BaseToast from 'ebg-vue-common/src/components/common/BaseToast.vue'
import OffcanvasCommon from 'ebg-vue-common/src/components/common/offcanvas.vue';
import { mapState } from 'vuex'
import UserRoleChange from '@/components/user/role/edit/UserRoleChange.vue';
import IssueReport from 'ebg-vue-common/src/components/common/issuereport/add/IssueReport.vue';

export default {
  name: 'LayoutDefault',

  data: function () {
    return {
      offcanvasHidden: true,
      propOffcanvasShow: false,
      offcanvasVisibilityPromise: null,
      offcanvasVisible: null
    }
  },

  components: {
    OffcanvasCommon,
    BaseToast,
    UserRoleChange,
    IssueReport
  },

  computed: {
    ...mapState({
      currentUser: state => state.user,
      version: state => state.config.version
    }),
    shortversion () {
      return (this.version ? this.version.substring(0, this.version.lastIndexOf('.')) : '')
    },

    userPermissions () {
      if (this.currentUser && this.currentUser.permissions) {
        const permissions = this.currentUser.permissions;
        if (typeof (permissions) !== 'object') {
          return [];
        }
        return permissions;
      }
      return [];
    },
    modules () {
      return this.filterMenuRecursive(this.$router.options.routes, this.userPermissions);
    },
    subnav () {
      if (this.currentUser) {
        if (typeof (this.$route.meta.parentPath) !== 'undefined') {
          let permissions = this.$session.get('permissions');
          if (typeof (permissions) !== 'object') {
            permissions = [];
          }
          let matchRoute = this.$router.getRoutes().find(el => el.name == this.$route.name);
          if (matchRoute) {
            if (!matchRoute.meta.isMenuItem) {
              matchRoute = matchRoute.parent;
            }
            const modules = this.findParentRouteRecursive(this.$router.options.routes, matchRoute.name);
            if (modules) {
              return this.filterMenuRecursive(modules, permissions);
            }
          }
        }
      }
      return [];
    },

    canChangeUserRole () {
      return this.ApiRequest.userHasPermission('view-role-change');
    },

    userMenu () {
      const menu = [];
      if (this.canChangeUserRole) {
        menu.push({ text: 'menu.user.dropdown.role', name: 'menu.user.dropdown.role' });
      }

      return menu;
    }
  },

  created () {
    this.$router.beforeEach((to, from, next) => {
      this.hideOffcanvas();
      if (this.offcanvasVisibilityPromise) {
        this.offcanvasVisibilityPromise.then(() => {
          next();
        });
      } else {
        next();
      }
    });
  },

  methods: {
    showIssueReport () {
      this.$refs.issueReport.showModal()
    },
    hideOffcanvas () {
      this.propOffcanvasShow = false;
    },

    showOffcanvas () {
      this.offcanvasVisibilityPromise = new Promise((resolve, reject) => {
        this.offcanvasVisible = {
          resolve,
          reject
        };
      });

      this.propOffcanvasShow = true;
    },

    logout () {
      const self = this;
      this.loggingOut = true;
      this.ApiRequest.request('logout', this.ApiRequest.REQUEST_POST, [], function (response) {
        self.loggingOut = false;
        self.$store.dispatch('logout', self.$session);
        self.$router.push('/login');
      });
    },
    // changeLocale (localeCode) {
    //   this.$root.$i18n.locale = localeCode;
    //   this.$cookie.set('locale', localeCode, { expires: '1Y' });
    //   this.$parent.$parent.$forceUpdate();
    //   if (this.$session.exists()) {
    //     this.$session.set('locale', localeCode);
    //     this.$parent.$parent.$forceUpdate();
    //     this.ApiRequest.request('user/change-locale', this.ApiRequest.REQUEST_POST, { locale: localeCode }, function () {})
    //   }
    // },
    filterMenuRecursive (modules, permissions) {
      return this.BasicHelper.cloneObject(modules).filter(el => {
        if (this.currentUser && this.currentUser.layoutConfiguration) {
          if (el.name) {
            if (this.currentUser.layoutConfiguration.includes(el.name)) {
              return false;
            }
          }
        }
        if (el.children) {
          el.children = this.filterMenuRecursive(el.children, permissions);
        }
        if (el.meta && el.meta.isMenuItem &&
          (el.meta.permissions === null || el.meta.permissions.filter(Set.prototype.has, new Set(permissions)).length > 0)) {
          return true;
        }
        return false;
      });
    },
    findParentRouteRecursive (modules, name) {
      let matchModules = null;
      modules.forEach(el => {
        if (matchModules == null) {
          if (el.name && el.name == name) {
            matchModules = this.BasicHelper.cloneObject(modules);
          } else if (el.children) {
            matchModules = this.findParentRouteRecursive(el.children, name);
          }
        }
      });
      return matchModules;
    },

    changeRole () {
      this.$refs.userRoleChange.changeRole();
    }
  },

  watch: {
    currentUser: {
      immediate: true,
      handler () {
        if (this.currentUser != undefined || this.currentUser != null) {
          localStorage.setItem('availableTesterCategories', JSON.stringify(
            this.currentUser == undefined || this.currentUser == null ? null : this.currentUser.availableTesterCategories))
          const findTesterProfileRoute = this.$router.options.routes.find(el => el.path == '/testerProfile')
          if (findTesterProfileRoute) {
            const dataSet = []
            const vm = this
            if (this.currentUser != null && this.currentUser.availableTesterCategories != null) {
              this.currentUser.availableTesterCategories.forEach(function callback (value, index) {
                dataSet.push(
                  {
                    path: '/testerProfile/' + value.id.toString(),
                    name: value.name,
                    // component: TesterWorkPlace,
                    query: { id: value.id },
                    meta: {
                      public: false,
                      noSession: false,
                      isMenuItem: true,
                      permissions: null,
                      parentPath: '/testerProfile',
                      isConfigPage: false,
                      dynamicChildrenComponents: 'TesterProfileSubMenu'
                    }
                  })
                vm.$router.addRoute({
                  path: '/testerProfile/' + value.id.toString(),
                  name: value.name,
                  // component: TesterWorkPlace,
                  query: { id: value.id },
                  meta: {
                    public: false,
                    noSession: false,
                    isMenuItem: true,
                    permissions: null,
                    parentPath: '/testerProfile',
                    isConfigPage: false,
                    dynamicChildrenComponents: 'TesterProfileSubMenu'
                  }
                })
              })
              this.$router.options.routes[this.$router.options.routes.indexOf(findTesterProfileRoute)].children = dataSet
            }
          }
        }
      }
    }
  }
}
</script>
