import NewsItem from '@/components/news/item/NewsItem.vue';

export default {
  name: 'NewsList',
  components: {
    NewsItem
  },
  data () {
    return {
      isMounted: false,
      loading: false,
      paging: {
        page: 1,
        pages: 1,
        limit: 10,
        total: 0
      },
      tableSorting: { item: 'title', direction: 'asc' },
      tableItems: [],
      filterValues: {
        title: null,
        published_at: [1]
      }
    }
  },
  computed: {
    showEditTable () {
      return this.$route.meta.allowListEdit && this.canEdit;
    },
    tableHeaders () {
      const headers = [
        { key: 'title', label: this.$t('news.title'), class: 'col-width-xl', filterKey: 'title' },
        { key: 'published_at', label: this.$t('news.published_at'), class: 'col-width-xl', filterKey: 'published_at' },
        { key: 'companies_access', label: this.$t('news.companies_access'), class: 'col-width-xl', type: 'slot', slotcode: 'companies_access' }
      ];
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('news.edit-item'), emit: 'editItem', class: 'btn-warning btn-sm', bicon: 'pencil-fill', ifconfig: { param: 'deleted_at', value: null } });
      }
      if (this.canDelete) {
        actions.push({ label: this.$t('news.delete-item'), emit: 'deleteItem', class: 'btn-danger btn-sm', bicon: 'trash', ifconfig: { param: 'deleted_at', value: null } });
        actions.push({ label: this.$t('news.restore-item'), emit: 'restoreItem', class: 'btn-success btn-sm', bicon: 'clock-history', ifconfig: { param: 'deleted_at', value: null, notCheck: true } });
      }
      if (actions) {
        headers.push({ key: 'actions', label: '', actions: actions, class: 'col-width-medium' });
      }
      return headers;
    },
    requestPath () {
      let path = 'news?perPage=' + this.paging.limit + '&page=' + this.paging.page;
      if (this.$route.meta.allowListEdit === true) {
        path = path + '&manage_list=true';
      }
      return this.ApiRequest.addGetParamsToPath(
        path,
        (this.$refs.tableview ? this.$refs.tableview.getFilterValues() : this.filterValues),
        true
      );
    },
    tableActions () {
      return {
        add: { title: this.$t('news.add-new') }
      }
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-news');
    },
    canDelete () {
      return this.ApiRequest.userHasPermission('delete-news');
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    tableFilterConfig () {
      const filters = {};
      filters.title = { type: 'text', label: 'title', colOnlyFilter: true };
      filters.published_at = { type: 'checkbox', label: 'published_at', options: this.yesNoOptions, colOnlyFilter: true };
      filters.deleted_at = { type: 'checkbox-single', label: this.$t('general.filters.deleted'), inlineLabel: true };
      return filters;
    }
  },
  methods: {
    editItem (data = null) {
      const routeData = this.$router.resolve({ name: 'menu.admin.news-edit', params: { id: data?.id ?? 'NEW' } });
      this.$router.push(routeData.href);
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t('news.delete-title'), this.$t('news.delete-message', { title: el.title }))
      }
    },
    restoreItem (el) {
      this.setDeleted(el, 0);
    },
    getTableData () {
      this.loading = false;
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.loading = true;
      this.$forceUpdate();
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, [], (response) => {
        if (response.data) {
          this.tableItems = response.data;
          this.paging = response.paging;
          this.loading = false;
        }
      });
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    setDeleted (el, isDeleted) {
      this.loading = true;
      const path = isDeleted === 1 ? `news/${el.id}` : `news/${el.id}/restore`;
      const method = isDeleted === 1 ? this.ApiRequest.REQUEST_DELETE : this.ApiRequest.REQUEST_PATCH;
      this.ApiRequest.request(path, method, [], (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.getTableData();
        }
      });
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
    this.isMounted = true;
  }
}
