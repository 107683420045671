export default {
  name: 'FolderPicker',
  props: ['value', 'disabled'],
  data () {
    return {
      loading: false,
      content: this.value,
      searchTxt: '',
      currentReq: null
    };
  },
  computed: {
    hasAnyResults () {
      return this.itemOptions && this.searchTxt && this.searchTxt.length > 0 && this.itemOptions.length === 0;
    },
    folderOptions () {
      return this.EbgFolderHelper.getFolderOptions();
    },
    itemOptions () {
      const search = this.searchTxt.trim().toLowerCase();
      if (search.length > 0) {
        return this.folderOptions.filter(el => (el.groupbase.toLowerCase().startsWith(search) || el.name.toLowerCase().startsWith(search) || el.path.toLowerCase().startsWith(search)));
      }
      return null;
    },
    selectedItemCode () {
      if (this.content) {
        const folderId = this.content;
        const folder = this.folderOptions.find(el => el.id === folderId);
        if (folder) {
          return folder.groupbase;
        }
      }
      return '';
    }
  },
  methods: {
    updateValue () {
      this.$emit('input', this.content);
      this.$emit('changed', this.content);
    },
    searchFocus () {
      if (this.content === null) {
        this.searchItem(this.searchTxt)
      }
    },
    searchItem () {
      this.content = null;
    },
    getItemText (folderId) {
      if (folderId) {
        const folder = this.folderOptions.find(el => el.id === folderId);
        if (folder) {
          return folder.path;
        }
      }
      return '';
    },
    selectItem (item) {
      this.searchTxt = this.getItemText(item.id);
      this.content = item.id;
      this.updateValue();
      this.loading = false;
    },
    clearSearch () {
      this.searchTxt = '';
      this.content = null;
      this.loading = false;
      this.updateValue();
    },
    editElement (refName, element) {
      this.$refs[refName].show(element);
    }
  },
  mounted () {
    this.searchTxt = this.getItemText(this.content);
  }
}
