import { mapState } from 'vuex'

export default {
  name: 'PermissionListExpand',
  props: {
    data: Object
  },
  computed: {
    ...mapState(['config'])
  }
}
