import CustomTreePickerView from '@/components/common/customtree/pickerview/CustomTreePickerView.vue'
import ContentBlock from '@/components/contentblocks/view/ContentBlocksView.vue'

export default {
  name: 'LandingPage',
  components: {
    CustomTreePickerView,
    ContentBlock
  },
  data () {
    return {
      blocks: [],
      loading: true
    }
  },
  methods: {
    loadContentBlocks () {
      const codes = ['HOME_NEWS', 'HOME_LINKS', 'HOME_BIRTHDAYS', 'HOME_SUPPORT', 'HOME_IMAGE', 'HOME_GOALS'];
      this.ApiRequest.request('config/content-blocks/view', this.ApiRequest.REQUEST_POST, { codes: codes }, (response) => {
        if (response.data) {
          this.blocks = response.data;
        }
        this.loading = false;
      });
    }
  },
  mounted () {
    this.loadContentBlocks();
  }
}
