import EditBenefit from '@/components/benefits/edit/EditBenefit.vue';

export default {
  name: 'BenefitsList',
  data () {
    return {
      isMounted: false,
      loading: false,
      paging: {
        page: 1,
        pages: 1,
        limit: 10,
        total: 0
      },
      tableSorting: { item: 'title', direction: 'asc' },
      itemData: [],
      filterValues: {
        company: []
      }
    }
  },
  components: {
    EditBenefit
  },
  computed: {
    tableHeaders () {
      const headers = [
        { key: 'title.' + this.$i18n.locale, label: this.$t('benefits.config.benefit'), class: 'col-width-xl' },
        { key: 'description.' + this.$i18n.locale, label: this.$t('benefits.config.description'), format: 'html' },
        { key: 'details.' + this.$i18n.locale, label: this.$t('benefits.config.details'), format: 'html' },
        { key: 'months_min', label: this.$t('benefits.config.employment-length-min-short'), class: 'col-width-medium' },
        { key: 'months_max', label: this.$t('benefits.config.employment-length-max-short'), class: 'col-width-medium' },
        { key: 'company_id', label: this.$t('benefits.config.company_id'), format: 'formatFromConfig', configKey: 'user_companies', configParam: 'name', class: 'col-width-small' }
      ];
      if (this.canEdit) {
        headers.push({
          key: 'actions',
          label: '',
          actions: [
            { label: this.$t('benefits.config.edit-item'), emit: 'editItem', class: 'btn-warning btn-sm', bicon: 'pencil-fill' },
            { label: this.$t('benefits.config.delete-item'), emit: 'deleteItem', class: 'btn-danger btn-sm', bicon: 'trash', ifconfig: { param: 'deleted_at', value: null } },
            { label: this.$t('benefits.config.restore-item'), emit: 'restoreItem', class: 'btn-success btn-sm', bicon: 'clock-history', ifconfig: { param: 'deleted_at', value: null, notCheck: true } }
          ],
          class: 'col-width-medium'
        });
      }
      return headers;
    },
    requestPath () {
      return this.ApiRequest.addGetParamsToPath(
        'config/benefits/list?perPage=' + this.paging.limit + '&page=' + this.paging.page,
        (this.$refs.tableview ? this.$refs.tableview.getFilterValues() : this.filterValues),
        true
      );
    },
    tableActions () {
      return {
        add: { title: this.$t('benefits.config.add-new') }
      }
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-company-settings');
    },
    tableExpandConfig () {
      return { component: 'slot' };
    },
    tableFilterConfig () {
      return {
        company: { type: 'checkbox', label: this.$t('company-structure.company.name'), options: this.companyOptions, class: 'col-md-12' }
      }
    },
    companyOptions () {
      const options = this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0'));
      options.unshift({ code: 'NULL', text: this.$t('general.item.ALL') });
      return options;
    }
  },
  methods: {
    editItem (data) {
      this.$refs['edit-benefit'].show(data);
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t('benefits.config.delete-title'), this.$t('benefits.config.delete-message'))
      }
    },
    restoreItem (el) {
      this.setDeleted(el, 0);
    },
    getTableData () {
      this.loading = false;
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.loading = true;
      this.$forceUpdate();
      const self = this;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, [], function (response) {
        if (response.data) {
          self.itemData = response.data;
          self.paging = response.paging;
          self.loading = false;
        }
      });
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    setDeleted (el, isDeleted) {
      this.loading = true;
      const self = this;
      this.ApiRequest.request('config/benefits/delete', this.ApiRequest.REQUEST_POST, { id: el.id, deleted: isDeleted }, function (response) {
        if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
          self.loading = false;
        } else {
          self.getTableData();
        }
      });
    }
  },
  mounted () {
    this.isMounted = true;
    this.getTableData();
  }
}
