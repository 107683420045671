import Vue from 'vue'
import Vuex from 'vuex';
import App from './App.vue'
import 'ebg-vue-common/src/filters/common.js'
import BasicHelper from 'ebg-vue-common/src/helpers/BasicHelper.js'
import ApiRequest from 'ebg-vue-common/src/helpers/ApiRequest.js'
import store from 'ebg-vue-common/src/store/index.js'
import { tooltip } from 'ebg-vue-common/src/directives/tooltip.js'
import EbgVueCommonSimple from 'ebg-vue-common/src/install.js'
import { clickoutside } from 'ebg-vue-common/src/directives/clickoutside.js';
import RoundedBlockList from 'ebg-vue-common/src/components/common/roundedblocklist/RoundedBlockList.vue'

import HrHelper from './helpers/HrHelper.js'
import EbgFolderHelper from './helpers/EbgFolderHelper.js';
import router from './router'
import VueSession from 'vue-session'
import VueCookie from 'vue-cookie'
import i18n from './i18n'
import hrstore from './store/hr.js'
import './assets/scss/custom-bootstrap.scss'
import moment from 'moment'
import AdGroupName from '@/components/adgroup/name/AdGroupName.vue'

Vue.config.productionTip = false

Vue.use(Vuex);

Vue.use(VueSession, { persist: true })
Vue.use(VueCookie)
Vue.use(EbgVueCommonSimple);
Vue.component('RoundedBlockList', RoundedBlockList);
Vue.component('AdGroupName', AdGroupName);

Vue.prototype.ApiRequest = ApiRequest
Vue.prototype.BasicHelper = BasicHelper
Vue.prototype.EbgFolderHelper = EbgFolderHelper
Vue.prototype.HrHelper = HrHelper

Vue.directive('tooltip', tooltip);
Vue.directive('click-outside', clickoutside);

store.registerModule('hr', hrstore);

Vue.prototype.moment = moment;

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
