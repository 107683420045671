export default {
  name: 'CustomFormRecordView',
  props: {
    form: {
      type: Object,
      default: {}
    },
    record: {
      type: Object,
      default: {}
    }
  },
  computed: {
    canDestroy () {
      return this.ApiRequest.userHasPermission('delete-custom-forms-file');
    },

    statusVariant () {
      if (this.recordStatus == 'OPEN') {
        return 'warning text-dark'
      } else if (this.recordStatus == 'APPROVED' || this.recordStatus == 'PROCESSED') {
        return 'success';
      } else if (this.recordStatus == 'REJECTED' || this.recordStatus == 'CANCELED') {
        return 'danger';
      }
      return 'info';
    },
    recordStatus () {
      if (this.record.status_id) {
        const recordStatus = this.$store.getters.getDataFromConfigWithFilter('custom_form_record_statuses', 'id', this.record.status_id);
        return recordStatus.code;
      }
      return null;
    }
  },
  methods: {
    getCheckboxValues (field) {
      let res = '';
      const self = this;
      if (this.record.fields[field.id]) {
        this.record.fields[field.id].forEach(function (optionId) {
          if (field.options && field.options[optionId]) {
            if (res !== '') {
              res += ', ';
            }
            res += self.BasicHelper.getTranslation(field.options[optionId])
          }
        });
      }
      return res;
    },

    getUserNameValues (data) {
      let text = '';
      if (typeof data === 'object') {
        if (data.length != undefined && data.length > 0) {
          data.forEach(function (row) {
            text += row.fullname + ' ';
          })
        } else {
          text = data.fullname;
        }
      }
      return text;
    },

    downloadFile (fileId) {
      const path = `custom-form/form/file/download/${fileId}`;
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_DOWNLOAD, [], () => {});
    },

    async destroyFile (el, approved) {
      if (approved) {
        let reqData = this.BasicHelper.cloneObject(el);
        const fileData = [];
        el.deleted = true;
        fileData.push(el);
        reqData.files = fileData;
        reqData = this.ApiRequest.createFormData(reqData, Object.keys(reqData), 'form', this, false, ['filename']);
        const { data } = await this.destroy(`custom-form/form/file/destroy/${this.$route.params.recordid}`, reqData);
        if (data) {
          this.$store.commit('toastAdd', {
            context: 'success',
            message: this.$t('user.jobs.delete_successfully', { jobs: el.filename })
          });
        }
        this.$emit('reload');
      } else {
        this.$refs.fileDestroyMsgBox.show(el, this.$t('custom-form.form.title-fail'), this.$t('user.jobs.text-delete', { jobs: el.filename }))
      }
    },

    async destroy (path, data) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_FILEUPLOAD, data, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  }
}
