import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import UserPicker from 'ebg-vue-common/src/components/user/picker/UserPicker.vue'

const defaultEl = {
  id: null,
  name: {},
  code: '',
  company_id: null,
  head_user: null,
  head_user_id: null,
  manager_user: null,
  manager_user_id: null
};

export default {
  name: 'DepartmentEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false
    }
  },
  components: {
    UserPicker
  },
  validations: {
    editEl: {
      name: { required },
      code: { required },
      company_id: { required }
    }
  },
  computed: {
    getTitle () {
      if (this.editEl.id) {
        return this.$t('menu.companies.department.edit');
      }
      return this.$t('menu.companies.department.new');
    },
    companyOptions () {
      return this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0'), 'text');
    },
    companyShortcode () {
      if (this.editEl.company_id) {
        return this.$store.getters.getParamFromConfig('user_companies', this.editEl.company_id, 'shortcode') + '_';
      }
      return '';
    },
    canEdit () {
      return (!this.loading && this.ApiRequest.userHasPermission('edit-company-settings'));
    }
  },
  methods: {
    show (elData) {
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
        this.editEl.company_id = this.companyOptions[0].value
      }
      this.v$.$reset();
      this.$refs['modal-window'].show();
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        reqData.head_user_id = (reqData.head_user ? reqData.head_user.id : null);
        reqData.head_user = null;
        reqData.manager_user_id = (reqData.manager_user ? reqData.manager_user.id : null);
        reqData.manager_user = null;
        this.ApiRequest.request('config/company/departments/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this, { 'item.code': 'general.item.code' });
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    },
    nameChanged (value) {
      if (this.editEl.id === null && value[this.$i18n.locale]) {
        this.editEl.code = this.BasicHelper.generateCodeByName(value[this.$i18n.locale], 'DEP_' + this.companyShortcode, 30);
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
