import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core';

const defaultEl = {
  id: null,
  userApi_id: null,
  level_id: null,
  place_id: null,
  type_id: null,
  specialty_id: null,
  document: '',
  documentNo: '',
  startYear: null,
  endYear: null,
  isDocumentPresented: false
}

export default {
  name: 'UserEducationEdit',

  data () {
    return {
      form: [],
      header_title: '',
      isLoading: false
    }
  },

  validations: {
    form: {
      level_id: { required },
      place_id: { required }
    }
  },

  computed: {
    levelOptions () {
      return this.$store.getters.getConfigOptions('education_levels', this.$i18n.locale);
    },

    placeOptions () {
      return this.$store.getters.getConfigOptions('education_places', this.$i18n.locale);
    },

    typeOptions () {
      return this.$store.getters.getConfigOptions('education_types', this.$i18n.locale);
    },

    specialtyOptions () {
      return this.$store.getters.getConfigOptions('education_specialities', this.$i18n.locale);
    }
  },

  methods: {
    async show (id, userApiId) {
      this.v$.$reset();
      this.form = this.BasicHelper.cloneObject(defaultEl);
      if (id !== 'NEW') {
        const { data } = await this.loadData(`user/education/show/${id}`);
        this.BasicHelper.syncObjectValuesByKeys(this.form, data);
      }
      this.form.id = id;
      this.form.userApi_id = userApiId;
      this.header_title = id === 'NEW' ? this.$t('user.education.title-add') : this.$t('user.education.title-update');
      this.$refs['modal-window'].show()
    },

    async submitForm () {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      this.isLoading = true;
      const { data } = await this.save(`user/education/save/${this.form.id}`);
      let placeName = '';
      if (data) {
        placeName = this.$store.getters.getParamFromConfig('education_places', data, 'name', this.$i18n.locale);
      }
      this.$refs['modal-window'].hide();
      this.$emit('changeDate');
      this.isLoading = false;
      let msg = ''
      if (this.form.id === 'NEW') {
        msg = this.$t('user.education.msg-add-successfully', { name: placeName });
      } else {
        msg = this.$t('user.education.msg-update-successfully', { name: placeName });
      }

      this.$store.commit('toastAdd', { context: 'success', message: msg });
    },

    async save (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, this.form, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    },

    async loadData (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, this.form, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  },

  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'UserEducationEdit' }) }
  }
}
