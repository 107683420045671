import PermissionRequestListExpand from '@/components/permissionrequests/listexpand/PermissionRequestListExpand.vue'

const defaultFilterValues = {
  onlyMine: true,
  onlyMineDirect: true,
  request_statuses: ['OPEN']
}

export default {
  name: 'PermissionRequestList',
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: {
      },
      tableSorting: { item: 'created_at', direction: 'desc' },
      paging: { limit: 50, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  components: {
    PermissionRequestListExpand
  },
  computed: {
    requestType () {
      if (this.$route.name === 'menu.requests.folder-requests') {
        return 'folder';
      }
      return 'system';
    },
    removeRequestTypeId () {
      return this.$store.getters.getParamFromConfigByFilter('permission_request_types', 'code', 'REMOVE', 'id');
    },
    readFolderPermissionTypeId () {
      return this.$store.getters.getParamFromConfigByFilter('ad_group_permission_types', 'code', 'R', 'id');
    },
    tableHeaders () {
      const headers = [
        { key: 'created_at', label: this.$t('permission-request.created'), type: 'datetime' },
        { key: 'user.email', label: this.$t('permission-request.user'), extraKey: 'username', filterKey: 'username' }
      ];
      if (this.requestType === 'folder') {
        headers.push({ key: 'folder_id', label: this.$t('permission-request.folder'), format: 'formatFunc', funcs: [this.getFolderPath], classFuncs: [null] })
        headers.push({ key: 'group.permission_type_id', label: this.$t('permission-request.permissions'), format: 'formatFromConfig', configKey: 'ad_group_permission_types', configParam: 'name', filterKey: 'permission_types', classfunc: this.getPermissionClass })
      } else {
        headers.push({ key: 'system_id', label: this.$t('permission-request.system'), format: 'formatFromConfig', configKey: 'ebg_systems', configParam: 'title' });
        headers.push({ key: 'group_id', label: this.$t('permission-request.group'), type: 'slot', slotcode: 'AdGroupName' })
      }
      headers.push({ key: 'request_type_id', label: this.$t('permission-request.request_type'), format: 'formatFromConfig', configKey: 'permission_request_types', configParam: 'name', filterKey: 'request_types', classfunc: this.getRequestTypeClass });
      headers.push({ key: 'status_id', label: this.$t('permission-request.status'), format: 'formatFromConfig', configKey: 'permission_request_statuses', configParam: 'name', filterKey: 'request_statuses' });
      headers.push({ key: 'comment', label: this.$t('permission-request.comment'), type: 'input', ifFunc: this.canUpdateRequest });
      const actions = [
        { label: this.$t('menu.requests.update'), emit: 'updateRequest', bicon: 'stickies-fill', class: 'btn-secondary', ifconfig: { func: this.canUpdateRequest, disableNotHide: true, useFunc: true } },
        { label: this.$t('menu.requests.reject'), emit: 'rejectRequest', bicon: 'x', class: 'btn-danger', ifconfig: { func: this.canRejectRequest, disableNotHide: true, useFunc: true } },
        { label: this.$t('menu.requests.approve'), emit: 'approveRequest', bicon: 'check', class: 'btn-primary', ifconfig: { func: this.canApproveRequest, disableNotHide: true, useFunc: true } }
      ];
      headers.push({ key: 'actions', actions: actions, class: 'col-width-large' });
      return headers;
    },
    tableExpandConfig () {
      return { component: 'slot' };
    },
    tableFilterConfig () {
      const filters = { };
      filters.onlyMine = { type: 'checkbox-single', label: this.$t('permission-request.onlyMine'), inlineLabel: true };
      if (this.requestType === 'folder') {
        filters.onlyMineDirect = { type: 'checkbox-single', label: this.$t('permission-request.onlyMineDirect'), inlineLabel: true };
      }
      filters.username = { type: 'text', label: '', colOnlyFilter: true };
      filters.permission_types = { type: 'checkbox', label: '', options: this.permissionTypeOptions, colOnlyFilter: true };
      filters.request_types = { type: 'checkbox', label: '', options: this.requestTypeOptions, colOnlyFilter: true };
      filters.request_statuses = { type: 'checkbox', label: '', options: this.requestStatusOptions, colOnlyFilter: true };
      return filters;
    },
    tableActions () {
      const actions = { download: false };
      return actions;
    },
    permissionTypeOptions () {
      return this.$store.getters.getConfigOptions('ad_group_permission_types', this.$i18n.locale);
    },
    requestStatusOptions () {
      return this.$store.getters.getConfigOptions('permission_request_statuses', this.$i18n.locale);
    },
    requestTypeOptions () {
      return this.$store.getters.getConfigOptions('permission_request_types', this.$i18n.locale);
    },
    approveStatusId () {
      return this.$store.getters.getParamFromConfigByFilter('permission_request_statuses', 'code', 'APPROVED', 'id');
    },
    rejectStatusId () {
      return this.$store.getters.getParamFromConfigByFilter('permission_request_statuses', 'code', 'REJECTED', 'id');
    }
  },
  methods: {
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    canApproveRequest (item, index) {
      return item.canEdit && (parseInt(item.status_id) !== this.approveStatusId);
    },
    canRejectRequest (item, index) {
      return item.canEdit && (parseInt(item.status_id) !== this.rejectStatusId);
    },
    canUpdateRequest (item, index) {
      return item.canEdit && !item.loading;
    },
    approveRequest (item) {
      this.editRequestData(item, { comment: item.comment, action: 'APPROVE' });
    },
    rejectRequest (item) {
      this.editRequestData(item, { comment: item.comment, action: 'REJECT' });
    },
    updateRequest (item) {
      this.editRequestData(item, { comment: item.comment });
    },
    getFolderPath (folderId, data) {
      return this.EbgFolderHelper.getFolderPath(folderId).path;
    },
    editRequestData (item, reqData) {
      item.loading = true;
      this.$refs.tableview.$forceUpdate();
      const self = this;
      this.ApiRequest.request('ad-groups/requests/list/' + this.requestType + '/' + item.id + '/update', this.ApiRequest.REQUEST_POST, reqData, function (response) {
        if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
        } else if (response.data) {
          Object.keys(response.data).forEach(key => {
            item[key] = response.data[key];
          });
        }
        item.loading = false;
        self.$refs.tableview.$forceUpdate();
      });
    },
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
      this.loading = true;
      const self = this;
      let path = 'ad-groups/requests/list/' + this.requestType + '?perPage=' + this.paging.limit + '&page=' + this.paging.page;
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, this.filterValues, function (response) {
        if (response.data) {
          self.tableItems = response.data;
          self.paging = response.paging;
          self.loading = false;
        }
      });
    },
    setComponentData () {
      this.isMounted = false;
      this.loading = false
      this.paging.page = 1;
      this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
      this.getTableData();
      this.isMounted = true;
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    getRequestTypeClass (item) {
      if (parseInt(item.request_type_id) === this.removeRequestTypeId) {
        return 'fw-bold text-danger';
      }
      return 'fw-bold text-primary';
    },
    getPermissionClass (item) {
      if (parseInt(item.group.permission_type_id) === this.readFolderPermissionTypeId) {
        return 'fw-bold';
      }
      return 'fw-bold text-primary';
    }
  },
  watch: {
    '$route' () {
      this.setComponentData();
    }
  },
  mounted () {
    if (this.$route.query.defaultFilters) {
      // clear custom session filters for each route component is used
      this.BasicHelper.updateFilterValues('menu.requests.folder-requests', defaultFilterValues);
      this.BasicHelper.updateFilterValues('menu.requests.system-requests', defaultFilterValues);
    }
    this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
    this.setComponentData();
  }
}
