import CustomTree from '@/components/common/customtree/tree/CustomTree.vue'

const defaultTreeData = {
  id: null,
  name: {},
  description: {},
  type_id: null,
  company_id: null,
  public: true,
  revision: {
    id: null,
    version: '',
    data: {}
  }
};

export default {
  name: 'CustomTreeView',
  props: {
    treeId: {
      type: Number,
      default: null
    },
    data: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      editable: false,
      loading: true,
      updating: false,
      loadingRevisions: false,
      currentReq: null,
      tree: null,
      revisionId: null,
      revisions: null,
      paging: { limit: 10, page: 1, total: 0, pages: 0 }
    };
  },
  components: {
    CustomTree
  },
  computed: {
    tableHeaders () {
      const headers = [
        { key: 'version', label: this.$t('custom-tree.tree.version') },
        { key: 'created_at', label: this.$t('general.item.created_at'), type: 'datetime' },
        { key: 'updated_at', label: this.$t('general.item.updated_at'), type: 'datetime' },
        { key: 'isActive', label: this.$t('custom-tree.tree.isActiveRevision'), format: 'formatFunc', funcs: [this.isActiveRevision], classFuncs: [this.isActiveRevisionClass] }
      ];
      if (this.canEdit) {
        const actions = [];
        if (this.canEdit) {
          actions.push({ label: this.$t('menu.custom-tree.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-warning' });
          actions.push({ label: this.$t('menu.custom-tree.activate'), emit: 'activateItem', bicon: 'check', class: 'btn-primary' });
        }
        headers.push({ key: 'actions', label: '', actions: actions });
      }
      return headers;
    },
    requestPath () {
      return 'custom-tree/view';
    },
    canEdit () {
      return this.editable && (this.ApiRequest.userHasPermission('edit-all-custom-trees') || this.ApiRequest.userHasPermission('edit-company-custom-trees'));
    },
    treeElId () {
      if (this.treeId) {
        return this.treeId
      } else if (this.data) {
        return this.data.id;
      }
      return this.$route.params.treeid
    },
    companyOptions () {
      const options = this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0'));
      options.unshift({ value: null, text: '' })
      return options;
    },
    typeOptions () {
      return this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getConfigOptions('custom_tree_types', this.$i18n.locale));
    },
    tableActions () {
      const actions = { download: false };
      if (this.canEdit) {
        actions.add = { title: this.$t('menu.custom-tree.new-revision') };
      }
      return actions;
    }
  },
  methods: {
    loadData () {
      if (this.treeElId === 'NEW') {
        const tree = this.BasicHelper.cloneObject(defaultTreeData);
        tree.revision.data[this.$i18n.locale] = [];
        this.tree = tree;
        this.revisions = null;
        this.tree.type_id = this.typeOptions[0].value;
        this.loading = false;
      } else if (this.treeElId) {
        this.loading = true;
        const self = this;
        let path = this.requestPath + '/' + this.treeElId + '/revision';
        if (this.editable) {
          path += '-all';
        }
        if (this.revisionId) {
          path += '/' + this.revisionId;
        }
        this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, function (response) {
          if (response.data) {
            self.tree = response.data.tree;
            self.tree.public = (parseInt(self.tree.public) === 1);
            self.revisions = response.data.revisions;
          }
          self.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
    save () {
      this.updating = true;
      const reqData = this.BasicHelper.cloneObject(this.tree);
      const self = this;
      this.ApiRequest.request('custom-tree/edit', this.ApiRequest.REQUEST_POST, { tree: reqData }, function (response) {
        if (response.data) {
          self.tree = response.data.tree;
          self.tree.public = (parseInt(self.tree.public) === 1);
          self.revisions = response.data.revisions;
        } else if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
        }
        self.updating = false;
      });
    },
    getRevisions () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.loadingRevisions = true;
      const self = this;
      this.ApiRequest.request(this.requestPath + '/' + this.treeElId + '/revisions', this.ApiRequest.REQUEST_GET, {}, function (response) {
        if (response.data) {
          self.revisions = response.data;
          self.loadingRevisions = false;
        }
      });
    },
    viewRevision (el) {
      this.revisionId = el.id;
      this.loadData();
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    newRevision () {
      this.tree.revision.id = null;
      this.tree.revision.version = '';
    },
    isActiveRevision (val, item) {
      if (this.tree && this.tree.active_revision_id && parseInt(item.item.id) === parseInt(this.tree.active_revision_id)) {
        return this.$t('general.yes');
      }
      return this.$t('general.no');
    },
    isActiveRevisionClass (val, item) {
      if (this.tree && this.tree.active_revision_id && parseInt(item.item.id) === parseInt(this.tree.active_revision_id)) {
        return 'text-success font-weight-bold'
      }
      return 'text-danger';
    },
    getRevisionRowClass (item) {
      if (this.tree && this.tree.revision && parseInt(item.id) === parseInt(this.tree.revision.id)) {
        return 'active';
      }
      return '';
    },
    activateRevision (item) {
      item.loading = true;
      this.$refs.tableview.$forceUpdate();
      const path = 'custom-tree/view/' + item.tree_id + '/revision/' + item.id + '/activate';
      const self = this;
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, {}, function (response) {
        if (response.data) {
          self.revisions = response.data;
          self.loadData();
        } else if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
          item.loading = false;
        }
        self.$refs.tableview.$forceUpdate();
      });
    }
  },
  watch: {
    treeId: {
      immediate: true,
      handler (val) {
        this.loadData();
      }
    }
  },
  mounted () {
    if (this.$route.params.treeid) {
      this.editable = true;
    }
    if (this.data && this.data.active_revision) {
      this.tree = this.data;
      this.tree.revision = this.tree.active_revision;
      this.loading = false;
    } else {
      this.loadData();
    }
  }
}
