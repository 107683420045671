import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import UserPicker from 'ebg-vue-common/src/components/user/picker/UserPicker.vue'
import { mapState } from 'vuex';

const defaultEl = {
  id: null,
  name: {},
  code: '',
  head_user: null,
  head_user_id: null,
  company_id: null,
  department_id: null
};

export default {
  name: 'DivisionEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false
    }
  },
  components: {
    UserPicker
  },
  validations: {
    editEl: {
      name: { required },
      code: { required },
      company_id: { required },
      department_id: { required }
    }
  },
  computed: {
    ...mapState(['config']),
    getTitle () {
      if (this.editEl.id) {
        return this.$t('menu.companies.division.edit');
      }
      return this.$t('menu.companies.division.new');
    },
    canEdit () {
      return (!this.loading && this.ApiRequest.userHasPermission('edit-company-settings'));
    },
    companyOptions () {
      return this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0'), 'text');
    },
    companyShortcode () {
      if (this.editEl.company_id) {
        return this.$store.getters.getParamFromConfig('user_companies', this.editEl.company_id, 'shortcode') + '_'
      }
      return '';
    },
    departmentOptions () {
      const options = [];
      if (this.config && this.config.company_departments) {
        const self = this;
        Object.values(this.config.company_departments).forEach(el => {
          if (parseInt(el.company_id) === parseInt(self.editEl.company_id)) {
            options.push({ value: el.id, text: el.name[self.$i18n.locale] })
          }
        });
      }
      return options;
    }
  },
  methods: {
    show (elData) {
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
        this.editEl.company_id = this.editEl.department.company_id;
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      this.v$.$reset();
      this.$refs['modal-window'].show();
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        reqData.head_user_id = (reqData.head_user ? reqData.head_user.id : null);
        reqData.head_user = null;
        this.ApiRequest.request('config/company/divisions/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this, { 'item.code': 'general.item.code' });
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    },
    nameChanged (value) {
      if (this.editEl.id === null && value[this.$i18n.locale]) {
        this.editEl.code = this.BasicHelper.generateCodeByName(value[this.$i18n.locale], 'DIV_' + this.companyShortcode, 30);
      }
    },
    onCompanyChange () {
      this.editEl.department_id = (this.departmentOptions[0] ? this.departmentOptions[0].value : null);
      this.nameChanged(this.editEl.name);
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
