import UserContactPersonEdit from '@/components/user/tab/contactPersons/edit/UserContactPersonEdit.vue';
import moment from 'moment';

export default {
  name: 'UserContactPersons',

  props: {
    userApiId: {
      type: String,
      default: null
    }
  },

  components: {
    UserContactPersonEdit
  },

  data () {
    return {
      contactPersonList: [],
      currentReq: null,
      isLoading: false,
      paging: { limit: 10, page: 1, total: 0, pages: 0 }
    }
  },

  computed: {
    tableHeaders () {
      const headers = [
        { key: 'contactPersonType_id', label: this.$t('user.table.contact-type'), format: 'formatFunc', funcs: [this.getTypeName] },
        { key: 'personName', label: this.$t('user.table.contact-person-name') },
        { key: 'dateBirth', label: this.$t('user.table.birth-day'), format: 'formatFunc', funcs: [this.getBirthday], classFuncs: [null] },
        { key: 'fullYears', label: this.$t('user.table.full-years'), format: 'formatFunc', funcs: [this.getFullYear], classFuncs: [null] },
        { key: 'phone', label: this.$t('user.table.phone') },
        { key: 'isDocumentPresented', label: this.$t('user.table.document-presented'), format: 'formatYesNo' }
      ];

      let actions = [];
      if (this.canEdit) {
        actions = [
          { label: this.$t('user.contactPerson.btn.title-edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-warning btn-sm' },
          { label: this.$t('user.contactPerson.btn.title-delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-danger btn-sm' }
        ];
      }

      headers.push({ key: 'actions', label: '', actions: actions, headerClass: 'col-1' });
      return headers;
    },

    canEdit () {
      return this.ApiRequest.userHasPermission('edit-all-users');
    }
  },

  methods: {
    populateContactPersons () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }

      this.isLoading = true;
      const path = `user/contactPerson/list?userApi_id=${this.userApiId}&perPage=${this.paging.limit}&page=${this.paging.page}`;
      this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else if (response.data) {
          this.contactPersonList = response.data;
          this.paging = response.paging;
        }
        this.isLoading = false;
      });
    },

    getTypeName (id) {
      return id ? this.$store.getters.getParamFromConfig('contact_person_types', id, 'name', this.$i18n.locale) : null;
    },

    getBirthday (val, data) {
      if (
        val === null ||
        (
          data.item.fullYears > 18 &&
          data.item.contactPersonType_id === this.$store.getters.getParamFromConfigByFilter('contact_person_types', 'code', 'CHILD', 'id')
        ) ||
        data.item.contactPersonType_id !== this.$store.getters.getParamFromConfigByFilter('contact_person_types', 'code', 'CHILD', 'id')
      ) {
        return;
      }

      const date = moment(val);
      return date.format('DD.MM.YYYY');
    },

    getFullYear (val, data) {
      if (
        val === null ||
        (
          data.item.fullYears > 18 &&
          data.item.contactPersonType_id === this.$store.getters.getParamFromConfigByFilter('contact_person_types', 'code', 'CHILD', 'id')
        ) ||
        data.item.contactPersonType_id !== this.$store.getters.getParamFromConfigByFilter('contact_person_types', 'code', 'CHILD', 'id')
      ) {
        return;
      }

      return val;
    },

    editItem (el) {
      this.$refs.editUserContactPerson.show(el ? el.id : 'NEW', this.userApiId);
    },

    changePage (pageNr) {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }

      this.paging.page = pageNr;
      this.populateContactPersons();
    },

    async deleteItem (el, approved) {
      if (approved) {
        const { data } = await this.destroy(`user/contactPerson/destroy/${el.id}`);
        if (data) {
          this.$store.commit(
            'toastAdd',
            {
              context: 'success',
              message: this.$t('user.contactPerson.delete_successfully', { type: this.getTypeName(el.contactPersonType_id), name: el.personName })
            }
          );
        }
        this.populateContactPersons();
      } else {
        this.$refs.itemDeleteMsgBox.show(
          el,
          this.$t('user.contactPerson.title-delete'),
          this.$t('user.contactPerson.text-delete', { type: this.getTypeName(el.contactPersonType_id), name: el.personName })
        );
      }
    },

    async destroy (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, this.form, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  },

  mounted () {
    this.populateContactPersons();
  }
}
