class HrHelper {
  setParams (component) {
    this.component = component
  }

  getJobPositionOptions (jobPositions, companyId = null) {
    const options = [];
    jobPositions.forEach(el => {
      if (el.job_position_id) {
        const job = this.component.$store.getters.getDataFromConfigWithFilter('company_jobs', 'id', el.job_id);
        if (job && job.job_positions[el.job_position_id] && job.job_positions[el.job_position_id].company_id &&
          (companyId == null || companyId == job.job_positions[el.job_position_id].company_id)
        ) {
          const option = {
            value: el.job_position_id,
            text:
              this.component.BasicHelper.getConfigValue(
                'user_companies',
                'id',
                job.job_positions[el.job_position_id].company_id,
                'name',
                true
              ) +
              ': ' +
              this.component.BasicHelper.getConfigValue('company_jobs', 'id', el.job_id, 'name')
          };
          if (job.isPrimary) {
            options.unshift(option)
          } else {
            options.push(option);
          }
        }
      }
    });
    return options;
  }
}

export default new HrHelper()
