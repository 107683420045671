import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import UserPicker from 'ebg-vue-common/src/components/user/picker/UserPicker.vue'

const defaultEl = {
  id: null,
  name: {},
  code: '',
  shortcode: '',
  disabled: false,
  head_user: null,
  head_user_id: null,
  mail_prefix: ''
};

export default {
  name: 'CompanyEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false
    }
  },
  components: {
    UserPicker
  },
  validations: {
    editEl: {
      name: { required },
      code: { required }
    }
  },
  computed: {
    getTitle () {
      if (this.editEl.id) {
        return this.$t('menu.companies.company.edit');
      }
      return this.$t('menu.companies.company.new');
    },
    canEdit () {
      return (!this.loading && this.ApiRequest.userHasPermission('edit-company-settings'));
    }
  },
  methods: {
    show (elData) {
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      this.editEl.disabled = (parseInt(this.editEl.disabled) === 1);
      this.v$.$reset();
      this.$refs['modal-window'].show();
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        reqData.head_user_id = (reqData.head_user ? reqData.head_user.id : null);
        reqData.head_user = null;
        this.ApiRequest.request('config/company/companies/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this, { 'item.code': 'general.item.code' });
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
