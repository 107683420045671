import UserPicker from 'ebg-vue-common/src/components/user/picker/UserPicker.vue';
import FolderTree from '@/components/folder/tree/FolderTree.vue';
import EbgSystemUserGroups from '@/components/ebgsystems/usergroups/EbgSystemUserGroups.vue'
import UserRightsInfo from '@/components/user/rights/info/UserRightsInfo.vue'
import UserRightsAttach from '@/components/user/rights/attach/UserRightsAttach.vue'

export default {
  name: 'UserRights',
  data () {
    return {
      userPickerUser: null,
      user: null,
      loadingTree: false
    };
  },
  components: {
    FolderTree,
    UserPicker,
    EbgSystemUserGroups,
    UserRightsInfo,
    UserRightsAttach
  },
  computed: {
    canPickUsers () {
      return (this.ApiRequest.userHasPermission('view-all-users') || this.ApiRequest.userHasPermission('view-company-users'));
    },
    currentLdapUser () {
      if (this.user && parseInt(this.user.isLdapUser) === 1) {
        return true;
      }
      return false;
    },
    folderPermissions () {
      if (this.user && this.user.ad_group_permissions) {
        return this.user.ad_group_permissions.folders;
      }
      return null;
    },
    otherPermissions () {
      if (this.user && this.user.ad_group_permissions) {
        return this.user.ad_group_permissions.other;
      }
      return null;
    },
    systemPermissions () {
      if (this.user && this.user.ad_group_permissions) {
        return this.user.ad_group_permissions.systems;
      }
      return null;
    }
  },
  methods: {
    userFolderGroupsChanged (data) {
      this.user.ad_group_permissions = data;
    },
    getFullUserData (newUser) {
      if (newUser && newUser.api_id) {
        this.requestUserData(newUser.api_id);
      } else {
        this.user = null;
      }
    },
    requestUserData (userApiId) {
      const self = this;
      this.loadingTree = true;
      this.ApiRequest.request('user/get/' + userApiId, this.ApiRequest.REQUEST_GET, [], function (response) {
        if (response.data) {
          self.user = response.data
        }
        self.loadingTree = false;
      });
    },
    openUserRightsAttachFrm () {
      this.$refs.userRightsAttachFrm.show(this.user);
    },
    userRightsAttached (data) {
      if (this.user) {
        this.user.ad_group_permissions = data;
      }
    }
  },
  mounted () {
    if (!this.canPickUsers) {
      this.requestUserData(this.$session.get('uuid'));
    }
  }
}
