import { mapState } from 'vuex';

export default {
  name: 'AxaptaSalaryCard',
  props: {
    isModal: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      loading: false,
      cardId: null,
      card: null
    }
  },
  computed: {
    ...mapState(['config']),
    componentName () {
      if (this.isModal) {
        return 'b-modal';
      }
      return 'div';
    },
    cardGroups () {
      return this.BasicHelper.sortArrayByKeyValue(
        Object.values(this.$store.getters.getItemsFromConfigWithFilter('axapta_salary_card_item_groups', 'displayInCard', '1')),
        'order'
      );
    },
    requestPath () {
      return 'axapta/salary-card/card/' + this.cardId + '/view';
    },
    title () {
      return this.$t('axapta.salary-card.title');
    }
  },
  methods: {
    setData (cardId) {
      this.cardId = cardId;
      this.getTableData();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    },
    getTableData () {
      const self = this;
      self.loading = true;
      this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, [], function (response) {
        if (response.data) {
          self.card = response.data;
        }
        self.loading = false;
      })
    }
  }
}
