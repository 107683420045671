import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import FolderPicker from '@/components/folder/picker/FolderPicker.vue';
import { mapState } from 'vuex';

const defaultEl = {
  id: null,
  code: '',
  name: '',
  description: '',
  isDefault: '',
  folder_id: null,
  system_id: null
};

export default {
  name: 'AdGroupsEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false
    }
  },
  components: {
    FolderPicker
  },
  validations: {
    editEl: {
      code: { required }
    }
  },
  computed: {
    ...mapState(['config']),
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-ad-groups');
    },
    getTitle () {
      if (this.editEl.id) {
        return this.$t('menu.config.ad-groups.edit');
      }
      return this.$t('menu.config.ad-groups.new');
    },
    systemOptions () {
      const options = [];
      if (this.config.ebg_systems) {
        Object.values(this.config.ebg_systems).forEach(el => {
          options.push({ value: el.id, text: el.title })
        });
        options.unshift({ value: null, text: '' });
      }
      return options;
    }
  },
  methods: {
    show (elData) {
      let el = null
      if (elData) {
        el = this.BasicHelper.cloneObject(elData);
      } else {
        el = this.BasicHelper.cloneObject(defaultEl);
      }
      el.isDefault = (parseInt(el.isDefault) === 1);
      if (el.folder_id) {
        el.folder_id = parseInt(el.folder_id);
      }
      this.editEl = el;
      this.v$.$reset();
      this.$refs['modal-window'].show();
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        this.ApiRequest.request('ad-groups/groups/edit', this.ApiRequest.REQUEST_POST, { 'ad-group': reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
