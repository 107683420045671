import { mapState } from 'vuex';
import UserRightsInfo from '@/components/user/rights/info/UserRightsInfo.vue'

export default {
  name: 'FolderUsers',
  props: {
    folder: Object
  },
  data () {
    return {
      folderUsers: null,
      loading: false
    }
  },
  components: {
    UserRightsInfo
  },
  computed: {
    ...mapState(['config']),
    folderPath () {
      if (this.folder) {
        return this.EbgFolderHelper.getFolderPath(this.folder.id).path;
      }
      return '';
    },
    folderPermissionTypes () {
      return this.$store.getters.getItemsFromConfigWithFilter('ad_group_permission_types');
    },
    tableHeaders () {
      const headers = [
        { key: 'typeId', label: this.$t('ebgfolder.permissions'), format: 'formatFromConfig', configKey: 'ad_group_permission_types', configParam: 'name', classTd: 'header-col' },
        { key: 'INHERIT', label: this.$t('ebgfolder.permission-types.INHERIT'), format: 'roundedBlockList', blockParam: 'fullname', class: 'left-border', listClassFunc: this.getItemClass },
        { key: 'CURRENT', label: this.$t('ebgfolder.permission-types.CURRENT'), format: 'roundedBlockList', blockParam: 'fullname', class: 'left-border', listClassFunc: this.getItemClass }
      ];
      return headers;
    }
  },
  methods: {
    getFolderUsers () {
      if (this.folder) {
        this.loading = true;
        this.ApiRequest.request('ad-groups/ebg-folders/' + this.folder.id + '/users', this.ApiRequest.REQUEST_GET, [], (response) => {
          if (response.data) {
            this.folderUsers = response.data;
          }
          this.loading = false;
        });
      }
    },
    getItemClass (item, user) {
      if (parseInt(user.isDisabled) === 1) {
        return 'rounded-block-disabled';
      } else if (user.request) {
        return 'rounded-block-' + user.request;
      }
      return '';
    }
  },
  mounted () {
    this.getFolderUsers();
  },
  watch: {
    folder: {
      immediate: true,
      handler (val) {
        this.getFolderUsers();
      }
    }
  }
}
