import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core';

const defaultEl = {
  id: null,
  userApi_id: null,
  contactPersonType_id: null,
  personName: null,
  dateBirth: null,
  phone: null,
  isDocumentPresented: false
}

export default {
  name: 'UserContactPersonEdit',

  data () {
    return {
      form: [],
      header_title: '',
      isLoading: false
    }
  },

  validations: {
    form: {
      contactPersonType_id: { required },
      personName: {
        validatePersonName: function (value, vm) {
          if (this.isRequiredPersonNameAndPhone && (value === null || value === '')) {
            return false;
          }
          return true;
        }
      },
      dateBirth: {
        validateDateBirth: function (value, vm) {
          const type = vm.contactPersonType_id ? this.$store.getters.getParamFromConfig('contact_person_types', vm.contactPersonType_id, 'code', this.$i18n.locale) : null;
          if (vm.isDocumentPresented && type === 'CHILD' && value === null) {
            return false;
          }
          return true;
        }
      },
      phone: {
        validatePhone: function (value, vm) {
          if (
            (this.isRequiredPersonNameAndPhone && (value === null || value === '' || value.length < 8)) ||
            (value !== undefined && value !== null && value !== '' && value.length < 8)
          ) {
            return false;
          }
          return true;
        }
      }
    }
  },

  computed: {
    isRequiredDateBirth () {
      const type = this.form.contactPersonType_id ? this.$store.getters.getParamFromConfig('contact_person_types', this.form.contactPersonType_id, 'code', this.$i18n.locale) : null;
      if (this.form.isDocumentPresented && type === 'CHILD') {
        return true;
      }
      return false;
    },

    isRequiredPersonNameAndPhone () {
      const personType = this.contactPersonTypeOptions.find(el => el.value === this.form.contactPersonType_id);
      return (personType !== undefined && personType.code === 'CONTACT_PERSON');
    },

    contactPersonTypeOptions () {
      return this.$store.getters.getConfigOptions('contact_person_types', this.$i18n.locale);
    }
  },

  methods: {
    async show (id, userApiId) {
      this.v$.$reset();
      this.form = this.BasicHelper.cloneObject(defaultEl);
      if (id !== 'NEW') {
        const { data } = await this.loadData(`user/contactPerson/show/${id}`);
        this.BasicHelper.syncObjectValuesByKeys(this.form, data);
      }
      this.form.id = id;
      this.form.userApi_id = userApiId;
      this.header_title = id === 'NEW' ? this.$t('user.contactPerson.title-add') : this.$t('user.contactPerson.title-update');
      this.$refs['modal-window'].show()
    },

    async submitForm () {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      this.isLoading = true;
      const { data } = await this.save(`user/contactPerson/save/${this.form.id}`);
      let contactPerson = '';
      if (data) {
        contactPerson = this.$store.getters.getParamFromConfig('contact_person_types', data.contactPersonType_id, 'name', this.$i18n.locale);
        let msg = ''
        if (this.form.id === 'NEW') {
          msg = this.$t('user.contactPerson.msg-add-successfully', { type: contactPerson, name: data.personName });
        } else {
          msg = this.$t('user.contactPerson.msg-update-successfully', { type: contactPerson, name: data.personName });
        }

        this.$store.commit('toastAdd', { context: 'success', message: msg });
      }
      this.isLoading = false;
      this.$emit('changeDate');
      this.$refs['modal-window'].hide();
    },

    async save (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, this.form, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    },

    async loadData (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, this.form, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  },

  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'UserContactPersonEdit' }) }
  }
}
