import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const defaultEl = {
  id: null,
  title: {},
  months_min: '',
  months_max: '',
  description: {},
  details: {},
  company_id: null
};

export default {
  name: 'EditBenefit',
  data () {
    return {
      editEl: defaultEl,
      loading: false,
      templateOptions: []
    }
  },
  components: {},
  validations: {
    editEl: {
      title: { required },
      months_min: { required },
      months_max: { },
      company_id: { }
    }
  },
  computed: {
    getTitle () {
      if (this.editEl.id) {
        return this.$t('benefits.config.modal-title-edit');
      }
      return this.$t('benefits.config.modal-title-add');
    },
    companyOptions () {
      const options = this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0'));
      options.unshift({ value: null, text: '' });
      return options;
    },
    canEdit () {
      return (!this.loading && this.ApiRequest.userHasPermission('edit-company-settings'));
    }
  },
  methods: {
    show (elData) {
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      this.v$.$reset();
      this.$refs['modal-window'].show();
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = this.ApiRequest.createFormData(this.editEl, Object.keys(defaultEl), 'benefit', this);
        this.loading = false;
        this.ApiRequest.request('config/benefits/edit', this.ApiRequest.REQUEST_POST, reqData, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this, { 'item.code': 'general.item.code' });
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
