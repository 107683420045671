var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-list"},[_c('Tableview',{ref:"tableview",attrs:{"tableClass":"table-striped table-sm table-hover small","fields":_vm.tableHeaders,"loading":_vm.isLoading,"items":_vm.tableItems,"filterValues":_vm.filterValues,"displayColFiltersAlways":true,"filters":_vm.tableFilterConfig,"expandableConfig":_vm.tableExpandConfig,"displayFilterActions":true,"filterActions":_vm.tableActions,"smallScreenCards":true,"filterActionColClass":"col-md-12","filterActionAlign":"text-end"},on:{"saved":_vm.updateFilters,"deleteItem":_vm.deleteItem,"enableDisableUser":_vm.enableDisableUser,"editItem":_vm.editItem,"addItem":function($event){return _vm.editItem(null)},"clearFilters":_vm.clearFilters},scopedSlots:_vm._u([{key:"jobPositions",fn:function(ref){
var data = ref.data;
return _vm._l((data.item.jobPositions),function(row,index){return _c('span',{key:index,attrs:{"index":index}},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"title":row.isPrimary ? _vm.$t('user.jobPositions.isPrimary') : _vm.$t('user.jobPositions.isSecondary')}},[_vm._v(" "+_vm._s(_vm.labelJob(row.job_id))+" "),(index !== data.item.jobPositions.length - 1)?[_c('br')]:_vm._e()],2)])})}},{key:"departments",fn:function(ref){
var data = ref.data;
return _vm._l((data.item.jobPositions),function(row,index){return _c('span',{key:index,attrs:{"index":index}},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"title":row.isPrimary ? _vm.$t('user.jobPositions.isPrimary') : _vm.$t('user.jobPositions.isSecondary')}},[_vm._v(" "+_vm._s(_vm.labelDepartment(row.department_id))+" "),(index !== data.item.jobPositions.length - 1)?[_c('br')]:_vm._e()],2)])})}},{key:"company",fn:function(ref){
var data = ref.data;
return _vm._l((data.item.jobPositions),function(row,index){return _c('span',{key:index,attrs:{"index":index}},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"title":row.isPrimary ? _vm.$t('user.jobPositions.isPrimary') : _vm.$t('user.jobPositions.isSecondary')}},[_vm._v(" "+_vm._s(_vm.labelCompany(row.company_id))+" "),(index !== data.item.jobPositions.length - 1)?[_c('br')]:_vm._e()],2)])})}},{key:"expandrow",fn:function(ref){
var data = ref.data;
return [_c('UserListExpand',{attrs:{"data":data}})]}},{key:"directManager",fn:function(ref){
var data = ref.data;
return _vm._l((data.item.jobPositions),function(row,index){return _c('span',{key:index,attrs:{"index":index}},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"title":row.isPrimary ? _vm.$t('user.jobPositions.isPrimary') : _vm.$t('user.jobPositions.isSecondary')}},[_vm._v(" "+_vm._s(row.directManager)+" "),(index !== data.item.jobPositions.length - 1)?[_c('br')]:_vm._e()],2)])})}},{key:"phone",fn:function(ref){
var data = ref.data;
return [(
        _vm.canViewFull &&
        data.item.ldap_telephone_number !== '' &&
        data.item.ldap_telephone_number !== null &&
        data.item.ldap_home_phone !== '' &&
        data.item.ldap_home_phone !== null
      )?[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"title":_vm.$t('user.jobPositions.work')}},[_vm._v(_vm._s(_vm.BasicHelper.formatPhone(data.item.ldap_telephone_number)))]),_vm._v(" / "),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"title":_vm.$t('user.jobPositions.private')}},[_vm._v(_vm._s(_vm.BasicHelper.formatPhone(data.item.ldap_home_phone)))])]:(_vm.canViewFull && data.item.ldap_telephone_number !== '' && data.item.ldap_telephone_number !== null)?[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"title":_vm.$t('user.jobPositions.work')}},[_vm._v(_vm._s(_vm.BasicHelper.formatPhone(data.item.ldap_telephone_number)))]),_vm._v(" / - ")]:(_vm.canViewFull && data.item.ldap_home_phone !== '' && data.item.ldap_home_phone !== null)?[_vm._v(" - / "),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"title":_vm.$t('user.jobPositions.private')}},[_vm._v(_vm._s(_vm.BasicHelper.formatPhone(data.item.ldap_home_phone)))])]:[_vm._v(" - / - ")]]}},{key:"email",fn:function(ref){
      var data = ref.data;
return [(
        _vm.canViewFull &&
        data.item.email !== '' &&
        data.item.email !== null &&
        data.item.emailHome !== '' &&
        data.item.emailHome !== null
      )?[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"href":("mailto:" + (data.item.email)),"title":_vm.$t('user.jobPositions.work')}},[_vm._v(_vm._s(data.item.email))]),_vm._v(" / "),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"href":("mailto:" + (data.item.emailHome)),"title":_vm.$t('user.jobPositions.private')}},[_vm._v(_vm._s(data.item.emailHome))])]:(_vm.canViewFull && data.item.v !== '' && data.item.email !== null)?[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"href":("mailto:" + (data.item.email)),"title":_vm.$t('user.jobPositions.work')}},[_vm._v(_vm._s(data.item.email))]),_vm._v(" / - ")]:(_vm.canViewFull && data.item.emailHome !== '' && data.item.emailHome !== null)?[_vm._v(" - / "),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"href":("mailto:" + (data.item.emailHome)),"title":_vm.$t('user.jobPositions.private')}},[_vm._v(_vm._s(data.item.emailHome))])]:[_vm._v(" - / - ")]]}}])}),(_vm.paging.pages > 1  && !_vm.isLoading)?_c('b-pagination',{attrs:{"total-rows":_vm.paging.total,"per-page":_vm.paging.limit},on:{"change":_vm.changePage},model:{value:(_vm.paging.page),callback:function ($$v) {_vm.$set(_vm.paging, "page", $$v)},expression:"paging.page"}}):_vm._e(),_c('UserEdit',{ref:"editElFrm",attrs:{"isModal":true},on:{"saved":_vm.getTableData}}),_c('MessageBox',{ref:"itemDeleteMsgBox",on:{"confirm":function($event){return _vm.deleteItem($event, 1)}}}),_c('MessageBox',{ref:"itemEnableDisableMsgBox",on:{"confirm":function($event){return _vm.enableDisableUser($event, 1)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }