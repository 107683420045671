class EbgFolderHelper {
  setParams (component) {
    this.component = component
  }

  getBasePath () {
    return '\\\\ebg-filserver\\data$\\';
  }

  getTree () {
    return this.component.$store.getters.getItemsFromConfigWithFilter('ebg_folder_tree');
  }

  getTreeWithPaths () {
    return this.getTreeWithPathsRecursive(this.getTree(), '');
  }

  getTreeWithPathsRecursive (tree, path) {
    const self = this;
    if (tree) {
      Object.keys(tree).forEach(function (key) {
        tree[key].path = (path ? path + '\\' + tree[key].name : tree[key].name).toUpperCase()
        if (tree[key].children) {
          tree[key].children = self.getTreeWithPathsRecursive(tree[key].children, tree[key].name);
        }
      });
    }
    return tree;
  }

  getFolderPath (folderId) {
    const tree = this.getTree();
    let res = { path: '', isMatch: false };
    res = this.searchFolderPathRecursive(tree, folderId, res);
    if (res.isMatch) {
      res.path = this.getBasePath() + res.path;
    }
    return res;
  }

  searchFolderPathRecursive (tree, folderId, res) {
    if (!tree) {
      return res;
    }
    if (tree[folderId]) {
      res.isMatch = true;
      res.path = tree[folderId].name;
      return res;
    }
    for (const el of Object.values(tree)) {
      if (el.children) {
        res = this.searchFolderPathRecursive(el.children, folderId, res);
        if (res.isMatch) {
          res.path = el.name + '\\' + res.path;
          break;
        }
      }
    }
    return res;
  }

  getFolderOptions () {
    return this.getFolderOptionsRecursive(this.getTree(), this.getBasePath(), []);
  }

  getFolderOptionsRecursive (tree, path, options) {
    if (!tree) {
      return options;
    }
    for (const el of Object.values(tree)) {
      options.push({
        id: el.id,
        name: el.name,
        groupbase: el.groupbase,
        path: path + '\\' + el.name
      })
      if (el.children) {
        options = this.getFolderOptionsRecursive(el.children, path + '\\' + el.name, options);
      }
    }
    return options;
  }
}

export default new EbgFolderHelper()
