import CustomFormRecordView from '@/components/common/customform/record/view/CustomFormRecordView.vue'
import CustomFormRecordRecipients from '@/components/common/customform/record/recipients/CustomFormRecordRecipients.vue'

export default {
  name: 'CustomFormRecordAction',
  data () {
    return {
      updatingStatus: false,
      loading: true,
      form: null,
      record: null,
      message: null,
      success: null
    };
  },
  components: {
    CustomFormRecordView,
    CustomFormRecordRecipients
  },
  computed: {
    canEdit () {
      return false;
    }
  },
  methods: {
    loadRecord () {
      const path = 'custom-form/action/view/' + this.$route.params.token;
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.error) {
          if (response.messageCodes[0] === 'completed') {
            this.message = this.$t('custom-form.action.completed-message');
          } else if (response.messageCodes[0] === 'not-found') {
            this.message = this.$t('custom-form.action.not-found');
          }
        }
        if (response.data) {
          this.record = response.data.record;
          this.form = response.data.form;
          this.mergeFields();
        }
        this.loading = false;
      });
    },
    mergeFields () {
      if (this.form.type && this.form.type.default_fields) {
        this.form.allFields = this.form.type.default_fields.concat(this.form.fields);
        return;
      }
      this.form.allFields = this.form.fields;
    },
    getRecordStatus () {
      if (this.record.status_id) {
        const recordStatus = this.$store.getters.getDataFromConfigWithFilter('custom_form_record_statuses', 'id', this.record.status_id);
        return recordStatus.code;
      }
      return null;
    },
    updateStatus (status) {
      const path = 'custom-form/action/complete/' + this.$route.params.token;
      this.updatingStatus = true;
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, { approve: status }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else {
          this.record = null;
          this.form = null;
          if (status) {
            this.success = this.$t('custom-form.action.accepted-message');
          } else {
            this.success = this.$t('custom-form.action.rejected-message');
          }
        }
        this.updatingStatus = false;
      });
    }
  },
  mounted () {
    this.loadRecord();
  }
}
