import AdGroupsEdit from '@/components/adgroup/edit/AdGroupEdit.vue'
import { mapState } from 'vuex'

const defaultFilterValues = {
  code: '',
  name: '',
  isDefault: [],
  isFolder: [],
  system: null
};

export default {
  name: 'AdGroupsList',
  components: {
    AdGroupsEdit
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'code', direction: 'asc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  computed: {
    ...mapState(['config']),
    tableHeaders () {
      const headers = [
        { key: 'name', label: this.$t('ad-group.name'), sortable: true, filterKey: 'name' },
        { key: 'code', label: this.$t('ad-group.code'), sortable: true, filterKey: 'code' },
        { key: 'isDefault', label: this.$t('ad-group.isDefault'), filterKey: 'isDefault', format: 'formatYesNo' },
        { key: 'system_id', label: this.$t('ad-group.system'), format: 'formatFromConfig', configKey: 'ebg_systems', configParam: 'title', filterKey: 'system' },
        { key: 'folder_id', label: this.$t('ad-group.folder'), format: 'formatFunc', funcs: [this.getFolderPath], classFuncs: [null] }
      ];
      if (this.canEdit) {
        const actions = [];
        if (this.canEdit) {
          actions.push({ label: this.$t('menu.config.ad-groups.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-warning' });
        }
        headers.push({ key: 'actions', label: '', actions: actions });
      }
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = { };
      filters.code = { type: 'text', label: '', colOnlyFilter: true };
      filters.name = { type: 'text', label: '', colOnlyFilter: true };
      filters.isDefault = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };
      filters.system = { type: 'select', label: '', options: this.systemOptions, colOnlyFilter: true }
      return filters;
    },
    canEnableDisable () {
      return this.ApiRequest.userHasPermission('edit-ad-groups');
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-ad-groups');
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath('ad-groups/groups/list?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      return path;
    },
    systemOptions () {
      const options = [];
      if (this.config.ebg_systems) {
        Object.values(this.config.ebg_systems).forEach(el => {
          options.push({ code: el.id + '', text: el.title })
        });
        options.unshift({ code: null, text: '' });
      }
      return options;
    },
    tableActions () {
      const actions = { download: false };
      if (this.canEdit) {
        // actions.add = { title: this.$t('menu.config.ad-groups.new') };
      }
      return actions;
    }
  },
  methods: {
    getFolderPath (folderId, data) {
      if (folderId) {
        return this.EbgFolderHelper.getFolderPath(folderId).path;
      }
      return '';
    },
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
      this.loading = true;
      const self = this;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, function (response) {
        if (response.data) {
          self.tableItems = response.data;
          self.paging = response.paging;
          self.loading = false;
        }
      });
    },
    itemUpdated () {
      this.$emit('saved');
      this.getTableData();
    },
    editItem (el) {
      this.$refs.editElFrm.show(el);
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
    this.isMounted = true;
  }
}
