import UserQualificationEdit from '@/components/user/tab/qualifications/edit/UserQualificationEdit.vue';

export default {
  name: 'UserQualifications',

  props: {
    userApiId: {
      type: String,
      default: null
    }
  },

  components: {
    UserQualificationEdit
  },

  data () {
    return {
      qualificationList: [],
      currentReq: null,
      isLoading: false,
      paging: { limit: 10, page: 1, total: 0, pages: 0 }
    }
  },

  computed: {
    tableHeaders () {
      const headers = [
        { key: 'qualification_id', label: this.$t('user.table.qualification'), format: 'formatFunc', funcs: [this.getQualificationName] },
        { key: 'documentNo', label: this.$t('user.table.document-no') },
        { key: 'validFrom', label: this.$t('user.table.valid-from'), type: 'date' },
        { key: 'validTo', label: this.$t('user.table.valid-to'), type: 'date' },
        { key: 'category', label: this.$t('user.table.category'), type: 'slot', slotcode: 'category', headerClass: 'col-2' },
        { key: 'restrictions', label: this.$t('user.table.restrictions') },
        { key: 'issue', label: this.$t('user.table.issue') },
        { key: 'isDocumentPresented', label: this.$t('user.table.document-presented'), format: 'formatYesNo' }
      ];

      let actions = [];
      if (this.canEdit) {
        actions = [
          { label: this.$t('user.qualification.btn.title-edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-warning btn-sm' },
          { label: this.$t('user.qualification.btn.title-delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-danger btn-sm' }
        ];
      }

      headers.push({ key: 'actions', label: '', actions: actions, headerClass: 'col' });
      return headers;
    },

    canEdit () {
      return this.ApiRequest.userHasPermission('edit-all-users');
    }
  },

  methods: {
    populateQualification () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }

      this.isLoading = true;
      const path = `user/qualification/list?userApi_id=${this.userApiId}&perPage=${this.paging.limit}&page=${this.paging.page}`;
      this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else if (response.data) {
          this.qualificationList = response.data;
          this.paging = response.paging;
        }
        this.isLoading = false;
      });
    },

    getQualificationName (id) {
      return id ? this.$store.getters.getParamFromConfig('qualifications', id, 'name', this.$i18n.locale) : null;
    },

    getCategoryName (id) {
      return id ? this.$store.getters.getParamFromConfig('qualification_categories', id, 'name', this.$i18n.locale) : null;
    },

    editItem (el) {
      this.$refs.editUserQualification.show(el ? el.id : 'NEW', this.userApiId);
    },

    async deleteItem (el, approved) {
      if (approved) {
        const { data } = await this.destroy(`user/qualification/destroy/${el.id}`);
        if (data) {
          this.$store.commit('toastAdd', { context: 'success', message: this.$t('user.qualification.delete_successfully', { qualification: el.qualification }) });
        }
        this.populateQualification();
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t('user.qualification.title-delete'), this.$t('user.qualification.text-delete', { qualification: el.qualification }))
      }
    },

    changePage (pageNr) {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }

      this.paging.page = pageNr;
      this.populateQualification();
    },

    async destroy (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, this.form, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  },

  mounted () {
    this.populateQualification();
  }
}
