import UserWorkSecurityEdit from '@/components/user/tab/workSecurity/edit/UserWorkSecurityEdit.vue';
import moment from 'moment';

export default {
  name: 'UserWorkSecurity',

  props: {
    userApiId: {
      type: String,
      default: ''
    }
  },

  components: {
    UserWorkSecurityEdit
  },

  data () {
    return {
      workSecurityList: [],
      currentReq: null,
      isLoading: false,
      paging: { limit: 10, page: 1, total: 0, pages: 0 }
    }
  },

  computed: {
    tableHeaders () {
      const headers = [];
      if (!this.userApiId) {
        headers.push({ key: 'fullname', label: this.$t('user.table.name') });
        headers.push({ key: 'company_id', label: this.$t('user.table.company'), format: 'formatFunc', funcs: [this.getCompanyName] });
      }
      headers.push({ key: 'security_type_id', label: this.$t('user.table.type-dd'), format: 'formatFunc', funcs: [this.getSecurityTypeName] });
      headers.push({ key: 'validTo', label: this.$t('user.table.valid-to'), format: 'formatFunc', funcs: [this.getValidTo], classFuncs: [this.isValidToRedClass] });

      let actions = [];
      if (this.canEdit) {
        actions = [
          { label: this.$t('user.workSecurity.btn.title-edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-warning btn-sm' },
          { label: this.$t('user.workSecurity.btn.title-delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-danger btn-sm' }
        ];
      }

      headers.push({ key: 'actions', label: '', actions: actions, headerClass: 'col-1' });
      return headers;
    },

    canEdit () {
      return this.ApiRequest.userHasPermission('edit-all-users');
    }
  },

  methods: {
    populateWorkSecurity () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }

      this.isLoading = true;
      const path = `user/work-security/list?userApi_id=${this.userApiId}&perPage=${this.paging.limit}&page=${this.paging.page}`;
      this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else if (response.data) {
          this.workSecurityList = response.data;
          this.paging = response.paging;
        }
        this.isLoading = false;
      });
    },

    rowClass: function (item) {
      let clas = ''
      if (item.validToRed === 'rowRed') {
        clas += 'table-danger';
      }
      return clas;
    },

    getSecurityTypeName (id) {
      return id ? this.$store.getters.getParamFromConfig('job_security_types', id, 'name', this.$i18n.locale) : null;
    },

    getSecurityCategoryName (id) {
      return id ? this.$store.getters.getParamFromConfig('security_category_types', id, 'name', this.$i18n.locale) : null;
    },

    getCompanyName (id) {
      return id ? this.$store.getters.getParamFromConfig('user_companies', id, 'name', this.$i18n.locale) : null;
    },

    getValidTo (val) {
      if (val === null) {
        return;
      }
      const date = moment(val);
      return date.format('DD.MM.YYYY');
    },

    isValidToRedClass (val, data) {
      if (data.item.validToRed === 'colRed') {
        return 'text-danger fw-bold';
      }
      return '';
    },

    editItem (el) {
      this.$refs.editUserWorkSecurity.show(el ? el.id : 'NEW', this.userApiId);
    },

    changePage (pageNr) {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }

      this.paging.page = pageNr;
      this.populateWorkSecurity();
    },

    async deleteItem (el, approved) {
      if (approved) {
        const { data } = await this.destroy(`user/work-security/destroy/${el.id}`);
        if (data) {
          this.$store.commit(
            'toastAdd',
            { context: 'success', message: this.$t('user.workSecurity.delete_successfully', { name: this.getSecurityTypeName(el.security_type_id) }) }
          );
        }
        this.populateWorkSecurity();
      } else {
        this.$refs.itemDeleteMsgBox.show(
          el, this.$t('user.workSecurity.title-delete'),
          this.$t('user.workSecurity.text-delete', { name: this.getSecurityTypeName(el.security_type_id) })
        );
      }
    },

    async destroy (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, this.form, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  },

  mounted () {
    this.populateWorkSecurity();
  }
}
