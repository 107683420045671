import i18n from '@/i18n';

export default {
  state: {
  },
  getters: {
    getDirectManager: (state, getters) => (data, userId, divisionId) => {
      let directManager = null;
      if (getters.getParamFromConfig('company_departments', data.department_id, 'head_user_id', i18n.locale) !== userId) {
        directManager = getters.getParamFromConfig('company_departments', data.department_id, 'head_user_fullname', i18n.locale);
      }

      if (
        divisionId != null &&
        getters.getParamFromConfig('company_divisions', divisionId, 'head_user_id', true) !== null &&
        getters.getParamFromConfig('company_divisions', divisionId, 'head_user_id', true) !== userId
      ) {
        directManager = getters.getParamFromConfig('company_divisions', divisionId, 'head_user_fullname', i18n.locale);
      }

      return directManager;
    }
  },
  mutations: {
    STATE_CHANGES (state, newValues) {
      Object.keys(newValues).forEach(el => {
        state[el] = newValues[el];
      });
    },
    CONFIG_CHANGED (state) {
      // console.log(this.currentshift);
      // this.currentshift = Object.values(state.config.shifts)[0];
      // console.log('changed');
    },
    SESSION_CLEAR (state) {
      const keys = [];
      keys.forEach(key => {
        localStorage.setItem(key, null);
        state[key] = null;
      });
    }
  }
}
