import LocalePicker from 'ebg-vue-common/src/components/common/localepicker/LocalePicker.vue'
import CustomFormFields from '@/components/common/customform/form/fields/CustomFormFields.vue'
import NotificationSortList from '@/components/common/customform/form/notifications/sortlist/SortList.vue';
import TextEditor from 'ebg-vue-common/src/components/common/texteditor/TextEditor.vue'

const defaultForm = {
  id: null,
  name: {},
  type_id: null,
  company_id: null,
  experience_id: null,
  order: null,
  fields: [],
  templateFields: [],
  hasPdf: false,
  notification_order: {},
  pdf_template: {
    template_translations: {}
  },
  notifyReminderHours: '',
  notifyReminderRepeatHours: ''
};

export default {
  name: 'CustomFormView',
  props: {
    formId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      editable: false,
      loading: true,
      updating: false,
      currentReq: null,
      form: null,
      pdfLocale: this.$i18n.locale,
      simpleEditor: true,
      hasPdf: false,
      downloading: false,
      notifications: {}
    };
  },
  components: {
    LocalePicker,
    TextEditor,
    CustomFormFields,
    NotificationSortList
  },
  computed: {
    requestPath () {
      return 'custom-form/form';
    },
    canEdit () {
      return (!this.updating && (this.ApiRequest.userHasPermission('edit-custom-forms')));
    },
    formElId () {
      if (this.formId) {
        return this.formId
      }
      return this.$route.params.formid;
    },

    experienceOptions () {
      return this.$store.getters.getConfigOptions('experience', this.$i18n.locale);
    },

    companyOptions () {
      const options = this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0'));
      options.unshift({ value: null, text: '' })
      return options;
    },
    typeOptions () {
      return this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getConfigOptions('custom_form_types', this.$i18n.locale));
    },
    fieldTypeOptions () {
      return this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getConfigOptions('custom_form_field_types', this.$i18n.locale));
    },
    fieldTypesWithOptions () {
      const res = [];
      this.fieldTypeOptions.forEach(function (el) {
        if (el.code === 'CHECKBOX' || el.code === 'SELECT') {
          res.push(el.value);
        }
      });
      return res;
    },
    checkboxTypeOptionsId () {
      const el = this.fieldTypeOptions.find(el => el.code === 'CHECKBOX');
      if (el) {
        return parseInt(el.value);
      }
      return -1;
    },
    selectTypeOptionId () {
      const el = this.fieldTypeOptions.find(el => el.code === 'SELECT');
      if (el) {
        return parseInt(el.value);
      }
      return -1;
    },
    notificationOrder () {
      return (!this.form.notification_order || this.form.notification_order.length === 0) ? {} : this.form.notification_order
    },
    tabOptions () {
      const options = [
        { code: 'fields', text: this.$t('custom-form.form.fields') }
      ];
      if (Object.keys(this.notifications).length > 0) {
        options.push({ code: 'notifications', text: this.$t('custom-form.notifications.recipients-tab-title') })
      }
      options.push({ code: 'pdftemplate', text: this.$t('custom-form.form.pdfTemplate.tab') })
      return options;
    }
  },
  methods: {
    loadData () {
      if (this.formElId === 'NEW') {
        this.form = this.BasicHelper.cloneObject(defaultForm);
        this.form.type_id = this.typeOptions[0].value;
        this.loading = false;
      } else if (this.formElId) {
        this.loading = true;
        const self = this;
        const path = this.requestPath + '/view/' + this.formElId;
        this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, function (response) {
          if (response.data) {
            self.form = response.data;
            self.form.templateFields = [];
            self.notifications = {};
            self.addDefaultFields();
            if (self.form.pdf_template) {
              self.form.hasPdf = true;
            } else {
              self.form.hasPdf = false;
            }
          }
          self.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
    save () {
      this.updating = true;
      const reqData = this.BasicHelper.cloneObject(this.form);
      const self = this;
      this.ApiRequest.request(this.requestPath + '/edit', this.ApiRequest.REQUEST_POST, { form: reqData }, function (response) {
        if (response.data) {
          self.form = response.data;
          if (self.form.pdf_template) {
            self.form.hasPdf = true;
          } else {
            self.form.hasPdf = false;
          }
          self.addDefaultFields();
          if (self.formElId === 'NEW') {
            const routeData = self.$router.resolve({ name: 'menu.forms.form-edit', params: { formid: self.form.id } });
            self.$router.push(routeData.href);
          }
        } else if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
        }
        self.updating = false;
      });
    },
    toggleHasPdf (val) {
      if (val) {
        if (!this.form.pdf_template) {
          this.form.pdf_template = { template_translations: {} };
        }
        if (!this.form.pdf_template[this.pdfLocale]) {
          this.form.pdf_template.template_translations[this.pdfLocale] = { title: '', html: '' };
        }
        this.$forceUpdate();
      }
    },
    toggleLocaleChange (val) {
      if (val) {
        if (!this.form.pdf_template[val]) {
          this.form.pdf_template.template_translations[val] = { title: '', html: '' };
        }
        this.$forceUpdate();
      }
    },
    addDefaultFields () {
      const form = this.$store.getters.getDataFromConfigWithFilter('custom_form_types', 'id', this.form.type_id);
      const self = this;
      const fields = [];
      Object.values(form.defaultFields).forEach(function (field) {
        const newField = self.BasicHelper.cloneObject(field);
        newField.default = 1;
        newField.id = null;
        fields.push(newField);
      });
      this.form.templateFields = this.BasicHelper.sortArrayByKeyValue(fields, 'order');
      this.notifications = {};
      if (form && form.id) {
        this.loadFormTypeData(form.id);
      }
    },
    loadFormTypeData (formId) {
      this.loading = true;
      this.ApiRequest.request('custom-form/type/details/' + formId, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        }
        if (response.data && response.data.notifications) {
          this.notifications = response.data.notifications;
        }
        this.loading = false;
      });
    },
    previewPdf () {
      this.downloading = true;
      const reqData = {
        title: this.form.pdf_template.template_translations[this.pdfLocale].title,
        html: this.form.pdf_template.template_translations[this.pdfLocale].html,
        fields: this.form.fields
      }
      const self = this;
      this.ApiRequest.request('custom-form/form/pdf-preview', this.ApiRequest.REQUEST_DOWNLOAD, reqData, function (response) {
        if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
        }
        self.downloading = false;
      });
    },
    updateSorting (data) {
      this.form.notification_order = data;
    }
  },
  watch: {
    formid: {
      immediate: true,
      handler (val) {
        this.loadData();
      }
    }
  },
  mounted () {
    this.loadData();
  }
}
