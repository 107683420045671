import CustomFormTypeEdit from '@/components/common/customform/type/edit/CustomFormTypeEdit.vue'

const defaultFilterValues = {};

export default {
  name: 'CustomFormTypeList',
  components: {
    CustomFormTypeEdit
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'code', direction: 'asc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  computed: {
    tableHeaders () {
      const headers = [
        { key: 'name.' + this.$i18n.locale, label: this.$t('company-structure.company.name'), sortable: false, filterKey: 'name' },
        { key: 'code', label: this.$t('general.item.code'), sortable: true, filterKey: 'code' }
      ];
      if (this.canEdit) {
        const actions = [];
        if (this.canEdit) {
          actions.push({ label: this.$t('menu.forms.type.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-warning' });
        }
        headers.push({ key: 'actions', label: '', actions: actions });
      }
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      return null;
    },
    canEdit () {
      return (this.ApiRequest.userHasPermission('edit-custom-forms-type'));
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath('custom-form/type/list?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      return path;
    },
    tableActions () {
      const actions = { download: false };
      if (this.canEdit) {
        actions.add = { title: this.$t('menu.forms.type.new') };
      }
      return actions;
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
      this.loading = true;
      const self = this;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, function (response) {
        if (response.data) {
          self.tableItems = response.data;
          self.paging = response.paging;
          self.loading = false;
        }
      });
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    itemUpdated () {
      this.$emit('saved');
      this.getTableData();
    },
    editItem (el) {
      this.$refs.editElFrm.show(el);
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    }
  },
  mounted () {
    this.filterValues = {};
    this.getTableData();
    this.isMounted = true;
  }
}
