export default {
  name: 'FolderTreeItem',
  props: {
    tree: Object | Array,
    permissions: Object | Array,
    canViewFolderUsers: Boolean,
    canRequest: Boolean,
    level: Number,
    inheritPermission: String,
    updatingFolderIds: Array
  },
  computed: {
    permissionTypeConfigs () {
      const res = {};
      const types = this.$store.getters.getItemsFromConfigWithFilter('ad_group_permission_types');
      if (types) {
        Object.values(types).forEach(el => {
          if (el.code === 'R') {
            res[el.id] = { code: el.code, icon: 'eye-fill', rotate: 0 };
          } else if (el.code === 'RW') {
            res[el.id] = { code: el.code, icon: 'pen-fill', rotate: -90 };
          }
        });
      }
      return res;
    }
  },
  methods: {
    emitPermissionAction (permissionAction, folderId, item) {
      this.$emit(permissionAction.emit, { folderId: folderId, groupCode: permissionAction.code, permissionType: permissionAction.type, item: item })
    },
    hasPermission (folderId, permissionTypeId) {
      const permissionCode = this.permissionTypeConfigs[permissionTypeId].code;
      if (this.permissions[folderId] && ((this.permissions[folderId].includes(permissionCode) && !this.permissions[folderId].includes(permissionCode + '-REM')) ||
        (this.permissions[folderId].includes(permissionCode + '-REQ') && !this.permissions[folderId].includes(permissionCode)))
      ) {
        return true;
      }
      return false;
    },
    getPermissionActions (folder, groups) {
      const actions = {};
      if (this.permissions) {
        const self = this;
        Object.values(groups).forEach(el => {
          if (el.permission_type_id) {
            const hasPerm = self.hasPermission(folder.id, el.permission_type_id);
            if (hasPerm || el.code.startsWith(folder.groupbase)) {
              actions[el.permission_type_id] = {
                code: el.code,
                emit: (hasPerm ? 'removePermission' : 'requestPermission'),
                icon: self.permissionTypeConfigs[el.permission_type_id].icon,
                type: self.permissionTypeConfigs[el.permission_type_id].code,
                rotate: self.permissionTypeConfigs[el.permission_type_id].rotate,
                reverse: hasPerm
              }
            }
          }
        });
      }
      return actions;
    },
    getFolderMaxPermission (folderId) {
      if (this.permissions && this.permissions[folderId]) {
        if (this.inheritPermission === 'RW' || this.permissions[folderId].includes('RW')) {
          if (this.inheritPermission === 'RW-REM' || this.permissions[folderId].includes('RW-REM')) {
            return 'RW-REM';
          }
          return 'RW';
        } else if (this.inheritPermission === 'R' || this.permissions[folderId].includes('R')) {
          if (this.inheritPermission === 'R-REM' || this.permissions[folderId].includes('R_REM')) {
            return 'R-REM';
          }
          return 'R';
        } else if (this.inheritPermission === 'R-REQ' || this.permissions[folderId].includes('R-REQ')) {
          return 'R-REQ';
        } else if (this.inheritPermission === 'RW-REQ' || this.permissions[folderId].includes('RW-REQ')) {
          return 'RW-REQ';
        }
      }
      return this.inheritPermission;
    },
    getFolderIconClass (item) {
      item.maxPermission = this.getFolderMaxPermission(item.id);
      if (item.maxPermission !== '') {
        return 'folder-' + item.maxPermission;
      }
      return '';
    },
    getFolderIcon (folder) {
      if (folder.maxPermission !== '') {
        return 'bi-folder-fill'
      }
      return 'bi-folder'
    },
    requestPermission (id, groupCode) {
      this.$emit('requestPermission', id, groupCode);
    },
    viewFolderUsers (el) {
      this.$emit('viewFolderUsers', el);
    },
    changeDisplayRecursive (val) {
      this.displayChildren = val;
      this.$emit('changeDisplayRecursive', val);
    },
    hideChildrenRecursive (item) {
      if (item.children) {
        const self = this;
        Object.keys(item.children).forEach(function (key) {
          item.children[key].displayChildren = false;
          self.hideChildrenRecursive(item.children[key]);
        });
      }
    },
    toggleDisplayChildren (item) {
      if (item.children) {
        if (item.displayChildren) {
          item.displayChildren = false;
          this.hideChildrenRecursive(item);
        } else {
          item.displayChildren = true;
        }
        this.$forceUpdate();
      }
    }
  }
}
