import { render, staticRenderFns } from "./UserListExpand.vue?vue&type=template&id=fac307ce"
import script from "./UserListExpand.js?vue&type=script&lang=js&external"
export * from "./UserListExpand.js?vue&type=script&lang=js&external"
import style0 from "./UserListExpand.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports