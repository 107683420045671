import { mapState } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import FolderTree from '@/components/folder/tree/FolderTree.vue'

const defaultEl = {
  id: null,
  name: {},
  code: '',
  description: {},
  job_positions: []
};

export default {
  name: 'JobEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false
    }
  },
  validations: {
    editEl: {
      name: { required },
      code: { required }
    }
  },
  components: {
    FolderTree
  },
  computed: {
    ...mapState(['config']),
    getTitle () {
      if (this.editEl.id) {
        return this.$t('menu.companies.job.edit');
      }
      return this.$t('menu.companies.job.new');
    },
    canEdit () {
      return (!this.loading && this.ApiRequest.userHasPermission('edit-company-settings'));
    },
    companyOptions () {
      return this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0'));
    },
    divisionOptions () {
      let options = [];
      if (this.config.company_divisions) {
        for (const id in this.config.company_divisions) {
          options.push({
            value: id,
            text: this.config.company_divisions[id].name[this.$i18n.locale],
            company_id: parseInt(this.config.company_divisions[id].company_id),
            department_id: parseInt(this.config.company_divisions[id].department_id)
          })
        }
      }
      options = this.BasicHelper.sortArrayByKeyValue(options, 'text');
      options.unshift({ value: null, text: '' });
      return options;
    },
    departmentOptions () {
      const options = [];
      if (this.config.company_departments) {
        for (const id in this.config.company_departments) {
          if (!this.config.company_departments[id].deleted) {
            options.push({
              value: id,
              text: this.config.company_departments[id].name[this.$i18n.locale],
              company_id: parseInt(this.config.company_departments[id].company_id)
            })
          }
        }
      }
      options.unshift({ value: null, text: '' });
      return options;
    }
  },
  methods: {
    show (elData) {
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      const self = this;
      this.editEl.job_positions.forEach(jobpos => {
        jobpos.editing = false;
        self.updatePositionOptions(jobpos);
      });
      this.v$.$reset();
      this.$refs['modal-window'].show();
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        const jobPositions = [];
        this.editEl.job_positions.forEach(el => {
          jobPositions.push({
            id: el.id,
            company_id: el.company_id,
            department_id: el.department_id,
            division_id: el.division_id,
            deleted: (el.deleted ? 1 : 0)
          })
        });
        reqData.job_positions = JSON.stringify(jobPositions);
        this.ApiRequest.request('config/company/jobs/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, self, { 'item.code': 'general.item.code' });
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    },
    nameChanged (value) {
      if (this.editEl.id === null) {
        this.editEl.code = this.BasicHelper.generateCodeByName(value[this.$i18n.locale], 'JOB_', 30);
      }
    },
    updatePositionOptions (item) {
      const companyId = parseInt(item.company_id);
      const departmentId = parseInt(item.department_id);
      item.departmentOptions = (companyId ? this.departmentOptions.filter(el => { return (el.value === null || el.company_id === companyId) }) : this.departmentOptions);
      item.divisionOptions = this.divisionOptions.filter(el => {
        if (el.value === null) {
          return true;
        }
        if ((companyId && el.company_id !== companyId) || (departmentId && el.department_id !== departmentId)) {
          return false;
        }
        return true;
      });
    },
    addPosition () {
      const el = { id: null, company_id: null, department_id: null, division_id: null, groups: [] };
      this.updatePositionOptions(el);
      this.editEl.job_positions.push(el);
    },
    deletePosition (key) {
      if (this.editEl.job_positions[key].id) {
        this.editEl.job_positions[key].deleted = true;
      } else {
        this.editEl.job_positions.splice(key, 1);
      }
      this.$forceUpdate();
    },
    editPosition (key) {
      if (this.editEl.job_positions[key].editing) {
        this.editEl.job_positions[key].editing = false;
      } else {
        this.editEl.job_positions[key].editing = true;
      }
      this.$forceUpdate();
    },
    folderGroupsChanged (data, key) {
      this.editEl.job_positions[key].ad_group_permissions = data;
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
