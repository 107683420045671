import Login from 'ebg-vue-common/src/components/user/login/Login.vue'
import PhoneLogin from 'ebg-vue-common/src/components/user/phoneLogin/PhoneLogin.vue'

export default {
  data () {
    return {
      activeTab: null
    }
  },

  components: {
    Login,
    PhoneLogin
  },

  computed: {
    logTypeOptions () {
      return [
        { code: 'Login', text: this.$t('menu.user.username'), slotcode: 'Login' },
        { code: 'PhoneLogin', text: 'SMS', slotcode: 'PhoneLogin' }
      ];
    },
    showPhoneLogin () {
      return process.env.VUE_APP_SHOW_PHONE_LOGIN == 1;
    }
  },

  methods: {
    tabChanged (el) {
      if (this.logTypeOptions[el]) {
        this.activeTab = this.logTypeOptions[el].code;
        localStorage.setItem('active_tab_login', this.activeTab);
      }
    }
  },

  mounted () {
    const activeTab = localStorage.getItem('active_tab_login');
    this.activeTab = activeTab === null ? 'PhoneLogin' : activeTab;
  }
}
