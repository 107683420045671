import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import UserJobs from '@/components/user/tab/jobs/list/UserJobs.vue';
import UserEducation from '@/components/user/tab/education/list/UserEducation.vue';
import UserQualifications from '@/components/user/tab/qualifications/list/UserQualifications.vue';
import UserContactPersons from '@/components/user/tab/contactPersons/list/UserContactPersons.vue';
import UserWorkSecurity from '@/components/user/tab/workSecurity/list/UserWorkSecurity.vue';
import SnipeAssetUserAssets from '@/components/snipeasset/user/assets/SnipeAssetUserAssets.vue'

export default {
  name: 'MyData',

  components: {
    UserJobs,
    UserEducation,
    UserQualifications,
    UserContactPersons,
    UserWorkSecurity,
    SnipeAssetUserAssets
  },

  data () {
    return {
      editPasswordEl: {
        password: '',
        passwordRepeat: ''
      },
      loading: true,
      loadingPassword: false,
      success: false,
      user: null,
      editingPassword: false,
      activeTab: 'UserJobs'
    }
  },

  validations: {
    editPasswordEl: {
      password: {
        required,
        validatepassword: function (value, vm) {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsSpecial = /[#?!@$%^&*-]/.test(value)
          return (value.length === 0 || (value.length > 5 && containsUppercase && containsLowercase && containsNumber && containsSpecial));
        }
      },
      passwordRepeat: {
        required,
        sameAsPassword: function (value, vm) {
          return (value === vm.password)
        }
      }
    }
  },

  computed: {
    tabOptions () {
      return [
        { code: 'UserJobs', text: this.$t('user.tab.jobs'), slotcode: 'UserJobs' },
        { code: 'UserEducation', text: this.$t('user.tab.education'), slotcode: 'UserEducation' },
        { code: 'UserQualifications', text: this.$t('user.tab.qualifications'), slotcode: 'UserQualifications' },
        { code: 'UserContactPersons', text: this.$t('user.tab.contact-persons'), slotcode: 'UserContactPersons' },
        { code: 'UserWorkSecurity', text: this.$t('user.tab.work-security'), slotcode: 'UserWorkSecurity' },
        { code: 'SnipeAssetUserAssets', text: this.$t('user.tab.inventory'), slotcode: 'SnipeAssetUserAssets' }
      ]
    }
  },

  methods: {
    loadData () {
      this.ApiRequest.request('user/data', this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.data) {
          this.user = response.data.user;
        }
        this.loading = false;
      });
    },

    save () {
      this.$v.$touch();
      const path = 'user/editPassword/' + this.user.api_id;
      if (this.$v.$invalid || this.loadingPassword) {
        return;
      }
      this.loadingPassword = true;
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, this.editPasswordEl, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else {
          this.success = true;
        }
        this.editPasswordEl.password = '';
        this.editPasswordEl.passwordRepeat = '';
        this.$v.$reset();
        this.editingPassword = false;
        this.loadingPassword = false;
      });
    },

    tabChanged (el) {
      if (this.tabOptions[el]) {
        this.activeTab = this.tabOptions[el].code;
      }
    },

    edit () {
      this.editingPassword = !this.editingPassword;
      this.success = false;
    },

    cancel () {
      this.v$.$reset();
      this.editingPassword = false;
      this.success = false;
    }
  },

  mounted () {
    this.loadData();
  },

  setup () {
    return { v$: useVuelidate() }
  }
}
