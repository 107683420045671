export default {
  name: 'CustomFormRecordRecipients',
  props: {
    record: {
      type: Object,
      default: {}
    }
  },
  computed: {
    recordStatus () {
      if (this.record.status_id) {
        const recordStatus = this.$store.getters.getDataFromConfigWithFilter('custom_form_record_statuses', 'id', this.record.status_id);
        return recordStatus.code;
      }
      return null;
    }
  },
  methods: {
    getNotificationVariant (notification) {
      if (this.recordStatus === 'CANCELED') {
        return ''
      }
      if (notification.completed) {
        if (notification.approved === 0) {
          return 'danger';
        }
        if (notification.approved || !notification.approve) {
          return 'success';
        }
      } else {
        if (notification.approve && notification.email_message_id) {
          return 'warning';
        }
        return null;
      }
    },
    getSentAtInfo (notification) {
      let txt = '';
      if (notification.sent_at) {
        txt = this.BasicHelper.formatDate(notification.sent_at, this.$t('general.dateTimeFormat'))
        if (notification.sent_at != notification.last_sent_at) {
          txt = this.BasicHelper.formatDate(notification.last_sent_at, this.$t('general.dateTimeFormat')) +
            ' (' + this.$t('custom-form.notifications.first_sent_at') + ' - ' + txt + ')';
        }
      }
      return txt;
    }
  }
}
