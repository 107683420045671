import UserCard from '@/components/user/card/UserCard.vue'

export default {
  name: 'CompanyEmployeeList',
  props: {
    employees: Array || Object,
    users: Object | Array
  },
  components: {
    UserCard
  },
  methods: {
    toggleEmployeeCard (employee, isVisible = -1) {
      if (isVisible === -1) {
        if (employee.displayCard) {
          employee.displayCard = false;
        } else {
          employee.displayCard = true;
        }
      } else {
        employee.displayCard = isVisible;
      }
      this.$forceUpdate();
    }
  }
}
