import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core';

const defaultEl = {
  id: null,
  userApi_id: null,
  security_type_id: null,
  validTo: null
}

export default {
  name: 'UserWorkSecurityEdit',

  data () {
    return {
      form: [],
      header_title: '',
      isLoading: false
    }
  },

  validations: {
    form: {
      security_type_id: { required },
      validTo: { required }
    }
  },

  computed: {
    securityTypeOptions () {
      return this.$store.getters.getConfigOptions('job_security_types', this.$i18n.locale);
    },

    isNewRecord () {
      return this.form.id === 'NEW';
    }
  },

  methods: {
    async show (id, userApiId) {
      this.v$.$reset();
      this.form = this.BasicHelper.cloneObject(defaultEl);
      if (id !== 'NEW') {
        const { data } = await this.loadData(`user/work-security/show/${id}`);
        this.BasicHelper.syncObjectValuesByKeys(this.form, data);
      }
      this.form.id = id;
      this.form.userApi_id = userApiId;
      this.header_title = id === 'NEW' ? this.$t('user.workSecurity.title-add') : this.$t('user.workSecurity.title-update');
      this.$refs['modal-window'].show()
    },

    async submitForm () {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      this.isLoading = true;
      const { data } = await this.save(`user/work-security/save/${this.form.id}`);
      let ddtype = '';
      if (data) {
        ddtype = this.$store.getters.getParamFromConfig('job_security_types', data.security_type_id, 'name', this.$i18n.locale);
        let msg = ''
        if (this.form.id === 'NEW') {
          msg = this.$t('user.workSecurity.msg-add-successfully', { type: ddtype });
        } else {
          msg = this.$t('user.workSecurity.msg-update-successfully', { type: ddtype });
        }

        this.$store.commit('toastAdd', { context: 'success', message: msg });
      }
      this.isLoading = false;
      this.$emit('changeDate');
      this.$refs['modal-window'].hide();
    },

    async save (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, this.form, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    },

    async loadData (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, this.form, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  },

  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'UserWorkSecurityEdit' }) }
  }
}
