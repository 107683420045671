import Captcha from 'ebg-vue-common/src/components/common/captcha/Captcha.vue'
import useVuelidate from '@vuelidate/core';

export default {
  name: 'UserResetPassword',
  data: function () {
    return {
      name: '',
      email: '',
      password: '',
      passwordRepeat: '',
      pageLoading: true,
      loading: false,
      apiId: null,
      resetToken: null,
      formSuccess: false,
      errorMsg: null,
      captcha: {}
    }
  },
  components: {
    Captcha
  },
  validations: {
    passwordRepeat: {
      sameAsPassword: function (value, vm) {
        return (value === vm.password)
      },
      validatepassword: function (value) {
        if (this.$route.meta.strongpass) {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsSpecial = /[#?!@$%^&*-]/.test(value)
          return (value.length > 5 && containsUppercase && containsLowercase && containsNumber && containsSpecial);
        }
        return (value.length > 4);
      }
    }
  },
  methods: {
    generateResetPassword () {
      this.loading = true;
      const reqData = { user: { email: this.email }, captcha_key: this.captcha.key, captcha_value: this.captcha.value };
      this.ApiRequest.request('user/reset-password/reset', this.ApiRequest.REQUEST_POST, reqData, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this)
        } else {
          this.formSuccess = true;
        }
        if (response.captcha) {
          this.$refs.captchainput.resetCaptcha(response.captcha);
        }
        this.loading = false;
      });
    },
    changePassword () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const path = 'user/reset-password/change-password/' + this.apiId;
        const reqData = { token: this.resetToken, user: { password: this.password, passwordRepeat: this.passwordRepeat } };
        this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, reqData, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, self);
          } else {
            this.formSuccess = true;
          }
          this.loading = false;
        });
      }
    }
  },
  mounted () {
    if (this.$route.params.apiId && this.$route.query.token) {
      this.apiId = this.$route.params.apiId;
      this.resetToken = this.$route.query.token;
      const path = 'user/reset-password/get-reset-password-user/' + this.apiId + '?token=' + this.resetToken;
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.error) {
          this.resetToken = null;
          this.apiId = null;
        } else {
          this.email = response.data.email;
          this.name = response.data.name;
        }
        this.pageLoading = false;
      });
    } else {
      this.pageLoading = false;
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
