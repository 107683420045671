export default {
  name: 'ContentBlocksView',
  props: {
    code: {
      type: String,
      default: null
    },
    card: {
      type: Boolean,
      default: true
    },
    classes: {
      type: String,
      default: ''
    },
    loadOnMount: {
      type: Boolean,
      default: false
    },
    contenthtml: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: this.loadOnMount,
      content: this.contenthtml
    }
  },
  methods: {
    loadBlock () {
      this.ApiRequest.request('config/content-blocks/view/' + this.code, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.error) {
          this.content = this.$t('content-blocks.not-found', { block: this.code });
        } else {
          this.content = response.data;
        }
        this.loading = false;
      });
    }
  },
  mounted () {
    if (this.loadOnMount) {
      this.loadBlock();
    }
  }
}
