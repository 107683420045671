import UserCard from '@/components/user/card/UserCard.vue'
import CompanyEmployeeList from '@/components/common/companytree/employeelist/CompanyEmployeeList.vue'
import { mapState } from 'vuex';

export default {
  name: 'CompanyTreeSubstree',
  props: {
    tree: Array,
    users: Object | Array,
    treeLvl: {
      type: Number,
      default: 0
    }
  },
  components: {
    UserCard,
    CompanyEmployeeList
  },
  computed: {
    ...mapState(['config']),
    getRowStyle () {
      return 'width: calc(100% - ' + (30 - 15 * this.treeLvl) + 'px);';
    }
  },
  methods: {
    toggleEmployeesVisible (subtree) {
      if (subtree.showEmployees) {
        subtree.showEmployees = false;
      } else {
        subtree.showEmployees = true;
      }
      this.$forceUpdate();
    },
    toggleEmployeeCard (employee, isVisible = -1) {
      if (isVisible === -1) {
        if (employee.displayCard) {
          employee.displayCard = false;
        } else {
          employee.displayCard = true;
        }
      } else {
        employee.displayCard = isVisible;
      }
      this.$forceUpdate();
    }
  }
}
