import { mapState } from 'vuex';
import EbgSystemUsers from '@/components/ebgsystems/users/EbgSystemUsers.vue';

export default {
  name: 'EbgSystemUserGroups',
  props: {
    user: Object,
    groups: Object | Array,
    loading: Boolean
  },
  data () {
    return {
      updatingUserGroups: false,
      selectedSystem: null,
      selectedSystemUserGroups: [],
      editSelectedSystemsTitle: null,
      autoApproveReq: false
    }
  },
  components: {
    EbgSystemUsers
  },
  computed: {
    ...mapState(['config']),
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-user-system-groups');
    },
    canViewSystemUsers () {
      return (this.ApiRequest.userHasPermission('view-company-users') || this.ApiRequest.userHasPermission('view-all-users'));
    },
    tableHeaders () {
      const headers = [
        { key: 'title', label: this.$t('ebgsystem.title') },
        {
          key: 'activeGroups',
          label: this.$t('user.ad-groups.all'),
          type: 'slot',
          slotcode: 'activegroups',
          classParam: 'permission',
          component: 'AdGroupName',
          bicon: (this.canEdit ? 'x' : null)
        }
      ];
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('menu.user.ad-groups.edit'), emit: 'editItems', bicon: 'pencil-fill', class: 'btn-warning btn-sm' })
      }
      if (this.canViewSystemUsers) {
        actions.push({ label: this.$t('menu.user.ad-groups.list'), emit: 'viewList', bicon: 'list', class: 'btn-sm' })
      }
      if (actions.length > 0) {
        headers.push({ key: 'actions', label: '', class: 'text-end col-width-medium', actions: actions });
      }
      return headers;
    },
    tableItems () {
      const data = [];
      const self = this;
      if (this.systems) {
        Object.values(this.systems).forEach(el => {
          const item = self.BasicHelper.cloneObject(el);
          item.activeGroups = (self.groups && self.groups[el.id]) ? self.groups[el.id] : [];
          data.push(item);
        });
      }
      return data;
    },
    systems () {
      return this.config.ebg_systems;
    }
  },
  methods: {
    closeModal (refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].hide();
      }
    },
    editItems (system) {
      this.selectedSystemUserGroups = [];
      this.selectedSystem = system;
      this.editSelectedSystemsTitle = this.$t('ebgsystem.editGroupsTitle', {
        username: this.user ? this.user.name + ' ' + this.user.surname : this.$session.get('name'),
        system: this.selectedSystem.title
      })
      const self = this;
      Object.values(this.selectedSystem.groups).forEach(el => {
        self.selectedSystemUserGroups[el.code] = (system.activeGroups[el.id] && system.activeGroups[el.id].permission !== 'RW-REM');
      });
      this.$refs.editUserGroups.show();
    },
    removeItem (system, group) {
      const el = { system: system, group: group, user: this.user };
      this.$refs.msgBoxApproveRemoveGroup.show(
        el,
        this.$t('ebgsystem.removeGroupTitle'),
        this.$t('ebgsystem.removeGroupMessage', { system: system.title, group: group.code, username: (this.user.name + ' ' + this.user.surname) })
      )
    },
    removeItemApproved (item) {
      const reqData = { user: item.user.api_id, groups: [], autoapprove: false };
      reqData.groups.push({ group: item.group.code, delete: true });
      this.changeSystemPermissions(item.system.id, reqData);
    },
    editSystemGroups () {
      const reqData = { user: this.user.api_id, groups: [], autoapprove: this.autoApproveReq };
      for (const [groupCode, isChecked] of Object.entries(this.selectedSystemUserGroups)) {
        reqData.groups.push({ group: groupCode, delete: !isChecked })
      }
      this.changeSystemPermissions(this.selectedSystem.id, reqData);
    },
    changeSystemPermissions (systemId, reqData) {
      const self = this;
      this.updatingUserGroups = true;
      this.ApiRequest.request('ad-groups/ebg-systems/' + systemId + '/change-permissions', this.ApiRequest.REQUEST_POST, reqData, function (response) {
        if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
        } else if (response.data) {
          self.user.ad_group_permissions = response.data;
          self.$refs.editUserGroups.hide();
        }
        self.updatingUserGroups = false;
      });
    },
    viewList (system) {
      this.selectedSystem = system;
      this.$refs.modalUsers.show();
    }
  }
}
