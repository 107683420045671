import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import TextEditor from 'ebg-vue-common/src/components/common/texteditor/TextEditor.vue';
import Fileupload from 'ebg-vue-common/src/components/common/fileupload/Fileupload.vue';

const defaultEl = {
  id: null,
  title: null,
  content: null,
  published_at: null,
  companies_access: null,
  files: []
};
export default {
  name: 'NewsEdit',
  components: {
    TextEditor,
    Fileupload
  },
  data () {
    return {
      editEl: defaultEl,
      loading: false,
      isNew: false
    }
  },
  methods: {
    show () {
      if (!this.isNew) {
        this.loading = true;
        this.ApiRequest.request(`news/${this.$route.params.id}/`, this.ApiRequest.REQUEST_GET, [], (response) => {
          if (response.data) {
            this.editEl = response.data;
            this.editEl.companies_access = response.data.companies_access.map(object => object.ref_id);
            this.v$.$reset();
            this.loading = false;
          } else {
            this.ApiRequest.displayErrorDialog(response, this);
          }
        })
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
    },
    save () {
      this.$forceUpdate();
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = this.ApiRequest.createFormData(this.editEl, ['title', 'content', 'published_at', 'files', 'companies_access'], 'news', this, false);
        if (reqData) {
          const path = this.editEl.id ? 'news/' + this.editEl.id + '/edit' : 'news/';
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_FILEUPLOAD, reqData, (response) => {
            if (response.error) {
              this.ApiRequest.displayErrorDialog(response, this);
            } else {
              this.$store.commit('toastAdd', {
                context: 'success',
                message: this.isNew ? this.$t('news.actions.published') : this.$t('news.actions.saved')
              });
              this.$emit('saved', response.data);
              this.close();
            }
            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      }
    },
    downloadfile (key) {
      this.editEl.files[key].loading = true;
      this.$refs.fileupload.forceUpdate(key);
      this.ApiRequest.request(`news/${this.editEl.id}/downloadfile/${this.editEl.files[key].id}`, this.ApiRequest.REQUEST_DOWNLOAD, [], () => {
        this.editEl.files[key].loading = false;
        this.$refs.fileupload.forceUpdate(key);
      });
    },
    close () {
      this.$router.push({ name: 'menu.admin.news-list' });
    }
  },
  validations: {
    editEl: {
      title: { required }
    }
  },
  computed: {
    canEdit () {
      return (!this.loading && this.ApiRequest.userHasPermission('edit-news'));
    },
    canPublish () {
      return (!this.loading && this.ApiRequest.userHasPermission('publish-news'));
    },
    companyOptions () {
      const options = this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0'));
      options.unshift({ value: null, text: '' })
      return options;
    }
  },
  watch: {
    '$route.params.id': {
      deep: true,
      immediate: true,
      handler (val) {
        if (val === 'NEW') {
          this.editEl = this.BasicHelper.cloneObject(defaultEl);
        }
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  mounted () {
    this.isNew = this.$route.params.id === 'NEW';
    this.show();
  }
}
