import CustomTreeItems from '@/components/common/customtree/items/CustomTreeItems.vue'
import LocalePicker from 'ebg-vue-common/src/components/common/localepicker/LocalePicker.vue'

export default {
  name: 'CustomTree',
  props: {
    revision: Object,
    editable: {
      type: Boolean,
      default: false
    },
    treeLvl: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isEditable: (this.revision.id === null),
      locale: this.$i18n.locale
    }
  },
  components: {
    CustomTreeItems,
    LocalePicker
  },
  methods: {
    treeModified () {
    }
  }
}
