import TextEditor from 'ebg-vue-common/src/components/common/texteditor/TextEditor.vue'
const defaultCardItem = {
  name: '',
  description: '',
  backgroundColor: '#FFF',
  textColor: '#212529',
  children: []
};

export default {
  name: 'CustomTreeItems',
  props: {
    items: Array,
    editable: {
      type: Boolean,
      default: false
    },
    treeLvl: {
      type: Number,
      default: 0
    },
    treeLvlSub: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TextEditor
  },
  computed: {
    getRowStyle () {
      return 'width: calc(100% - 5 +' + (5 * this.treeLvl) + 'px);';
    },
    headItem () {
      if (this.treeLvl === 0 && this.items.length === 1) {
        return this.items[0];
      }
      return null;
    }
  },
  methods: {
    addCard (item) {
      const newItem = this.BasicHelper.cloneObject(defaultCardItem);
      if (item) {
        newItem.backgroundColor = item.backgroundColor;
        newItem.textColor = item.textColor;
      }
      this.items.push(newItem);
      this.$emit('modified');
    },
    getCardStyle (item) {
      return 'background-color:' + item.backgroundColor + ';color:' + item.textColor;
    },
    removeCard (index) {
      this.items.splice(index, 1);
      this.$emit('modified');
    }
  }
}
