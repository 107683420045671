export default {
  name: 'NewsItem',
  props: {
    item: {
      type: Object,
      default: null
    },
    className: {
      type: String,
      default: null
    }
  },
  methods: {
    downloadFile (fileKey) {
      this.item.files[fileKey].loading = true;
      this.$forceUpdate();
      this.ApiRequest.request('news/' + this.item.id + '/downloadfile/' + this.item.files[fileKey].id, this.ApiRequest.REQUEST_DOWNLOAD, [], () => {
        this.item.files[fileKey].loading = false;
        this.$forceUpdate();
      });
    }
  }
}
