import { mapState } from 'vuex'
import CompanyEmployeeList from '@/components/common/companytree/employeelist/CompanyEmployeeList.vue'
import UserCard from '@/components/user/card/UserCard.vue'
import CompanyTreeSubtree from '@/components/common/companytree/subtree/CompanyTreeSubtree.vue'

export default {
  name: 'CompanyTree',
  data () {
    return {
      mounted: false,
      loading: true,
      company: null,
      trees: null,
      users: null
    }
  },
  components: {
    CompanyEmployeeList,
    UserCard,
    CompanyTreeSubtree
  },
  computed: {
    ...mapState(['config']),
    companyOptions () {
      return this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0'), 'text');
    }
  },
  methods: {
    loadTrees () {
      const self = this;
      this.loading = true;
      let path = 'config/company/structure';
      if (this.company) {
        path += '/' + this.company;
      }
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, function (response) {
        if (response.data) {
          self.trees = response.data.trees;
          self.users = response.data.users;
        }
        self.loading = false;
      })
    },
    toggleEmployeesVisible (subtree) {
      if (subtree.showEmployees) {
        subtree.showEmployees = false;
      } else {
        subtree.showEmployees = true;
      }
      this.$forceUpdate();
    },
    toggleEmployeeCard (employee, isVisible = -1) {
      if (isVisible === -1) {
        if (employee.displayCard) {
          employee.displayCard = false;
        } else {
          employee.displayCard = true;
        }
      } else {
        employee.displayCard = isVisible;
      }
      this.$forceUpdate();
    },
    getTreeColWidth (itemcount) {
      return (100 / itemcount) + '%';
    },
    getEmployeeTitle (employee) {
      return this.users[employee.user_id].fullname + ' (' +
        this.BasicHelper.getConfigValue('company_jobs', 'id', employee.job_id, 'name', true) +
        ')';
    }
  },
  mounted () {
    this.company = this.$store.getters.getParamFromConfig('user_companies', this.$session.get('company_id'), 'code');
    this.mounted = true;
    this.loadTrees();
  }
}
