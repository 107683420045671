import AxaptaSalaryCard from '@/components/axapta/user/salary/card/AxaptaSalaryCard.vue'

export default {
  name: 'AxaptaSalaryList',
  props: {
    userApiId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      tableItems: [],
      user: null,
      loading: true,
      paging: { limit: 10, page: 1, total: 0, pages: 0 }
    }
  },
  components: {
    AxaptaSalaryCard
  },
  computed: {
    tableHeaders () {
      const actions = [
        { label: this.$t('general.filters.download'), emit: 'downloadCard', bicon: 'download', class: 'btn-info' },
        { label: this.$t('axapta.salary-card.view'), emit: 'viewCard', bicon: 'list', class: 'btn-primary' }
      ];
      const headers = [
        { key: 'date', label: this.$t('axapta.salary-card.date'), format: 'formatFunc', funcs: [this.getMonthWithYear], classFuncs: [null] },
        { key: 'filledByName', label: this.$t('axapta.salary-card.filledByName') },
        { key: 'total_bruto', label: this.$t('axapta.salary-card.total_bruto'), format: 'formatDecimal', precision: 2 },
        { key: 'total_neto', label: this.$t('axapta.salary-card.total_neto'), format: 'formatDecimal', precision: 2 },
        { key: 'actions', label: '', actions: actions }
      ];
      return headers;
    }
  },
  methods: {
    getTableData () {
      const userApiId = this.userApiId !== null ? this.userApiId : this.$session.get('uuid');
      if (userApiId) {
        this.loading = true;
        const self = this;
        const path = `axapta/salary-card/list/${userApiId}?perPage=${this.paging.limit}&page=${this.paging.page}`;
        this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, [], function (response) {
          if (response.data) {
            self.tableItems = response.data;
            self.paging = response.paging;
          }
          self.loading = false;
        });
      }
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    downloadCard (item) {
      item.downloading = true;
      const self = this;
      this.ApiRequest.request('axapta/salary-card/card/' + item.id + '/download', this.ApiRequest.REQUEST_DOWNLOAD, [], function (response) {
        if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
        }
        item.downloading = false;
      });
    },
    viewCard (item) {
      this.$refs.axaptaSalaryCard.setData(item.id);
    },
    getMonthWithYear (val) {
      return this.BasicHelper.getDateString(val, 'general.dateFormatMonthNameYear');
    }
  },
  mounted () {
    this.getTableData()
  }
}
