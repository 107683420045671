import CompanyEdit from '@/components/company/edit/CompanyEdit.vue'
import DepartmentList from '@/components/company/departments/list/DepartmentList.vue'
const defaultFilterValues = {
  code: '',
  name: '',
  disabled: [0]
};

export default {
  name: 'CompanyList',
  components: {
    CompanyEdit,
    DepartmentList
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'code', direction: 'asc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  computed: {
    tableHeaders () {
      const headers = [
        { key: 'name.' + this.$i18n.locale, label: this.$t('company-structure.company.name'), sortable: false, filterKey: 'name' },
        { key: 'code', label: this.$t('general.item.code'), sortable: true, filterKey: 'code' },
        { key: 'head_user.fullname', label: this.$t('company-structure.company.head-user') },
        { key: 'disabled', label: this.$t('company-structure.company.isDisabled'), filterKey: 'disabled', format: 'formatYesNo', reverseYesNo: true }
      ];
      if (this.canEdit) {
        const actions = [];
        if (this.canEdit) {
          actions.push({ label: this.$t('menu.companies.company.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-warning' });
        }
        headers.push({ key: 'actions', label: '', actions: actions });
      }
      return headers;
    },
    tableExpandConfig () {
      return { component: 'slot' }
    },
    tableFilterConfig () {
      const filters = { };
      filters.code = { type: 'text', label: '', colOnlyFilter: true };
      filters.name = { type: 'text', label: '', colOnlyFilter: true };
      filters.disabled = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };
      return filters;
    },
    canEnableDisable () {
      return this.ApiRequest.userHasPermission('edit-company-settings');
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-company-settings');
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions(true);
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath('config/company/companies/list?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      return path;
    },
    tableActions () {
      const actions = { download: false, clearCache: true };
      if (this.canEdit) {
        // Not implemented API company add
        // actions.add = { title: this.$t('menu.companies.company.new') };
      }
      return actions;
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
      this.loading = true;
      const self = this;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, function (response) {
        if (response.data) {
          self.tableItems = response.data;
          self.paging = response.paging;
          self.loading = false;
        }
      });
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    itemUpdated () {
      this.$emit('saved');
      this.getTableData();
    },
    editItem (el) {
      this.$refs.editElFrm.show(el);
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
    this.isMounted = true;
  }
}
