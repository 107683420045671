export default {
  name: 'UserInventory',

  components: {

  },

  data () {
    return {
      isLoading: false,
      paging: { limit: 10, page: 1, total: 0, pages: 0 }
    }
  },

  computed: {

  },

  methods: {
    show () {
      console.log('Nr.8');
    },

    addItem () {
      console.log('add');
    }
  },

  mounted () {
    this.show();
  }
}
