import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core';

const defaultEl = {
  id: null,
  userApi_id: null,
  qualification_id: null,
  documentNo: null,
  validFrom: null,
  validTo: null,
  category: [],
  restrictions: null,
  issue: null,
  isDocumentPresented: false
}

export default {
  name: 'UserQualificationEdit',

  data () {
    return {
      form: [],
      header_title: '',
      isLoading: false,
      categoryOptions: []
    }
  },

  validations: {
    form: {
      qualification_id: { required }
    }
  },

  computed: {
    qualificationOptions () {
      return this.$store.getters.getConfigOptions('qualifications', this.$i18n.locale, null, null, false, false, false, ['type_id']);
    }
  },

  methods: {
    async show (id, userApiId) {
      this.v$.$reset();
      this.form = this.BasicHelper.cloneObject(defaultEl);
      if (id !== 'NEW') {
        const { data } = await this.loadData(`user/qualification/show/${id}`);
        this.BasicHelper.syncObjectValuesByKeys(this.form, data);
      }
      this.form.id = id;
      this.form.userApi_id = userApiId;
      this.header_title = id === 'NEW' ? this.$t('user.qualification.title-add') : this.$t('user.qualification.title-update');
      this.$refs['modal-window'].show()
    },

    changeQualification (el) {
      this.categoryOptions = [];
      if (el !== null) {
        this.categoryOptions = this.$store.getters.getConfigOptions(
          'qualification_categories', this.$i18n.locale, null, null, false, false, false, ['type_id']
        ).filter(
          row => row.type_id == el.type_id
        );
      }
    },

    async submitForm () {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      this.isLoading = true;
      const { data } = await this.save(`user/qualification/save/${this.form.id}`);
      let qualificationName = '';
      if (data) {
        qualificationName = this.$store.getters.getParamFromConfig('qualifications', data, 'name', this.$i18n.locale);
      }
      this.$refs['modal-window'].hide();
      this.$emit('changeDate');
      this.isLoading = false;
      let msg = ''
      if (this.form.id === 'NEW') {
        msg = this.$t('user.qualification.msg-add-successfully', { name: qualificationName });
      } else {
        msg = this.$t('user.qualification.msg-update-successfully', { name: qualificationName });
      }

      this.$store.commit('toastAdd', { context: 'success', message: msg });
    },

    async save (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, this.form, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    },

    async loadData (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, this.form, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  },

  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'UserQualificationEdit' }) }
  }
}
