import { mapState } from 'vuex'

export default {
  name: 'UserCard',

  props: [
    'user',
    'job_id',
    'jobs'
  ],

  computed: {
    ...mapState(['config'])
  }
}
