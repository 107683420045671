export default {
  name: 'CustomTreeList',
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  computed: {
    tableHeaders () {
      const headers = [
        { key: 'name.' + this.$i18n.locale, label: this.$t('custom-tree.tree.name') },
        { key: 'company_id', label: this.$t('custom-tree.tree.company'), format: 'formatFromConfig', configKey: 'user_companies', configParam: 'name' },
        { key: 'public', label: this.$t('custom-tree.tree.public'), format: 'formatYesNo' },
        { key: 'active_revision.version', label: this.$t('custom-tree.tree.version') },
        { key: 'active_revision.modified_by_user.fullname', label: this.$t('custom-tree.tree.modifiedByUser') },
        { key: 'active_revision.approvedAt', label: this.$t('custom-tree.tree.approvedAt'), type: 'datetime' },
        { key: 'active_revision.approved_by_user.fullname', label: this.$t('custom-tree.tree.approvedByUser') }
      ];
      if (this.canEdit) {
        const actions = [];
        if (this.canEdit) {
          actions.push({ label: this.$t('menu.custom-tree.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-warning' });
          actions.push({ label: this.$t('menu.custom-tree.approve'), emit: 'approveItem', bicon: 'check-all', class: 'btn-primary', ifconfig: { param: 'active_revision_id', value: null, notCheck: true } });
          actions.push({ label: this.$t('menu.custom-tree.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-danger' });
        }
        headers.push({ key: 'actions', label: '', actions: actions });
      }
      return headers;
    },
    tableExpandConfig () {
      return { component: 'CustomTreeView' }
    },
    requestPath () {
      return 'custom-tree/list?perPage=' + this.paging.limit + '&page=' + this.paging.page;
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-all-custom-trees') || this.ApiRequest.userHasPermission('edit-company-custom-trees');
    },
    tableActions () {
      const actions = { download: false };
      if (this.canEdit) {
        actions.add = { title: this.$t('menu.custom-tree.new') };
      }
      return actions;
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.loading = true;
      const self = this;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, function (response) {
        if (response.data) {
          self.tableItems = response.data;
          self.paging = response.paging;
          self.loading = false;
        }
      });
    },
    editItem (el, newtab = 0) {
      let id = 'NEW';
      if (el) {
        id = el.id;
      }
      const routeData = this.$router.resolve({ name: 'menu.custom-tree.view', params: { treeid: id } });
      if (newtab) {
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push(routeData.href);
      }
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(
          el,
          this.$t('custom-tree.tree.deleteTitle'),
          this.$t('custom-tree.tree.deleteMessage', { name: this.BasicHelper.getTranslation(el.name) })
        )
      }
    },
    setDeleted (el, isDeleted) {
      this.loading = true;
      const self = this;
      this.ApiRequest.request('custom-tree/view/' + el.id + '/deleted', this.ApiRequest.REQUEST_POST, { id: el.id, deleted: isDeleted }, function (response) {
        if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
        } else {
          self.tableItems = response.data;
        }
        self.loading = false;
      });
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    approveItem (item) {
      item.loading = true;
      this.$forceUpdate();
      this.$refs.tableview.$forceUpdate();
      const path = 'custom-tree/view/' + item.id + '/revision/' + item.active_revision_id + '/approve';
      const self = this;
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, {}, function (response) {
        if (response.data) {
          const idx = self.tableItems.findIndex(function (el) {
            return (el.id === item.id);
          });
          if (self.tableItems[idx]) {
            self.tableItems[idx] = response.data;
          }
          item.loading = false;
        } else if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
          item.loading = false;
        }
        self.$refs.tableview.$forceUpdate();
      });
    }
  },
  mounted () {
    this.getTableData();
    this.isMounted = true;
  }
}
