import CustomFormRecordList from '@/components/common/customform/record/list/CustomFormRecordList.vue'

export default {
  name: 'CustomFormList',
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      tableSorting: { item: 'order', direction: 'asc' },
      isMounted: false
    };
  },
  components: {
    CustomFormRecordList
  },
  computed: {
    tableHeaders () {
      const headers = [
        { key: 'name.' + this.$i18n.locale, label: this.$t('custom-form.form.name'), type: 'slot', slotcode: 'name' },
        { key: 'company_id', label: this.$t('custom-form.form.company'), format: 'formatFromConfig', configKey: 'user_companies', configParam: 'name' },
        { key: 'type_id', label: this.$t('custom-form.form.type'), format: 'formatFromConfig', configKey: 'custom_form_types', configParam: 'name' }
      ];
      const actions = [];
      actions.push({ label: this.$t('custom-form.record.action.add'), emit: 'addRecord', bicon: 'plus', class: 'btn-primary' });
      if (this.canEdit) {
        actions.push({ label: this.$t('custom-form.form.action.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-warning' });
        actions.push({ label: this.$t('custom-form.form.action.deleteTitle'), emit: 'deleteItem', bicon: 'trash', class: 'btn-danger' });
      }
      headers.push({ key: 'actions', label: '', actions: actions });
      return headers;
    },
    tableExpandConfig () {
      return { component: 'slot' };
    },
    requestPath () {
      return 'custom-form/form/list/' + this.type + '?perPage=' + this.paging.limit + '&page=' + this.paging.page + '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-custom-forms');
    },
    tableActions () {
      const actions = { download: false };
      if (this.canEdit) {
        actions.add = { title: this.$t('custom-form.form.action.new') };
      }
      return actions;
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.loading = true;
      const self = this;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, function (response) {
        if (response.data) {
          self.tableItems = response.data;
          self.paging = response.paging;
          self.loading = false;
        }
      });
    },
    editItem (el, newtab = 0) {
      let id = 'NEW';
      if (el) {
        id = el.id;
      }
      const routeData = this.$router.resolve({ name: 'menu.forms.form-edit', params: { formid: id } });
      if (newtab) {
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push(routeData.href);
      }
    },
    addRecord (el, newtab = 0) {
      const routeData = this.$router.resolve({ name: 'menu.forms.record-edit', params: { formid: el.id, recordid: 'NEW' } });
      if (newtab) {
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push(routeData.href);
      }
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(
          el,
          this.$t('custom-form.form.action.deleteTitle'),
          this.$t('custom-form.form.action.deleteMessage', { name: this.BasicHelper.getTranslation(el.name) })
        )
      }
    },
    setDeleted (el, isDeleted) {
      el.loading = true;
      this.$refs.tableview.$forceUpdate();
      const self = this;
      this.ApiRequest.request('custom-form/form/view/' + el.id + '/deleted', this.ApiRequest.REQUEST_POST, { id: el.id, deleted: isDeleted }, function (response) {
        el.loading = false;
        if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
        } else {
          self.getTableData();
        }
      });
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    }
  },
  mounted () {
    this.getTableData();
    this.isMounted = true;
  }
}
