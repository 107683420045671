import { mapState } from 'vuex';

export default {
  name: 'UserRoleChange',

  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    ...mapState({
      currentUser: state => state.user
    })
  },

  methods: {
    async changeRole () {
      this.isLoading = true;
      const { data } = await this.loadData('user/role/change');
      this.$store.dispatch('checkSession', { data: data });
      if (this.$route.name === 'menu.home.title') {
        this.$forceUpdate()
      } else {
        setTimeout(() => this.$router.push({ path: '/home' }), 500);
      }
      this.isLoading = false;
    },

    async loadData (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  }
}
