import DepartmentEdit from '@/components/company/departments/edit/DepartmentEdit.vue'
import UserPicker from 'ebg-vue-common/src/components/user/picker/UserPicker.vue'
import DivisionList from '@/components/company/divisions/list/DivisionList.vue'

const defaultFilterValues = {
  code: '',
  name: '',
  company: '',
  isDeleted: false
};

export default {
  name: 'DepartmentList',
  components: {
    DepartmentEdit,
    UserPicker,
    DivisionList
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'code', direction: 'asc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {
    companyId () {
      if (this.data) {
        return this.data.id + '';
      }
      return null;
    },
    companyOptions () {
      return this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0'), 'text');
    },
    tableHeaders () {
      const headers = [
        { key: 'name.' + this.$i18n.locale, label: this.$t('company-structure.department.name'), sortable: false, filterKey: 'name' },
        { key: 'code', label: this.$t('general.item.code'), sortable: true, filterKey: 'code' }
      ];
      if (this.companyId === null) {
        headers.push({ key: 'company_id', label: this.$t('company-structure.company.name'), format: 'formatFromConfig', configKey: 'user_companies', configParam: 'name', filterKey: 'company' });
      }
      headers.push({ key: 'head_user.fullname', label: this.$t('company-structure.department.head-user') });
      headers.push({ key: 'manager_user.fullname', label: this.$t('company-structure.department.manager-user') });
      if (this.filterValues.isDeleted) {
        headers.push({ key: 'deleted_at', label: this.$t('general.item.deleted'), format: 'formatYesNo' });
      }
      if (this.canEdit) {
        const actions = [];
        if (this.canEdit) {
          actions.push({ label: this.$t('menu.companies.department.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-warning' });
          actions.push({ label: this.$t('menu.companies.department.delete'), emit: 'deleteItem', class: 'btn-danger', bicon: 'trash', ifconfig: { param: 'deleted_at', value: null } });
          actions.push({ label: this.$t('menu.companies.department.restore'), emit: 'restoreItem', class: 'btn-success', bicon: 'clock-history', ifconfig: { param: 'deleted_at', value: null, notCheck: true } });
        }
        headers.push({ key: 'actions', label: '', actions: actions });
      }
      return headers;
    },
    tableExpandConfig () {
      return { component: 'slot' }
    },
    tableFilterConfig () {
      if (this.companyId === null) {
        const filters = { };
        filters.code = { type: 'text', label: '', colOnlyFilter: true };
        filters.name = { type: 'text', label: '', colOnlyFilter: true };
        filters.company = { type: 'select', label: '', options: this.companyOptions, colOnlyFilter: true };
        filters.isDeleted = { type: 'checkbox-single', label: this.$t('general.filters.deleted'), inlineLabel: true };
        return filters;
      }
      return null;
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-company-settings');
    },
    requestPath () {
      let filterValues = this.filterValues;
      if (this.companyId !== null) {
        filterValues = { companyId: this.companyId };
      }
      let path = this.ApiRequest.addGetParamsToPath('config/company/departments/list?perPage=' + this.paging.limit + '&page=' + this.paging.page, filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      return path;
    },
    tableActions () {
      const actions = { download: false, clearCache: true };
      if (this.canEdit) {
        actions.add = { title: this.$t('menu.companies.department.new') };
      }
      return actions;
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      if (this.companyId === null) {
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
      }
      this.loading = true;
      const self = this;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, function (response) {
        if (response.data) {
          self.tableItems = response.data;
          self.paging = response.paging;
          self.loading = false;
        }
      });
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    itemUpdated () {
      this.$emit('saved');
      this.getTableData();
    },
    editItem (el) {
      this.$refs.editElFrm.show(el, this.companyId);
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t('company-structure.department.deleteTitle'), this.$t('company-structure.department.deleteMessage', { name: this.BasicHelper.getTranslation(el.name) }))
      }
    },
    restoreItem (el) {
      this.setDeleted(el, 0);
    },
    setDeleted (el, isDeleted) {
      this.loading = true;
      const self = this;
      this.ApiRequest.request('config/company/departments/deleted', this.ApiRequest.REQUEST_POST, { id: el.id, code: el.code, deleted: isDeleted }, function (response) {
        if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
          self.loading = false;
        } else {
          self.getTableData();
        }
      });
    }
  },
  mounted () {
    if (this.companyId === null) {
      this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    }
    this.getTableData();
    this.isMounted = true;
  }
}
