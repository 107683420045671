import SnipeAssetUserAssets from '@/components/snipeasset/user/assets/SnipeAssetUserAssets.vue'
import AxaptaUserHolidayList from '@/components/axapta/user/holidaylist/AxaptaUserHolidayList.vue'

export default {
  name: 'UserListExpand',
  props: {
    data: Object
  },
  components: {
    AxaptaUserHolidayList,
    SnipeAssetUserAssets
  },
  methods: {
    getAdGroupClass (group) {
      let res = '';
      if (parseInt(this.data.isDisabled)) {
        res += ' rounded-block-disabled';
      } else if (group.request) {
        res += ' rounded-block-' + group.request;
      }
      return res;
    }
  }
}
