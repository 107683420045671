import UserPicker from 'ebg-vue-common/src/components/user/picker/UserPicker.vue'
import CustomFormRecordView from '@/components/common/customform/record/view/CustomFormRecordView.vue'
import CustomFormRecordRecipients from '@/components/common/customform/record/recipients/CustomFormRecordRecipients.vue'
import Filelist from 'ebg-vue-common/src/components/common/fileupload/filelist/Filelist.vue'
import Fileupload from 'ebg-vue-common/src/components/common/fileupload/Fileupload.vue';
import moment from 'moment';

export default {
  name: 'CustomFormRecordEdit',
  data () {
    return {
      editable: false,
      loading: true,
      updating: false,
      updatingStatus: false,
      currentReq: null,
      form: null,
      record: null,
      dispalayError: false,
      userOptions: [],
      isExistUserMultiple: false,
      number: 0
    };
  },
  props: {
    formId: {
      type: Number,
      default: null
    },
    recordId: {
      type: Number,
      default: null
    }
  },
  components: {
    Filelist,
    UserPicker,
    CustomFormRecordView,
    CustomFormRecordRecipients,
    Fileupload
  },
  computed: {
    requestPath () {
      return 'custom-form/form/view/' + this.formElId + '/records';
    },
    canEdit () {
      return (!this.updating && (this.record && this.recordElId === 'NEW'));
    },
    formElId () {
      if (this.formId) {
        return this.formId
      }
      return this.$route.params.formid;
    },
    recordElId () {
      if (this.recordId) {
        return this.recordId
      }
      return this.$route.params.recordid;
    },

    formCode () {
      return this.$store.getters.getParamFromConfig('custom_form_types', this.form.type_id, 'code');
    },
    isHolidayRequest () {
      return this.formCode.includes('HOLIDAY');
    },

    holidaysRemaining () {
      const field = this.form.allFields.find(el => el.typeCode == 'JOB-POSITION');
      if (field === undefined) {
        return null;
      }

      const userCompanyJobPosition = this.record.user.job_positions.find(el => el.job_position_id === this.record.fields[field.id]);
      if (userCompanyJobPosition === undefined) {
        return null;
      }
      return userCompanyJobPosition.remainHoliday;
    },

    isFiveDayNotice () {
      if (!this.isHolidayRequest || this.record.id) {
        return false;
      }

      const paymentTypeFieldId = this.form.type.default_fields.find((el) => el.code === 'payment_type')
      if (paymentTypeFieldId === undefined) {
        return false;
      }

      const type = this.record.fields[paymentTypeFieldId.id];
      if (type !== 'SALARY_BEFORE') {
        return false;
      }
      const fromDateFieldId = this.form.type.default_fields.find((el) => el.code === 'fromDate').id;
      const date = this.record.fields[fromDateFieldId];
      const now = (new Date()).getTime();
      const diff = Math.floor((Date.parse(date) - now) / 86400000) + 1;
      return !isNaN(diff) && diff <= 5;
    },
    isOtherHolidayType () {
      return this.isHolidayRequest && this.formCode.includes('OTHER');
    },
    invalidFields () {
      const res = [];
      if (this.form && this.form.allFields && this.record && this.record.fields) {
        const self = this;
        this.form.allFields.forEach(function (el) {
          if (el.typeCode === 'USER-PICKER') {
            if (!(!el.isRequired || self.record.field_users[el.id]) && el.isShow) {
              res.push(el.id);
            }
          } else if (el.typeCode === 'USER-MULTIPLE') {
            if (!(!el.isRequired) && !!el.isShow) {
              if ((self.number < 2 && (self.record.field_users[el.id] && !self.record.field_users[el.id].length))) {
                res.push(el.id);
              } else if (self.number > 1 && self.record.field_users[el.id].length != self.number) {
                res.push('MSG-USER-MULTIPLE');
              }
            }
          } else {
            if (!(!el.isRequired || self.record.fields[el.id])) {
              res.push(el.id);
            }
          }
        });
      }

      return res;
    },
    recordStatus () {
      if (this.record.status_id) {
        const recordStatus = this.$store.getters.getDataFromConfigWithFilter('custom_form_record_statuses', 'id', this.record.status_id);
        return recordStatus.code;
      }
      return null;
    },
    jobPositionOptions () {
      if (this.record && this.record.user && this.record.user.job_positions) {
        return this.HrHelper.getJobPositionOptions(this.record.user.job_positions, this.form.company_id);
      }
      return [];
    }
  },
  methods: {
    validMinDate (el) {
      let isTimeMinPeriod = false;
      if (el.timePeriod_id) {
        const timePeriods = this.$store.getters.getConfigOptions('time_period', this.$i18n.locale);
        const timePeriod = timePeriods.find(data => data.value == el.timePeriod_id);
        if (timePeriod != undefined && timePeriod.code === 'END-DATE') {
          isTimeMinPeriod = true;
        }
      }

      if (el.isDateNow) {
        return new Date();
      } else if (isTimeMinPeriod && el.parentValidationField_id && this.record.fields[el.parentValidationField_id]) {
        return this.record.fields[el.parentValidationField_id];
      } else if (Number(el.addDateFromNow) > 0) {
        const parentMinDate = moment(this.record.fields[el.parentMinDate_id]).unix();
        const addDateFromNow = moment().add(Number(el.addDateFromNow), 'days').unix();
        let date = this.record.fields[el.parentMinDate_id]
        if (parentMinDate < addDateFromNow) {
          date = moment().add(Number(el.addDateFromNow), 'days').format();
        }

        if (el.parentValidationField_id && this.record.fields[el.parentValidationField_id] && el.maxDaysHoliday > 0) {
          return this.maxDaysHoliday(el, date);
        } else {
          return date;
        }
      } else if (el.parentMinDate_id && this.record.fields[el.parentMinDate_id]) {
        if (el.parentValidationField_id && this.record.fields[el.parentValidationField_id] && el.maxDaysHoliday > 0) {
          return this.maxDaysHoliday(el, this.record.fields[el.parentMinDate_id]);
        } else {
          return this.record.fields[el.parentMinDate_id];
        }
      } else if (this.isHolidayRequest) {
        const date = new Date();
        date.setDate((new Date()).getDate() - 30);
        return date;
      }

      return null;
    },

    maxDaysHoliday (el, date) {
      const number = Number(el.maxDaysHoliday) - 1;
      const maxFromDate = moment(this.record.fields[el.parentValidationField_id]).subtract(number, 'days').unix();
      const minFromDate = moment(date).startOf('day').unix();
      if (maxFromDate >= minFromDate) {
        return moment(this.record.fields[el.parentValidationField_id]).endOf('day').subtract(number, 'days').format();
      } else {
        for (let day = number; day >= 1; day -= 1) {
          if (moment(this.record.fields[el.parentValidationField_id]).subtract(day, 'days').unix() >= minFromDate) {
            return moment(this.record.fields[el.parentValidationField_id]).endOf('day').subtract(day, 'days').format();
          }
        }
      }
    },

    validMaxDate (el) {
      let isTimeMinPeriod = false;
      if (el.timePeriod_id) {
        const timePeriods = this.$store.getters.getConfigOptions('time_period', this.$i18n.locale);
        const timePeriod = timePeriods.find(data => data.value == el.timePeriod_id);
        if (timePeriod != undefined && timePeriod.code === 'START-DATE') {
          isTimeMinPeriod = true;
        }
      }

      if (isTimeMinPeriod && el.parentValidationField_id && this.record.fields[el.parentValidationField_id]) {
        return this.record.fields[el.parentValidationField_id];
      } else if (el.parentValidationField_id && this.record.fields[el.parentValidationField_id] && el.maxDaysHoliday > 0) {
        const number = Number(el.maxDaysHoliday) - 1;
        return moment(this.record.fields[el.parentValidationField_id]).add(number, 'days')
      }

      return null;
    },

    loadData () {
      this.loading = true;
      const self = this;
      const path = this.requestPath + '/view/' + this.recordElId;
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, function (response) {
        if (response.error) {
          self.loading = false;
          self.ApiRequest.displayErrorDialog(response, this);
        } else {
          self.record = response.data.record;
          self.form = response.data.form;
          self.mergeFields();
          if (self.recordElId == 'NEW') {
            if (self.jobPositionOptions.length > 0) {
              Object.values(self.form.allFields).forEach(field => {
                if (field.typeCode == 'USER-MULTIPLE') {
                  self.isExistUserMultiple = true;
                } else if (field.typeCode == 'JOB-POSITION') {
                  self.record.fields[field.id] = self.jobPositionOptions[0].value
                }
              });
            }
          }
        }
        self.loading = false;
      });
    },

    mergeFields () {
      if (this.form.type && this.form.type.default_fields) {
        this.form.allFields = this.form.type.default_fields.concat(this.form.fields);
        return;
      }
      this.form.allFields = this.form.fields;
    },
    save () {
      if (this.invalidFields.length === 0) {
        this.updating = true;
        let reqData = this.BasicHelper.cloneObject(this.record);
        const fileData = [];
        this.form.allFields.forEach((row, key) => {
          if (row.typeCode === 'FILE') {
            this.record.fields[row.id].forEach((data) => {
              fileData.push(data);
            })
          }
        });

        if (fileData.length) {
          reqData.files = fileData;
        }
        reqData = this.ApiRequest.createFormData(reqData, Object.keys(reqData), 'form', this, false, ['field_id']);

        const self = this;
        this.ApiRequest.request(this.requestPath + '/edit', this.ApiRequest.REQUEST_FILEUPLOAD, reqData, function (response) {
          if (response.data) {
            self.record = response.data.record;
            self.form = response.data.form;
            const routeData = self.$router.resolve({ name: 'menu.forms.record-edit', params: { formid: self.record.form_id, recordid: self.record.id } });
            self.$router.push(routeData.href);
            self.mergeFields();
          } else if (response.error) {
            self.ApiRequest.displayErrorDialog(response, self);
          }
          self.updating = false;
        });
      } else {
        let message = this.$t('custom-form.form.fillRequiredMessage');
        if (this.invalidFields[0] === 'MSG-USER-MULTIPLE') {
          message = this.$t('custom-form.form.fill-required-user-multiple')
        }
        this.dispalayError = true;
        this.$refs.msgBoxRequired.show(
          null,
          this.$t('custom-form.form.fillRequiredTitle'),
          message
        )
      }
    },
    updateStatus (status) {
      const path = 'custom-form/form/view/' + this.record.form_id + '/records/update-status/' + this.record.id;
      this.updatingStatus = true;
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, { approve: status }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        }
        const messageBody = status
          ? 'custom-form.record.change-status-success-approved'
          : 'custom-form.record.change-status-success-rejected';
        this.ApiRequest.displaySuccessDialog(this, this.$t(messageBody), this.$t('custom-form.record.change-status-success-title'));
        this.updatingStatus = false;
        this.loadData();
      });
    },
    cancelRecord () {
      const path = 'custom-form/form/view/' + this.record.form_id + '/records/cancel/' + this.record.id;
      this.updatingStatus = true;
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, { }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        }
        const messageBody = 'custom-form.record.change-status-success-canceled'
        this.ApiRequest.displaySuccessDialog(this, this.$t(messageBody), this.$t('custom-form.record.change-status-success-title'));
        this.updatingStatus = false;
        this.loadData();
      });
    },

    checkToDate (data) {
      const el = data.find(el => el.code === 'toDate');
      if (el == undefined) {
        return true;
      } else if (this.record && this.record.fields[el.id]) {
        const date = moment(this.record.fields[el.id]).add(30, 'days');
        return date.unix() > moment().unix()
      }

      return true;
    },

    optionsCheckbox (el) {
      const options = [];
      el.forEach((item, key) => {
        options.push({ value: key, text: this.BasicHelper.getTranslation(item) })
      });

      return options;
    },

    async selectJob (el) {
      if (this.isExistUserMultiple) {
        Object.values(this.form.allFields).forEach(field => {
          if (field.typeCode == 'USER-MULTIPLE' && field.isShow) {
            this.record.field_users[field.id] = [];
          } else if (field.typeCode == 'USER-PICKER') {
            field.isShow = true;
          }
        });

        const { data } = await this.getData(`custom-form/type/approve/users/${this.form.type_id}/${el.value}`);
        this.number = data.number
        this.userOptions = data.user;
        Object.values(this.form.allFields).forEach(field => {
          if (field.typeCode == 'USER-MULTIPLE') {
            field.isShow = this.userOptions.length;
          } else if (field.typeCode == 'USER-PICKER') {
            field.isShow = !this.userOptions.length;
          }
        });
      }
    },

    selectedUserMultiple (el) {
      if (this.number > 1 && el.length == this.number) {
        this.userOptions.forEach(row => {
          const user = el.find(data => data.value === row.value);
          if (user === undefined) {
            row.disabled = true;
          }
        });
      }
    },

    deselectUserMultiple (el) {
      if (this.number > 1) {
        this.userOptions.forEach(row => {
          row.disabled = false;
        });
      }
    },

    async getData (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  },

  watch: {
    formid: {
      immediate: true,
      handler (val) {
        this.loadData();
      }
    }
  },
  mounted () {
    this.loadData();
  }
}
