import ContentBlocksEdit from '@/components/contentblocks/edit/ContentBlocksEdit.vue'

export default {
  name: 'ContentBlocksList',
  data () {
    return {
      isMounted: false,
      loading: false,
      paging: {
        page: 1,
        pages: 1,
        limit: 10,
        total: 0
      },
      tableSorting: { item: 'title', direction: 'asc' },
      itemData: [],
      filterValues: {
        company: []
      }
    }
  },
  components: {
    ContentBlocksEdit
  },
  computed: {
    tableHeaders () {
      const headers = [
        { key: 'name.' + this.$i18n.locale, label: this.$t('content-blocks.name'), class: 'col-width-xl' },
        { key: 'code', label: this.$t('content-blocks.code'), filterKey: 'code', class: 'col-width-xl' },
        { key: 'company_id', label: this.$t('content-blocks.company'), format: 'formatFromConfig', configKey: 'user_companies', configParam: 'name' }
      ];
      if (this.canEdit) {
        headers.push({
          key: 'actions',
          label: '',
          actions: [
            { label: this.$t('content-blocks.edit-item'), emit: 'editItem', class: 'btn-warning btn-sm', bicon: 'pencil-fill' },
            { label: this.$t('content-blocks.delete-item'), emit: 'deleteItem', class: 'btn-danger btn-sm', bicon: 'trash', ifconfig: { param: 'deleted_at', value: null } }
          ],
          class: 'col-width-medium'
        });
      }
      return headers;
    },
    requestPath () {
      return this.ApiRequest.addGetParamsToPath(
        'config/content-blocks/list?perPage=' + this.paging.limit + '&page=' + this.paging.page,
        (this.$refs.tableview ? this.$refs.tableview.getFilterValues() : this.filterValues),
        true
      );
    },
    tableActions () {
      return {
        add: { title: this.$t('content-blocks.add-new') }
      }
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-company-settings');
    },
    tableExpandConfig () {
      return { component: 'slot' };
    },
    tableFilterConfig () {
      return {
        code: { type: 'text', label: '', colOnlyFilter: true },
        company: { type: 'checkbox', label: this.$t('company-structure.company.name'), options: this.companyOptions, class: 'col-md-12' }
      }
    },
    companyOptions () {
      const options = this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0'));
      options.unshift({ code: 'NULL', text: this.$t('general.item.ALL') });
      return options;
    }
  },
  methods: {
    editItem (data) {
      this.$refs['edit-content-block'].show(data);
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t('content-blocks.delete-title'), this.$t('content-blocks.delete-message'))
      }
    },
    restoreItem (el) {
      this.setDeleted(el, 0);
    },
    getTableData () {
      this.loading = false;
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.loading = true;
      this.$forceUpdate();
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, [], (response) => {
        if (response.data) {
          this.itemData = response.data;
          this.paging = response.paging;
          this.loading = false;
        }
      });
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    setDeleted (el, isDeleted) {
      this.loading = true;
      this.ApiRequest.request('config/content-blocks/delete/' + el.id, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.getTableData();
        }
      });
    }
  },
  mounted () {
    this.isMounted = true;
    this.getTableData();
  }
}
