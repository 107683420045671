import UserJobsEdit from '@/components/user/tab/jobs/edit/UserJobsEdit.vue';

export default {
  name: 'UserJobs',

  props: {
    userApiId: {
      type: String,
      default: null
    }
  },

  components: {
    UserJobsEdit
  },

  data () {
    return {
      jobsList: [],
      currentReq: null,
      isLoading: false,
      paging: { limit: 10, page: 1, total: 0, pages: 0 }
    }
  },

  computed: {
    tableHeaders () {
      const headers = [
        { key: 'company_id', label: this.$t('user.table.company'), format: 'formatFunc', funcs: [this.getCompanyName] },
        { key: 'department_id', label: this.$t('company-structure.department.name'), format: 'formatFunc', funcs: [this.getDepartmentName] },
        { key: 'division_id', label: this.$t('company-structure.division.name'), format: 'formatFunc', funcs: [this.getDivisionName] },
        { key: 'job_id', label: this.$t('user.jobPosition'), format: 'formatFunc', funcs: [this.getJobPositionName] },
        { key: 'directManager', label: this.$t('user.my-data.direct-manager') },
        { key: 'contractNo', label: this.$t('user.table.contractNo') },
        { key: 'companyValidFrom', label: this.$t('user.table.valid-from'), type: 'date' },
        { key: 'validTo', label: this.$t('user.table.validTo'), type: 'date' },
        { key: 'isPrimary', label: this.$t('user.jobPositions.isPrimary'), format: 'formatYesNo' },
        { key: 'remainHoliday', label: this.$t('user.table.accumulatedHoliday'), format: 'formatDecimal', precision: 2 }
      ];

      let actions = [];
      if (this.canEdit) {
        actions = [
          { label: this.$t('user.jobs.btn.title-edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-warning btn-sm' },
          { label: this.$t('user.jobs.btn.title-delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-danger btn-sm' }
        ];
      }

      headers.push({ key: 'actions', label: '', actions: actions, headerClass: 'col-1' });
      return headers;
    },

    canEdit () {
      return this.ApiRequest.userHasPermission('edit-all-users');
    }
  },

  methods: {
    populateJobs () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }

      this.isLoading = true;
      const path = `user/jobs/list?userApi_id=${this.userApiId}&perPage=${this.paging.limit}&page=${this.paging.page}`;
      this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else if (response.data) {
          this.jobsList = response.data;
          this.paging = response.paging;
        }
        this.isLoading = false;
      });
    },

    editItem (el) {
      this.$refs.editUserJobs.show(el ? el.id : 'NEW', this.userApiId);
    },

    getCompanyName (id) {
      return id ? this.$store.getters.getParamFromConfig('user_companies', id, 'name', this.$i18n.locale) : null;
    },

    getDepartmentName (id) {
      return id ? this.$store.getters.getParamFromConfig('company_departments', id, 'name', this.$i18n.locale) : null;
    },

    getDivisionName (id) {
      return id ? this.$store.getters.getParamFromConfig('company_divisions', id, 'name', this.$i18n.locale) : null;
    },

    getJobPositionName (id) {
      return id ? this.$store.getters.getParamFromConfig('company_jobs', id, 'name', this.$i18n.locale) : null;
    },

    changePage (pageNr) {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }

      this.paging.page = pageNr;
      this.populateJobs();
    },

    async deleteItem (el, approved) {
      if (approved) {
        const { data } = await this.destroy(`user/jobs/destroy/${el.id}`);
        if (data) {
          this.$store.commit('toastAdd', {
            context: 'success',
            message: this.$t('user.jobs.delete_successfully', { jobs: this.getJobPositionName(el.job_id) })
          });
        }
        this.populateJobs();
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t('user.jobs.title-delete'), this.$t('user.jobs.text-delete', { jobs: this.getJobPositionName(el.job_id) }))
      }
    },

    async destroy (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, this.form, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  },

  mounted () {
    this.populateJobs();
  }
}
