export default {
  name: 'AdGroupName',
  props: {
    data: {
      default: null
    },
    groupId: {
      default: null,
      type: Number | String
    },
    itemClass: {
      default: '',
      type: String
    }
  },
  computed: {
    group () {
      let groupid = this.data;
      let groupCode = null;
      if (groupid && groupid.code) {
        groupCode = groupid.code;
        groupid = null;
      }
      if (this.groupId) {
        groupid = this.groupId;
      }
      if (groupid) {
        return this.$store.getters.getDataFromConfigById('ad_groups', groupid);
      } else if (groupCode) {
        return this.$store.getters.getDataFromConfigWithFilter('ad_groups', 'code', groupCode);
      }
      return null;
    },
    groupDesc () {
      if (this.group) {
        return this.group.description;
      }
      return null;
    },
    groupName () {
      if (this.group) {
        if (this.group.name) {
          return this.group.name;
        }
        return this.group.code;
      }
      return null;
    }
  }
}
