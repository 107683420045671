import UserEdit from '@/components/user/edit/UserEdit.vue'
import UserListExpand from '@/components/user/listexpand/UserListExpand.vue'
import { mapState } from 'vuex';

export default {
  name: 'UserList',

  components: {
    UserEdit,
    UserListExpand
  },

  data () {
    return {
      currentReq: null,
      isLoading: false,
      tableItems: [],
      filterValues: {
        isUserActive: [1],
        company_id: null,
        isPrimary: [],
        userType: [this.$store.getters.getParamFromConfigByFilter('user_types', 'code', 'EMPLOYEE', 'id')]
      },
      paging: { limit: 20, page: 1, total: 0, pages: 0 }
    }
  },

  computed: {
    ...mapState(['config']),
    tableHeaders () {
      const headers = [
        { key: 'fullname', label: this.$t('user.table.name-surname') },
        { key: 'jobPositions', label: this.$t('user.jobPosition'), type: 'slot', slotcode: 'jobPositions', filterKey: 'jobPosition', headerClass: 'col-2' },
        {
          key: 'department_id',
          label: this.$t('company-structure.department.name'),
          type: 'slot',
          slotcode: 'departments',
          filterKey: 'department',
          headerClass: 'col-2'
        },
        { key: 'company_id', label: this.$t('user.company'), type: 'slot', slotcode: 'company' }
      ];
      if (this.canViewFull) {
        headers.push(
          { key: 'phone', label: this.$t('user.table.phone-work-private'), type: 'slot', slotcode: 'phone' },
          { key: 'email', label: this.$t('user.table.email-work-private'), type: 'slot', slotcode: 'email' }
        );
      } else {
        headers.push(
          { key: 'ldap_telephone_number', label: this.$t('user.table.phone-work'), format: 'formatPhone' },
          { key: 'email', label: this.$t('user.email'), format: 'formatEmail' }
        );
      }

      headers.push(
        { key: 'directManager', label: this.$t('user.my-data.direct-manager'), type: 'slot', slotcode: 'directManager' }
      );

      if (this.canViewFull) {
        headers.push(
          { key: 'isUserActive', label: this.$t('user.isActive'), labelcode: 'user.isActive', format: 'formatYesNo', reverseYesNo: true },
          { key: 'isAgreesPhoto', label: this.$t('user.table.agrees-photo'), format: 'formatYesNo', reverseYesNo: true },
          { key: 'isAgreesBirthday', label: this.$t('user.table.agrees-birthday'), format: 'formatYesNo', reverseYesNo: true },
          { key: 'isAgreesPhone', label: this.$t('user.table.agrees-phone'), format: 'formatYesNo', reverseYesNo: true }
        );
      }

      const actions = [];
      if (this.canEdit) {
        actions.push({
          label: this.$t('menu.user.delete'),
          emit: 'deleteItem',
          bicon: 'trash',
          class: 'btn-danger btn-sm',
          ifconfig: { func: this.canDeleteUser, useFunc: true }
        });

        if (this.canEnableDisable) {
          actions.push({
            label: this.$t('menu.user.enable'),
            emit: 'enableDisableUser',
            bicon: 'arrow-counterclockwise',
            class: 'btn-primary btn-sm',
            ifconfig: { func: this.canEnableUser, useFunc: true }
          });
          actions.push({
            label: this.$t('menu.user.disable'),
            emit: 'enableDisableUser',
            bicon: 'x',
            class: 'btn-danger btn-sm',
            ifconfig: { func: this.canDisableUser, useFunc: true }
          });
        }
        actions.push({
          label: this.$t('menu.user.edit'),
          emit: 'editItem',
          bicon: 'pencil-fill',
          class: 'btn-warning btn-sm',
          ifconfig: { func: this.canEditUser, useFunc: true }
        });
      }
      headers.push({ key: 'actions', label: '', actions: actions, headerClass: 'col-1' });
      return headers;
    },

    tableExpandConfig () {
      if (this.canViewFull) {
        return { component: 'slot' };
      }
      return null;
    },

    tableFilterConfig () {
      const filters = {
        company_id: {
          type: 'select',
          label: this.$t('user.company'),
          options: this.companyOptions,
          valueField: 'value',
          controlClass: 'form-control-sm',
          class: 'col-md-3'
        },
        fullname: {
          type: 'text',
          colOnlyFilter: false,
          label: this.$t('user.table.name-surname'),
          controlClass: 'form-control-sm',
          class: 'col-md-3'
        },
        userType: {
          type: 'checkbox',
          label: this.$t('user.type'),
          options: this.userTypeOptions,
          valueField: 'value',
          controlClass: 'btn-sm',
          class: 'col-md-2'
        }
      }
      if (this.canViewFull) {
        filters.isUserActive = {
          type: 'checkbox',
          label: this.$t('user.isActive'),
          options: this.yesNoOptions,
          valueField: 'code',
          controlClass: 'btn-sm',
          class: 'col-md-1'
        }
      }
      filters.isPrimary = {
        type: 'checkbox',
        label: this.$t('user.jobPositions.isPrimary'),
        options: this.yesNoOptions,
        valueField: 'code',
        controlClass: 'btn-sm',
        class: 'col-md-1'
      }
      filters.jobPosition = {
        type: 'text',
        colOnlyFilter: true,
        label: '',
        controlClass: 'form-control-sm'
      }
      filters.department = {
        type: 'text',
        colOnlyFilter: true,
        label: '',
        controlClass: 'form-control-sm'
      }
      return filters;
    },

    tableActions () {
      const actions = { download: false };
      if (this.canEdit) {
        actions.add = { title: this.$t('menu.user.new') };
      }
      return actions;
    },

    userTypeOptions () {
      return this.$store.getters.getConfigOptions('user_types', this.$i18n.locale);
    },

    yesNoOptions () {
      return this.BasicHelper.yesNoOptions(0);
    },

    companyOptions () {
      return this.BasicHelper.sortArrayByKeyValue(
        this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0'),
        'text'
      );
    },

    canEnableDisableAllUsers () {
      return this.ApiRequest.userHasPermission('disable-all-users');
    },

    canEnableDisable () {
      return this.canEnableDisableAllUsers;
    },

    canEditAllUsers () {
      return this.ApiRequest.userHasPermission('edit-all-users');
    },

    canEdit () {
      return this.canEditAllUsers;
    },

    canViewFull () {
      return this.ApiRequest.userHasPermission('view-all-users');
    }
  },

  methods: {
    canEnableUser (item) {
      if (!parseInt(item.isUserActive) || item.isLdapUser == 0) {
        return false;
      }

      if (this.canEnableDisableAllUsers) {
        return true;
      }
      return false;
    },

    canDisableUser (item) {
      if (parseInt(item.isDisabled) || item.isLdapUser == 0) {
        return false
      }

      if (this.canEnableDisableAllUsers) {
        return true;
      }
      return false;
    },

    canDeleteUser (item) {
      return !item.ldapUsername;
    },

    canEditUser () {
      if (this.canEditAllUsers) {
        return true;
      }
    },

    labelJob (jobId) {
      if (jobId) {
        return this.config.company_jobs[jobId].name[this.$i18n.locale];
      }
    },

    labelDepartment (departmentId) {
      if (departmentId === null) {
        return ''
      }
      return this.config.company_departments[departmentId].name[this.$i18n.locale];
    },

    labelCompany (companyId) {
      if (companyId === null) {
        return ''
      }
      return this.config.user_companies[companyId].name[this.$i18n.locale];
    },

    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }

      this.isLoading = true;
      let path = `user/index?perPage=${this.paging.limit}&page=${this.paging.page}`;
      path = this.ApiRequest.addGetParamsToPath(path, this.filterValues, true);
      this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else if (response.data) {
          this.tableItems = response.data;
          this.paging = response.paging;
        }
        this.isLoading = false;
      });
    },

    changePage (pageNr) {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }

      this.paging.page = pageNr;
      this.getTableData();
    },

    updateFilters () {
      this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
      this.getTableData();
    },

    setDeleted (el) {
      this.loading = true;
      const self = this;
      this.ApiRequest.request(`user/delete/${el.api_id}`, this.ApiRequest.REQUEST_POST, {}, function (response) {
        if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
          self.loading = false;
        } else {
          self.itemUpdated();
        }
      });
    },

    itemUpdated () {
      this.$emit('saved');
      this.getTableData();
    },

    editItem (el, newtab = 0) {
      if (newtab) {
        const routeData = this.$router.resolve({ name: 'menu.user.view', params: { api_id: (el ? el.api_id : 'NEW') } });
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({ name: 'menu.user.view', params: { api_id: (el ? el.api_id : 'NEW') } });
      }
    },

    enableDisableUser (item, approved) {
      if (approved) {
        this.isLoading = true;
        this.$refs.tableview.$forceUpdate();
        this.ApiRequest.request(`user/change-disabled/${item.id}`, this.ApiRequest.REQUEST_POST, { isDisabled: !parseInt(item.isUserActive) }, (response) => {
          if (response.error) {
            this.isLoading = false;
            this.ApiRequest.displayErrorDialog(response, this);
          } else if (response.data) {
            this.isLoading = false;
            this.getTableData();
          }
        });
      } else {
        const number = item.isUserActive ? 0 : 1;
        this.$refs.itemEnableDisableMsgBox.show(
          item,
          this.$t('user.enableDisable.' + number + '.title'),
          this.$t('user.enableDisable.' + number + '.message', { userName: item.fullname + ' (' + item.email + ')' })
        );
      }
    },

    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t('user.deleteTitle'), this.$t('user.deleteMessage', { userName: el.name }))
      }
    },

    clearFilters () {
      this.filterValues = {};
      this.getTableData();
    }
  },

  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    if (!this.canViewFull) {
      this.filterValues.isUserActive = [1];
    }
    this.getTableData();
  }
}
