export default {
  name: 'AxaptaUserHolidayList',
  props: {
    userApiId: {
      type: String,
      default: null
    },
    includeReqButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      tableItems: [],
      user: null,
      loading: true,
      paging: { limit: 10, page: 1, total: 0, pages: 0 }
    }
  },

  computed: {
    tableHeaders () {
      const headers = [
        { key: 'company_id', label: this.$t('company-structure.company.name'), format: 'formatFromConfig', configKey: 'user_companies', configParam: 'name' },
        { key: 'startDate', label: this.$t('axapta.holiday.startDate'), type: 'date' },
        { key: 'toDate', label: this.$t('axapta.holiday.toDate'), type: 'date' }
      ];

      let actions = [];
      if (this.canDestroy) {
        actions = [
          {
            label: this.$t('user.holiday.btn.title-delete'),
            emit: 'deleteItem',
            bicon: 'trash',
            class: 'btn-danger btn-sm'
          }
        ];
      }

      headers.push({ key: 'actions', label: '', actions: actions });
      return headers;
    },

    formUrlParams () {
      return { name: 'menu.forms.list-forms' }
    },

    canDestroy () {
      return this.ApiRequest.userHasPermission('delete-holiday');
    }
  },

  methods: {
    getTableData () {
      const userApiId = this.userApiId !== null ? this.userApiId : this.$session.get('uuid');
      if (userApiId) {
        this.loading = true;
        const self = this;
        this.ApiRequest.request('axapta/holiday/list/' + userApiId, this.ApiRequest.REQUEST_GET, [], function (response) {
          if (response.data) {
            self.tableItems = response.data.items;
            self.user = response.data.user
          }
          self.loading = false;
        });
      }
    },

    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },

    async deleteItem (el, approved) {
      if (approved) {
        const { data } = await this.destroy(`axapta/holiday/destroy/${el.id}`);
        if (data) {
          this.$store.commit('toastAdd', {
            context: 'success',
            message: this.$t('user.holiday.delete_successfully')
          });
        }
        this.getTableData();
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t('user.holiday.btn.title-delete'), this.$t('user.holiday.text-delete'))
      }
    },

    async destroy (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, this.form, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  },

  mounted () {
    this.getTableData()
  }
}
