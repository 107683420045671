import { mapState } from 'vuex';
import FolderTreeItem from '@/components/folder/tree/item/FolderTreeItem.vue';
import FolderUsers from '@/components/folder/users/FolderUsers.vue';
import TableFilter from 'ebg-vue-common/src/components/common/tablefilter/TableFilter.vue'

export default {
  name: 'FolderTree',
  props: {
    user: Object,
    permissions: Object | Array,
    loading: Boolean,
    jobPosition: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedFolder: null,
      searchFolder: '',
      onlyWithPermission: false,
      treeItems: null,
      delayTimer: null,
      updatingFolderIds: []
    }
  },
  components: {
    FolderTreeItem,
    FolderUsers,
    TableFilter
  },
  computed: {
    ...mapState(['config']),
    canViewFolderUsers () {
      if (this.jobPosition) {
        return false;
      }
      return (this.ApiRequest.userHasPermission('view-company-users') || this.ApiRequest.userHasPermission('view-all-users'));
    },
    canRequest () {
      if (this.jobPosition && this.ApiRequest.userHasPermission('edit-company-settings')) {
        return true;
      }
      if (this.user && parseInt(this.user.isLdapUser) === 1) {
        return true;
      }
      return false;
    },
    folderTree () {
      return this.EbgFolderHelper.getTreeWithPaths();
    }
  },
  methods: {
    updateFolderSubtree (tree, search = '', onlyWithPermission) {
      const self = this;
      if (tree) {
        Object.keys(tree).forEach(function (key) {
          if (!onlyWithPermission && search !== '') {
            tree[key].hidden = !tree[key].path.includes(search);
            tree[key].displayChildren = false;
            if (tree[key].children) {
              tree[key].children = self.updateFolderSubtree(tree[key].children, search, onlyWithPermission);
              Object.values(tree[key].children).forEach(function (childEl) {
                if (!childEl.hidden) {
                  tree[key].hidden = false
                  tree[key].displayChildren = true
                }
              })
            }
          } else {
            if (self.permissions && self.permissions[tree[key].id]) {
              tree[key].shouldBeVisible = true;
              tree[key].hidden = false;
            } else if (onlyWithPermission) {
              tree[key].hidden = true;
            }
            if (!tree[key].displayChildren && tree[key].children && !tree[key].shouldBeVisible) {
              tree[key].children = self.updateFolderSubtree(tree[key].children, search, onlyWithPermission);
              Object.values(tree[key].children).forEach(function (childEl) {
                if (childEl.shouldBeVisible || childEl.displayChildren) {
                  tree[key].displayChildren = true
                  tree[key].hidden = false;
                }
              });
            }
          }
        });
      }
      return tree;
    },
    updateFolderTreeData () {
      clearTimeout(this.delayTimer);
      const self = this;
      this.delayTimer = setTimeout(function () {
        self.treeItems = self.updateFolderSubtree(
          self.BasicHelper.cloneObject(self.folderTree),
          (self.searchFolder ? self.searchFolder.toUpperCase() : '').replace('/', '\\'),
          (!self.editable ? true : self.onlyWithPermission)
        );
      }, 200);
    },
    requestPermission (data) {
      if (!this.editable) {
        return;
      }
      const reqData = { folderId: data.folderId, groups: [{ group: data.groupCode, delete: false }] };
      if (this.jobPosition) {
        reqData.job_position_id = this.jobPosition.position_id;
        reqData.job_id = this.jobPosition.job_id;
        this.requestFolderPermissionChange(reqData);
      } else {
        reqData.user = this.user.api_id;
        const permissions = this.$store.getters.getParamFromConfigByFilter('ad_group_permission_types', 'code', data.permissionType, 'name');
        this.$refs.approveFolderRequest.show(
          reqData,
          this.$t('ebgfolder.addGroupTitle'),
          this.$t('ebgfolder.addGroupMessage', {
            permissions: this.BasicHelper.getTranslation(permissions),
            path: this.EbgFolderHelper.getFolderPath(data.folderId).path,
            permission: data.groupCode,
            username: (this.user ? this.user.name + ' ' + this.user.surname : '')
          })
        )
      }
    },
    removePermission (data) {
      if (!this.editable) {
        return;
      }
      const reqData = { folderId: data.folderId, groups: [{ group: data.groupCode, delete: true }] };
      if (this.jobPosition) {
        reqData.job_position_id = this.jobPosition.position_id;
        reqData.job_id = this.jobPosition.job_id;
        this.requestFolderPermissionChange(reqData);
      } else {
        reqData.user = this.user.api_id;
        const permissions = this.$store.getters.getParamFromConfigByFilter('ad_group_permission_types', 'code', data.permissionType, 'name');
        this.$refs.approveFolderRequest.show(
          reqData,
          this.$t('ebgfolder.removeGroupTitle'),
          this.$t('ebgfolder.removeGroupMessage', {
            permissions: this.BasicHelper.getTranslation(permissions),
            path: this.EbgFolderHelper.getFolderPath(data.folderId).path,
            permission: data.groupCode,
            username: (this.user ? this.user.name + ' ' + this.user.surname : '')
          })
        )
      }
    },
    requestFolderPermissionChange (reqData) {
      const self = this;
      let path = 'ad-groups/ebg-folders/' + reqData.folderId + '/change-permissions';
      if (!reqData.user) {
        path = 'config/company/jobs/change-permissions'
      }
      this.updatingFolderIds.push(reqData.folderId);
      this.$forceUpdate();
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, reqData, function (response) {
        if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
        } else if (response.data) {
          self.$emit('userFolderGroupsChanged', response.data);
        }
        const idx = self.updatingFolderIds.indexOf(reqData.folderId);
        if (idx > -1) {
          self.updatingFolderIds.splice(idx, 1);
        }
      });
    },
    viewFolderUsers (el) {
      this.selectedFolder = el;
      this.$refs.modalUsers.show();
    }
  },
  watch: {
    permissions: {
      immediate: true,
      handler (val) {
        this.updateFolderTreeData();
      }
    },
    editable: {
      immediate: true,
      handler (val) {
        this.updateFolderTreeData();
      }
    }
  },
  mounted () {
    if (this.user) {
      this.onlyWithPermission = true;
    }
    this.updateFolderTreeData();
  }
}
