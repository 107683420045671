export default {
  name: 'UserBirthdays',

  data () {
    return {
      currentReq: null,
      isLoading: false,
      tableItems: [],
      exportCols: [],
      filterValues: {
        isUserActive: [1],
        isd365: [1],
        isAgreesBirthday: [1]
      },
      paging: { limit: 25, page: 1, total: 0, pages: 0 },
      raworder: 'MONTH:birthday;DAY:birthday'
    }
  },

  computed: {
    tableHeaders () {
      const headers = [
        { key: 'fullname', label: this.$t('user.table.name-surname') },
        { key: 'job_id', label: this.$t('user.jobPosition'), format: 'formatFromConfig', configKey: 'company_jobs', configParam: 'name' },
        { key: 'company_id', label: this.$t('user.company'), format: 'formatFromConfig', configKey: 'user_companies', configParam: 'name' },
        { key: 'department_id', label: this.$t('company-structure.department.name'), format: 'formatFromConfig', configKey: 'company_departments', configParam: 'name' },
        { key: 'seniorityBirthday', label: this.$t('user.table.seniority-birthday') },
        { key: 'birthday', label: this.$t('user.table.date-birth') },
        { key: 'age', label: this.$t('user.table.age') }
      ];

      const actions = [];
      headers.push({ key: 'actions', label: '', actions: actions, headerClass: 'col-1' });
      return headers;
    },

    tableFilterConfig () {
      const filters = {
        company_id: {
          type: 'select',
          label: this.$t('user.company'),
          options: this.companyOptions,
          valueField: 'value',
          controlClass: 'form-control-sm',
          class: 'col-lg-3 col-md-12 col-sm-12'
        },
        period: {
          type: 'date-range',
          manualFilter: true,
          label: this.$t('user.period'),
          valueField: 'period',
          controlClass: 'form-control-sm',
          class: 'col-lg-3 col-md-12 col-sm-12'
        },
        isUserActive: {
          type: 'checkbox',
          label: this.$t('user.isActive'),
          options: this.yesNoOptions,
          valueField: 'code',
          controlClass: 'btn-sm',
          class: 'col-lg-2 col-md-12 col-sm-12'
        },
        isAgreesBirthday: {
          type: 'checkbox',
          label: this.$t('user.isAgreesBirthday'),
          options: this.yesNoOptions,
          valueField: 'code',
          controlClass: 'btn-sm',
          class: 'col-lg-2 col-md-12 col-sm-12'
        }
      }

      return filters;
    },

    tableActions () {
      const actions = { extra: [], download: true };
      return actions;
    },

    companyOptions () {
      return this.BasicHelper.sortArrayByKeyValue(
        this.$store.getters.getConfigOptions(
          'user_companies',
          this.$i18n.locale,
          'disabled',
          '0',
          false,
          false,
          false,
          ['isDynamics', 'd365Code']
        ).filter(
          el => el.isDynamics
        ),
        'text'
      );
    },

    yesNoOptions () {
      return this.BasicHelper.yesNoOptions(0);
    }
  },

  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }

      this.isLoading = true;
      this.currentReq = this.ApiRequest.request(this.getRequestPath(0), this.ApiRequest.REQUEST_GET, { raworder: this.raworder }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else if (response.data) {
          this.tableItems = response.data.table;
          this.paging = response.paging;
          const colConfig = [];
          response.data.columns.forEach(el => {
            colConfig.push({ key: el.key, name: this.$t(el.key), group: el.group });
          });
          this.exportCols = colConfig;
        }
        this.isLoading = false;
      });
    },

    changePage (pageNr) {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }

      this.paging.page = pageNr;
      this.getTableData();
    },

    clearFilters () {
      this.filterValues = {};
      this.getTableData();
    },

    updateFilters () {
      this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
      this.getTableData();
    },

    rowClass (item) {
      return (item.age >= 50 && Number.isInteger(item.age / 10)) ? 'table-success' : '';
    },

    getRequestPath (downloading = 0) {
      let path = 'user/birthdays/list?perPage=' + this.paging.limit + '&page=' + this.paging.page;
      path = this.ApiRequest.addGetParamsToPath(path, this.filterValues, true);
      if (downloading) {
        path += '&download=1';
      }
      return path;
    },

    downloadList (html, exportCols) {
      this.isLoading = true;
      this.$refs.tableview.setDownloading(1);
      const reqData = {};
      if (exportCols) {
        reqData.cols = exportCols;
      }
      this.ApiRequest.request(this.getRequestPath(1), this.ApiRequest.REQUEST_DOWNLOAD, reqData, () => {
        this.$refs.tableview.setDownloading(0);
      });
      this.isLoading = false;
    }
  },

  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
  }
}
