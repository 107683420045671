import ApproveEdit from '@/components/common/customform/form/notifications/approve/edit/ApproveEdit.vue';
import { mapState } from 'vuex';

export default {
  name: 'ApproveList',

  props: {
    customFormTypeId: {
      type: Number,
      default: null
    },
    companyId: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      aprovelList: [],
      isLoading: false
    }
  },

  components: {
    ApproveEdit
  },

  computed: {
    ...mapState(['config']),
    tableHeaders () {
      const headers = [
        { key: 'department_id', label: this.$t('company-structure.department.name'), format: 'formatFunc', funcs: [this.getDepartmentName] },
        { key: 'division_id', label: this.$t('company-structure.division.name'), format: 'formatFunc', funcs: [this.getDivisionName] },
        { key: 'jobPositions', label: this.$t('user.jobPosition'), type: 'slot', slotcode: 'jobPositions' },
        { key: 'number', label: this.$t('company-structure.number') },
        { key: 'isDirectManager', label: this.$t('user.my-data.direct-manager'), format: 'formatYesNo' },
        { key: 'department_additional_approve_id', label: this.$t('company-structure.department.name-additional-approve'), format: 'formatFunc', funcs: [this.getDepartmentName] },
        { key: 'division_additional_approve_id', label: this.$t('company-structure.division.name-additional-approve'), format: 'formatFunc', funcs: [this.getDivisionName] },
        { key: 'jobPositionsAdditionalApprove', label: this.$t('company-structure.job.name-additional-approve'), type: 'slot', slotcode: 'jobPositionsAdditionalApprove' }
      ];

      const actions = [
        { label: this.$t('general.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-warning btn-sm' },
        { label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-danger btn-sm' }
      ];

      headers.push({ key: 'actions', label: '', actions: actions, headerClass: 'col-1' });
      return headers;
    }
  },

  methods: {
    async populateApprove () {
      this.isLoading = true;
      const { data } = await this.loadData(`custom-form/type/approve/index/${this.customFormTypeId}/${this.companyId}`);
      this.aprovelList = data;
      this.isLoading = false;
    },

    getDepartmentName (id) {
      return id ? this.$store.getters.getParamFromConfig('company_departments', id, 'name', this.$i18n.locale) : null;
    },

    getDivisionName (id) {
      return id ? this.$store.getters.getParamFromConfig('company_divisions', id, 'name', this.$i18n.locale) : null;
    },

    getJobPositionName (id) {
      let jobText = '';
      if (this.config && this.config.company_jobs && this.companyId) {
        Object.values(this.config.company_jobs).forEach(el => {
          Object.values(el.job_positions).forEach(jobPos => {
            if (jobPos.id === id) {
              jobText = el.name[this.$i18n.locale];
            }
          });
        });
      }
      return jobText;
    },

    editItem (el) {
      this.$refs.approveEdit.show(el ? el.id : 'NEW', this.companyId, this.customFormTypeId);
    },

    async deleteItem (el, approved) {
      if (approved) {
        const { data } = await this.loadData(`custom-form/type/approve/destroy/${el.id}`);
        if (data) {
          this.$store.commit('toastAdd', {
            context: 'success',
            message: this.$t('company-structure.approve.delete_successfully')
          });
        }
        await this.populateApprove();
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t('general.actions.delete'), this.$t('user.holiday.text-delete'))
      }
    },

    async loadData (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  },

  async mounted () {
    await this.populateApprove();
  }
}
