import logList from 'ebg-vue-common/src/components/log/list/LogList.vue';

export default {
  name: 'UserHistory',

  components: {
    logList
  },

  computed: {
    tableHeaders () {
      return [
        { key: 'type_id', label: this.$t('log.type'), filterKey: 'type', class: 'col-width-medium', format: 'formatFromConfig', configKey: 'log_record_types', configParam: 'name' },
        { key: 'element_id', label: this.$t('general.item.id') },
        { key: 'user', label: this.$t('log.user'), filterKey: 'user', class: 'col-width-medium', valFilter: 'userFullName' },
        { key: 'created_at', label: this.$t('log.created'), filterKey: 'date', type: 'datetime', class: 'col-width-medium' },
        { key: 'oldValue', label: this.$t('log.oldValue'), valueChange: true },
        { key: 'newValue', label: this.$t('log.newValue'), valueChange: true },
        { key: 'actions', actions: [] }
      ];
    }
  },

  methods: {
    reload () {
      this.$refs.logList.reload();
    }
  }
}
