import { mapState } from 'vuex';
import FolderTree from '@/components/folder/tree/FolderTree.vue'

export default {
  name: 'UserRightsAttach',
  data () {
    return {
      user: null,
      jobPositionId: null,
      userJobs: true,
      loading: false,
      companyId: null,
      departmentId: null,
      divisionId: null,
      loadingGroups: false,
      jobPositionGroups: [],
      currentReq: null
    }
  },
  components: {
    FolderTree
  },
  computed: {
    ...mapState(['config']),
    getTitle () {
      return this.$t('menu.user.ad-groups.copy-title');
    },
    companyOptions () {
      return this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getConfigOptions('user_companies', this.$i18n.locale, 'disabled', '0'), 'text');
    },
    departmentOptions () {
      const options = [];
      if (this.config && this.config.company_departments && this.companyId) {
        const self = this;
        Object.values(this.config.company_departments).forEach(el => {
          if (parseInt(el.company_id) === parseInt(self.companyId)) {
            options.push({ value: el.id, text: el.name[self.$i18n.locale] })
          }
        });
      }
      return this.BasicHelper.sortArrayByKeyValue(options, 'text');
    },
    divisionOptions () {
      const options = [];
      if (this.config && this.config.company_divisions && this.companyId) {
        const self = this;
        Object.values(this.config.company_divisions).forEach(el => {
          if (parseInt(el.company_id) === parseInt(self.companyId) && (self.departmentId === null || parseInt(el.department_id) === parseInt(self.departmentId))) {
            options.push({ value: el.id, text: el.name[self.$i18n.locale] })
          }
        });
      }
      return this.BasicHelper.sortArrayByKeyValue(options, 'text');
    },
    jobPositionOptions () {
      const options = [];
      if (this.config && this.config.company_jobs && this.companyId) {
        const self = this;
        Object.values(this.config.company_jobs).forEach(el => {
          Object.values(el.job_positions).forEach(jobPos => {
            if (parseInt(jobPos.company_id) === parseInt(self.companyId) && (self.departmentId === null || parseInt(jobPos.department_id) === parseInt(self.departmentId)) &&
              (self.divisionId === null || parseInt(jobPos.division_id) === parseInt(self.divisionId))
            ) {
              options.push({ value: jobPos.id, text: el.name[self.$i18n.locale] })
            }
          });
        });
      }
      return this.BasicHelper.sortArrayByKeyValue(options, 'text');
    },
    userJobPositions () {
      const options = [];
      if (this.user) {
        const self = this;
        Object.values(this.user.job_positions).forEach(function (el) {
          const jobTitle = self.$store.getters.getParamFromConfig('company_jobs', el.job_id, 'name', self.$i18n.locale);
          options.push({ value: el.job_position_id, text: jobTitle })
        });
      }
      return options;
    }
  },
  methods: {
    show (elData) {
      this.user = this.BasicHelper.cloneObject(elData);
      this.userJobs = true;
      this.jobPositionId = (this.userJobPositions.length > 0) ? this.userJobPositions[0].value : null;
      this.getJobPositionGroups();
      this.$refs['modal-window'].show();
    },
    save () {
      if (!this.loading && this.jobPositionId) {
        this.loading = true;
        const self = this;
        this.ApiRequest.request('user/job-permissions/' + this.user.api_id + '/' + this.jobPositionId, this.ApiRequest.REQUEST_POST, {}, function (response) {
          if (response.error) {
            self.ApiRequest.displayErrorDialog(response, self);
          } else {
            self.$emit('saved', response.data);
            self.close();
          }
          self.loading = false;
        });
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    },
    onCompanyChange () {
      this.departmentId = null;
      this.divisionId = null;
      this.jobPositionId = null;
    },
    onDepartmentChange () {
      this.divisionId = null;
      this.jobPositionId = null;
    },
    onDivisionChange () {
      this.jobPositionId = null;
    },
    getJobPositionGroups () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      if (this.jobPositionId) {
        this.loadingGroups = true;
        const self = this;
        this.currentReq = this.ApiRequest.request('config/company/jobs/position-groups/' + this.jobPositionId, this.ApiRequest.REQUEST_GET, {}, function (response) {
          if (response.data) {
            self.jobPositionGroups = response.data;
            self.loadingGroups = false;
          }
        });
      }
    }
  }
}
