import { render, staticRenderFns } from "./UserRights.vue?vue&type=template&id=ee53ff16"
import script from "./UserRights.js?vue&type=script&lang=js&external"
export * from "./UserRights.js?vue&type=script&lang=js&external"
import style0 from "./UserRights.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports