import { mapState } from 'vuex';
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core';

const defaultEl = {
  id: null,
  custom_form_type_id: null,
  company_id: null,
  department_id: null,
  division_id: null,
  job_position_id: [],
  number: 0,
  isDirectManager: true,
  department_additional_approve_id: null,
  division_additional_approve_id: null,
  job_position_additional_approve_id: []
}

export default {
  name: 'ApproveEdit',

  data () {
    return {
      form: [],
      header_title: '',
      isLoading: false
    }
  },

  validations: {
    form: {
      department_id: { required },
      job_position_id: { required },
      department_additional_approve_id: { required },
      job_position_additional_approve_id: { required }
    }
  },

  computed: {
    ...mapState(['config']),

    departmentOptions () {
      const departments = this.$store.getters.getConfigOptions(
        'company_departments',
        this.$i18n.locale,
        null, null, false, false, false,
        ['company_id']
      ).filter(
        el => parseInt(el.company_id) === parseInt(this.form.company_id)
      );

      return this.BasicHelper.sortArrayByKeyValue(departments, 'sortText');
    },

    divisionOptions () {
      const division = this.$store.getters.getConfigOptions(
        'company_divisions',
        this.$i18n.locale,
        null, null, false, false, false,
        ['company_id', 'department_id']
      ).filter(
        el => (
          parseInt(el.company_id) === parseInt(this.form.company_id) &&
          (this.form.department_id === null || parseInt(el.department_id) === parseInt(this.form.department_id))
        )
      );

      return this.BasicHelper.sortArrayByKeyValue(division, 'sortText');
    },

    jobPositionOptions () {
      const options = [];
      if (this.config && this.config.company_jobs && this.form.company_id) {
        Object.values(this.config.company_jobs).forEach(el => {
          Object.values(el.job_positions).forEach(jobPos => {
            if (
              parseInt(jobPos.company_id) === parseInt(this.form.company_id) &&
              (this.form.department_id === null || parseInt(jobPos.department_id) === parseInt(this.form.department_id)) &&
              (this.form.division_id === null || parseInt(jobPos.division_id) === parseInt(this.form.division_id))
            ) {
              options.push({ value: jobPos.id, text: el.name[this.$i18n.locale], department_id: jobPos.department_id })
            }
          });
        });
      }
      return this.BasicHelper.sortArrayByKeyValue(options, 'text');
    },

    divisionAdditionalApproveOptions () {
      const division = this.$store.getters.getConfigOptions(
        'company_divisions',
        this.$i18n.locale,
        null, null, false, false, false,
        ['company_id', 'department_id']
      ).filter(
        el => (
          parseInt(el.company_id) === parseInt(this.form.company_id) &&
          (this.form.department_additional_approve_id === null || parseInt(el.department_id) === parseInt(this.form.department_additional_approve_id))
        )
      );

      return this.BasicHelper.sortArrayByKeyValue(division, 'sortText');
    },

    jobPositionAdditionalApproveOptions () {
      const options = [];
      if (this.config && this.config.company_jobs && this.form.company_id) {
        Object.values(this.config.company_jobs).forEach(el => {
          Object.values(el.job_positions).forEach(jobPos => {
            if (
              parseInt(jobPos.company_id) === parseInt(this.form.company_id) &&
              (this.form.department_additional_approve_id === null || parseInt(jobPos.department_id) === parseInt(this.form.department_additional_approve_id)) &&
              (this.form.division_additional_approve_id === null || parseInt(jobPos.division_id) === parseInt(this.form.division_additional_approve_id))
            ) {
              options.push({ value: jobPos.id, text: el.name[this.$i18n.locale], department_id: jobPos.department_id })
            }
          });
        });
      }
      return this.BasicHelper.sortArrayByKeyValue(options, 'text');
    }
  },

  methods: {
    async show (id, companyId, customFormTypeId) {
      this.v$.$reset();
      this.form = this.BasicHelper.cloneObject(defaultEl);
      this.form.custom_form_type_id = customFormTypeId;
      this.form.company_id = companyId;
      if (id !== 'NEW') {
        this.form.id = id;
        this.isLoading = true;
        const { data } = await this.loadData(`custom-form/type/approve/show/${id}`);
        this.BasicHelper.syncObjectValuesByKeys(this.form, data);
        Object.values(data.jobPositions).forEach(el => {
          this.form.job_position_id.push(el.job_position_id)
        });
        Object.values(data.jobPositionsAdditionalApprove).forEach(el => {
          this.form.job_position_additional_approve_id.push(el.job_position_additional_approve_id)
        });
        this.isLoading = false;
      }
      this.$refs['modal-window'].show();
    },

    async submitForm () {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      this.isLoading = true;
      await this.save('custom-form/type/approve/save');
      this.$emit('changeDate');
      this.isLoading = false;
      this.$refs['modal-window'].hide();
      this.$store.commit('toastAdd', { context: 'success', message: this.$t('menu.issue-reports.saved') });
    },

    async loadData (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    },

    async save (path) {
      try {
        return new Promise((resolve, reject) => {
          this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, this.form, response => {
            if (response.error) {
              this.isLoading = false;
              this.ApiRequest.displayErrorDialog(response, this);
              reject(response.error);
            }
            resolve(response);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  },

  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'NotificationApproveEdit' }) }
  }
}
