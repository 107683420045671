import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'

const defaultField = {
  id: null,
  name: {},
  code: '',
  type_id: null,
  defaultValue: '',
  isRequired: true,
  predefined: false,
  options: [],
  const_code: null,
  isShowDate: false,
  isDateNow: false,
  parentValidationField_id: null,
  timePeriod_id: null,
  parentMinDate_id: null,
  addDateFromNow: 0,
  maxDaysHoliday: 0
};

export default {
  name: 'CustomFormFields',
  props: {
    fields: {
      type: Array,
      default: []
    },
    options: {
      type: Array,
      default: []
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    template: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VueSimpleSuggest
  },
  data () {
    return {
      simpleEditor: true,
      availableCodes: [],
      iter: 0,
      autoCompleteStyle: {
        defaultInput: 'form-control'
      }
    };
  },
  computed: {
    dateСonnectionOptions () {
      const options = [];
      this.fields.forEach((item, key) => {
        const type = this.fieldTypeOptions.find(el => el.value === item.type_id);
        if (type != undefined && type.code === 'DATE') {
          if (item.id != null) {
            options.push({ value: item.id, text: item.name[this.$i18n.locale] });
          } else {
            options.push({ value: `serial_number_${key}`, text: item.name[this.$i18n.locale] });
          }
        }
      });
      return options;
    },

    timePeriodOptions () {
      return this.$store.getters.getConfigOptions('time_period', this.$i18n.locale);
    },

    fieldTypeOptions () {
      return this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getConfigOptions('custom_form_field_types', this.$i18n.locale));
    },

    fieldConstantTypesOptions () {
      let types = this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getConfigOptions('custom_form_constant_types', this.$i18n.locale));
      types.forEach(el => {
        el.type = el.code;
      });
      types = [{ value: null, text: this.$t('custom-form.form-field.createList'), code: null }, ...types];
      return types;
    },
    fieldTypesWithOptions () {
      const res = [];
      this.fieldTypeOptions.forEach(function (el) {
        if (el.code === 'CHECKBOX' || el.code === 'SELECT') {
          res.push(el.value);
        }
      });
      return res;
    },
    checkboxTypeOptionsId () {
      const el = this.fieldTypeOptions.find(el => el.code === 'CHECKBOX');
      if (el) {
        return parseInt(el.value);
      }
      return -1;
    },
    selectTypeOptionId () {
      const el = this.fieldTypeOptions.find(el => el.code === 'SELECT');
      if (el) {
        return parseInt(el.value);
      }
      return -1;
    },
    codes () {
      return this.options.map(e => e.code);
    }
  },
  methods: {
    onCodeFocus () {
      let available = this.BasicHelper.cloneObject(this.codes);
      this.fields.forEach((field, id) => {
        if (field.code && available.includes(field.code) && !field.deleted) {
          available = available.filter(e => e !== field.code);
        }
      });
      this.availableCodes = available.map((code) => {
        return { id: code, title: `${this.$t('custom-form.codes.' + code)} (${code})` }
      });
      this.$refs.suggestion.forEach((suggest) => {
        suggest.clearSuggestions();
      });
    },

    onCodeChange () {
      this.fields.forEach((field, id) => {
        const option = this.options.filter(opt => opt.code === field.code && !field.deleted);
        if (this.fields[id].predefined && option.length > 0 && !this.fields[id].id) {
          this.fields[id] = this.BasicHelper.cloneObject(option[0]);
          this.fields[id].name = {};
          this.fields[id].name[this.$i18n.locale] = this.$t('custom-form.codes.' + this.fields[id].code);
        }
        if (option.length > 0 && option[0].options) {
          this.fields[id].options = option[0].options;
          this.fields[id].const_code = option[0].const_code;
          this.fields[id].type_id = option[0].type_id;
        }
        this.fields[id].predefined = option.length > 0;
      });
    },

    onTypeChange (value, key) {
      if (key != undefined) {
        this.fields[key].isShowDate = (value && value.code === 'DATE')
      }
    },

    addField () {
      const field = this.BasicHelper.cloneObject(defaultField);
      field.type_id = this.fieldTypeOptions[0].value;
      this.fields.push(field);
    },
    removeField (key) {
      if (this.fields[key].id) {
        this.fields[key].deleted = true;
      } else {
        this.fields.splice(key, 1);
      }
      this.$forceUpdate();
    },
    selectTypeChange (val, field, key) {
      if (!val) {
        this.fields[key].options = [];
        this.$refs.select[0].content = [];
      } else {
        const type = this.$store.getters.getDataFromConfigWithFilter('custom_form_constant_types', 'code', val);
        const options = this.$store.getters.getItemsFromConfigWithFilter('custom_form_constants', 'const_type_id', type.id);
        const results = {};
        for (const value of Object.values(options)) {
          results[value.code] = value.name;
        }
        this.fields[key].options = results;
        this.onCodeChange();
      }
    },
    isPredefined (key) {
      if (this.template) {
        return false; // ignore if editing template
      }
      return !!(this.fields[key] && this.fields[key].form_type_id);
    }
  },
  mounted () {
    this.onCodeChange();
  },
  watch: {
    options: {
      immediate: true,
      handler () {
        this.onCodeChange();
      }
    },
    fields: {
      immediate: true,
      handler (val) {
        this.$forceUpdate();
      }
    }
  }
}
