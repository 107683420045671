import CustomTreeView from '@/components/common/customtree/view/CustomTreeView.vue'
import { mapState } from 'vuex';

export default {
  name: 'CustomTreePickerView',
  data () {
    return {
      loading: true,
      treeOptions: null,
      treeId: null
    }
  },
  props: {
    type: {
      type: String,
      default: 'ORG_STRUC'
    },
    optionTextField: {
      type: String,
      default: 'companyName'
    }
  },
  components: {
    CustomTreeView
  },
  computed: {
    ...mapState(['config'])
  },
  methods: {
    loadOptions () {
      this.loading = true;
      let path = 'custom-tree/list/1';
      if (this.type) {
        path += '/' + this.type
      }
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, [], (response) => {
        if (response.data) {
          this.treeOptions = null;
          this.treeId = null;
          const companyId = parseInt(this.$session.get('company_id'));
          const options = [];
          response.data.forEach((el) => {
            options.push({
              value: el.id,
              text: this.BasicHelper.getTranslation(el.name),
              companyId: el.company_id,
              companyName: this.BasicHelper.getConfigValue('user_companies', 'id', el.company_id, 'name', true)
            })
            if (companyId && el.company_id === companyId) {
              this.treeId = el.id;
            }
          });
          if (this.treeId === null && options && options.length > 0) {
            this.treeOptions = this.BasicHelper.sortArrayByKeyValue(options, this.optionTextField);
            this.treeId = this.treeOptions[0].value
          }
        }
        this.loading = false;
      });
    }
  },
  mounted () {
    this.loadOptions();
  }
}
